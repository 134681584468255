import React, { useEffect, useState } from 'react';
import npLogo from '../assets/imgs/np.png';
import { CSVLink } from 'react-csv';
import { useHits } from 'react-instantsearch';
import RecruitNav from '../layouts/navigation/EndUserNavigation';

import {
    Hits,
    CurrentRefinements,
    InstantSearch,
    Pagination,
    RefinementList,
    SearchBox,
    SortBy,
    Configure
} from 'react-instantsearch';

import { Sidebar, Menu, SubMenu } from 'react-pro-sidebar';
import { parseDate } from '../services/utilities';

import {
    ClearFilters,
    NoResults,
    NoResultsBoundary,
    Panel,
    PriceSlider,
    CustomStats,
} from '../components/shared';

import algoliasearch from "algoliasearch";

import instagramIcon from '../assets/icons/instagram.svg';
import espnIcon from '../assets/icons/espn.svg';
import hudlIcon from '../assets/icons/hudl-icon.png';
import fallbackImage from '../assets/imgs/np.png';
import twitterIcon from '../assets/icons/twitter-x.svg';
import unknownImage from '../assets/imgs/unknown.png'
import pffImage from '../assets/imgs/pff.png';
import { json, useLocation } from 'react-router-dom';
import { fetchFavoriteCountByUser, insertUserFavorite } from '../services/supabase';
import { toast } from 'react-toastify';

const searchClient = algoliasearch('ETT8OD4DUG', 'bb501e3aa5c51ecc4515f46406dc894c');
const indexName = 'PROD_PORTAL_ATHLETE';


function convertData(input) {
    return input.map(entry => ({
        twitter: entry.twitterHandle && entry.twitterHandle != 'Unknown' ? 'https://twitter.com/' + entry.twitterHandle : '',
        fname: entry.fname ? entry.fname : '',
        lname: entry.lname ? entry.lname : '',
        class: entry.experience ? entry.experience : '',
        exitTeam: entry.exitTeam ? entry.exitTeam : 'Unknown',
        position: entry.position ? entry.position : '',
        state: entry.location ? entry.location : '',
        height: entry.height ? entry.height.toString().replace('"', '').replace('\'', '-').replace('- ', '-') : '',
        weight: entry.weight ? entry.weight : '',
        'offer count': entry.offers?.length ? entry.offers.length : '0',
        offers: entry.offers?.map(offer => offer.name) || [], // Extracting offer names
        committed: entry.committedTeam?.name ? entry.committedTeam.name : '',
        pff: entry.pffId ? 'https://ultimate.pff.com/ncaa/players/' + entry.pffId + '/snaps_and_grades' : '',
        snaps: entry.snaps ? entry.snaps : '',
        multipleTransfers: entry.multipleTransfers ? 'Yes' : 'No',
        receivingFinancialAid: entry.receivingFinancialAid ? 'Yes' : 'No',
        gradStudent: entry.gradStudent ? 'Yes' : 'No'
    }));
}



const CSVSearchResults = () => {
    const hits = useHits().hits;
    return (
        hits && hits.length > 0 ? (
            <CSVLink
                filename="portal.csv"
                data={convertData(hits)}
                target="_blank"
            >
                <span className="btn btn-success">Export Records →</span>
            </CSVLink>

        ) : null
    );
};


export default function Portal() {

    const [favoriteCount, setFavoriteCount] = useState(0)
    useEffect(() => {
    
        const mount = async () => {
            const count = await fetchFavoriteCountByUser()
            setFavoriteCount(count)
        }

        mount()
    }, []); 

    const transformItems = (items) => {
        return items.map((item) => ({
            ...item,
            label: item.label.toUpperCase(),
        }));
    };

    async function addToFavorites(transferId) {
        const favObj = {
            'transfers': transferId,
            'type': 'Transfer'
        }

        setFavoriteCount(favoriteCount+1)
        await insertUserFavorite(favObj)
        toast.info("Added to Favorite.", {
            position: "top-right",
            autoClose: 1000,
            });
    }


    function Hit({ hit }) {
    
        return (
            <div className="container container-wrapper w-100 hit-container" style={{ flexDirection: 'column' }}>
                
                {/* Content Row */}
                <div className="row w-100">
                    <div className="col-sm-12 d-flex" style={{ justifyContent: 'space-around' }}>
                        <div className="pr-3 d-flex flex-row col-md-3 col-sm-12">
                            <header className="hit-image-container align-items-left">
                                <img src={hit.image || fallbackImage} title={hit.name} alt={hit.name} style={{ width: '200px', borderRadius: '15px' }} />
                                <p style={{ fontSize: '12px', marginTop: '8px' }}>
                                    Entered: <strong>{hit.enteredPortalDate ? parseDate(hit.enteredPortalDate) : 'Unknown'}</strong><br />
                                    {parseDate(hit.lastInteractionDate) !== parseDate(hit.enteredPortalDate) ? (
                                        <>
                                            Updated: <strong>{hit.lastInteractionDate ? parseDate(hit.lastInteractionDate) : 'Unknown'}</strong>
                                        </>
                                    ) : null}
                                </p>
    
                            </header>
                        </div>
                        <div className="d-flex col-md-5 col-sm-12">
    
                            <h1 className="hit-name w-100">
                                <strong>{hit.name}</strong>
                                <div className="d-flex mt-2">
                                    {hit.pffId && hit.pffId !== '' ? <div className="m-2 p-10"><a target="_blank" rel="noreferrer" href={`https://ultimate.pff.com/ncaa/players/${hit.pffId}/snaps_and_grades`}><img src={pffImage} style={{ width: '50px' }} /></a></div> : ''}
                                    {hit.twitterHandle && hit.twitterHandle !== 'Unknown' ? <div className="m-2 p-10"><a target="_blank" rel="noreferrer" href={`https://twitter.com/${hit.twitterHandle}`}><img src={twitterIcon} /></a></div> : ''}
                                    {hit.instagramHandle && hit.instagramHandle !== '' ? <div className="m-2 p-10"><a target="_blank" rel="noreferrer" href={`https://instagram.com/${hit.instagramHandle}`} alt="Instagram"><img src={instagramIcon} /></a></div> : ''}
                                    {hit.hudlUrl && hit.hudlUrl !== '' ? <div className="m-2 p-10"><a target="_blank" rel="noreferrer" href={`${hit.hudlUrl}`}><img style={{ width: '20px' }} src={hudlIcon} alt="HUDL"></img></a></div> : ''}
                                    {hit.espnUrl && hit.espnUrl !== '' ? <div className="m-2 p-10"><a target="_blank" rel="noreferrer" href={`${hit.espnUrl}`}><img style={{ width: '60px' }} src={espnIcon} alt="ESPN"></img></a></div> : ''}
                                </div>
                                <p style={{ fontSize: '16px', marginTop: '20px' }}>
                                    {hit.experience && hit.experience != 'Unknown' ? hit.experience + ' | ' : ''}
                                    {hit.position ? hit.position + ' | ' : ''}
                                    {hit.height ? hit.heightFeet + '\' ' + hit.heightInches + '" | ' : ''} 
                                    {hit.weight ? hit.weight + ' lbs | ' : ''}
                                    {hit.location && hit.location != 'Unknown' ? hit.location : ''}</p>
                                <p style={{ fontSize: '12px', marginTop: '8px' }}>
                                    {hit.snaps ? <div className="stats-table-row">{hit.snaps} snaps in 2023</div> : ''}
                                    {hit.gradStudent ? <div className="stats-table-row"><div>Grad Student</div></div> : ''}
                                    {hit.multipleTransfers ? <div className="stats-table-row"><div>Multiple Transfers</div></div> : ''}
                                    {hit.receivingFinancialAid ? <div className="stats-table-row">Receives Financial Aid</div> : ''}
                                </p>
    
                            </h1>
                        </div>
    
                        <div className="d-flex col-md-4 col-sm-12">
                            <table className="table" style={{ width: '300px' }}>
                                <thead>
                                    <tr>
                                        <th className="text-center">
                                            Exiting
                                        </th>
                                        <th className="text-center">
                                            Committed
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="text-center align-middle" style={{ textAlign: 'center' }}>
                                            {hit.exitingTeamLogo ? (
                                                <img src={hit.exitingTeamLogo} title={hit.exitTeam} alt={hit.exitTeam} style={{ maxWidth: '65px', margin: 'auto', display: 'block' }} />
                                            ) : (
                                                ''
                                            )}
                                        </td>
                                        <td className="text-center align-middle" style={{ textAlign: 'center' }}>
                                            {hit.committedTeam?.logo ?
                                                <img src={hit.committedTeam?.logo} title={hit.committedTeam?.name} alt={hit.committedTeam?.name} style={{ maxWidth: '55px', margin: 'auto', display: 'block' }} /> :
                                                <img src={unknownImage} alt="Unknown" style={{ width: '50px', margin: 'auto', display: 'block' }} />
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-center" style={{ textAlign: 'center' }}>
                                            <span style={{ fontWeight: 'normal', color: 'red' }}>{hit.exitTeam}</span>
                                        </td>
                                        <td className="text-center" style={{ textAlign: 'center' }}>
                                            <span style={{ fontWeight: 'normal', color: 'green' }}>{hit.committedTeam?.name ? hit.committedTeam.name : 'TBD'}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
    
                <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', padding: '10px 0' }}>
                        <button className="btn btn-success" onClick={() => addToFavorites(hit)} >Add to Favorites</button>
                        
                    </div>
    
                {/* Offers Row */}
                {hit.offercount > 0 && (
                    <div className="row w-100">
                        <div className="d-flex" style={{ borderTop: '1px solid #F2F2F2', paddingTop: '15px', overflowX: 'auto' }}>
                            <p>Offers<br /></p>
                            {hit.offers?.map((offer, idx) => (
                                <div className="col-sm-12 col-md-1" key={idx} style={{ padding: '10px' }}>
                                    <img src={offer.logo} style={{ maxWidth: '55px' }} alt={offer.name} title={offer.name} />
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
    
        );
    };
    
    

    return (
        <div className="row" style={{ marginRight: '0', marginLeft: '0' }}>

            <InstantSearch
                searchClient={searchClient}
                indexName={indexName}
                insights={true}

                future={{
                    preserveSharedStateOnUnmount: true,
                }}
            >
                <Configure
                    analytics={false}
                    hitsPerPage={100}
                />

                <div className="col-md-3 col-xs-12 d-flex justify-content-center custom-scrollbar" style={{ height: '100vh', overflowY: 'scroll', backgroundColor: '#222222' }}>

                    <div className="container-wrapper filter-sidebar">
                        <h3 style={{width:'100%', textAlign:'center'}}>Transfer Portal</h3>
                        <hr />
                        <section className="container-filters pt-3" >
                            <div className="container-body">

                                <SearchBox
                                    placeholder="Name, Twitter Handle"
                                    submitIconComponent={SubmitIcon}
                                />
                                <CustomStats />

                                <Sidebar  >
                                    <Menu menuItemStyles={{
                                        button: {
                                            border: 'none',
                                            minWidth: '0px',
                                            marginLeft: '0',
                                            paddingLeft: '0',

                                            [`&.active`]: {
                                                backgroundColor: '#222222',
                                                color: '#7AFD78'

                                            },
                                            [`&:hover`]: {
                                                backgroundColor: '#222222',
                                                color: '#7AFD78 !important',

                                            }
                                        },
                                    }} >

                                        <SubMenu label="Class" >
                                            <Panel>
                                                <RefinementList
                                                    attribute="experience"
                                                    limit="10"
                                                   
                                                />
                                            </Panel>
                                        </SubMenu>
                                        <hr />
                                        <SubMenu label="Status" >
                                            <Panel>
                                                <RefinementList
                                                    attribute="status"
                                                    limit="5"

                                                />
                                            </Panel>
                                        </SubMenu>
                                        <hr />
                                        <SubMenu label="Division" >
                                            <Panel>
                                                <RefinementList
                                                    attribute="division"
                                                    limit="5"

                                                />
                                            </Panel>
                                        </SubMenu>
                                        <hr />


                                        <SubMenu label="Position" >
                                            <Panel>
                                                <RefinementList
                                                    attribute="position"
                                                    limit="30"
                                                />
                                            </Panel>
                                        </SubMenu>
                                        <hr />
                                        <SubMenu label="Financial Aid, Grad, Transfers" >
                                            <Panel header="Multiple Transfers">
                                                <RefinementList
                                                    attribute="multipleTransfers"
                                                />
                                            </Panel>
                                        <hr />
                                        
                                            <Panel header="Grad Student">
                                                <RefinementList
                                                    attribute="gradStudent"
                                                />
                                            </Panel>
                                        
                                        <hr />
                                        
                                            <Panel header="Financial Aid">
                                                <RefinementList
                                                    attribute="receivingFinancialAid"
                                                />
                                            </Panel>
                                        </SubMenu>
                                        <hr />
                                        <SubMenu label="Exit" >
                                            <Panel header="Division">
                                                <RefinementList
                                                    attribute="exitingDivision"
                                                    limit="10"
                                                    transformItems={transformItems}
                                                />
                                            </Panel>
                                            <Panel header="Conference">
                                                <RefinementList
                                                    attribute="exitingConference"
                                                    searchablePlaceholder='Exit Conference...'
                                                    searchable="true"
                                                    limit="10"
                                                    showMore="true"
                                                />
                                            </Panel>
                                            <Panel header="Team">
                                                <RefinementList
                                                    attribute="exitTeam"
                                                    searchable="true"
                                                    searchablePlaceholder='Exit Team...'
                                                    limit="10"
                                                    showMore="true"
                                                />
                                            </Panel>
                                        </SubMenu>
                                        <hr />

                                        <SubMenu label="Snaps" >
                                            <Panel header="Snaps Taken" className='no-border'>
                                                <PriceSlider attribute="snaps" />
                                            </Panel>
                                        </SubMenu><hr />
                                        <SubMenu label="Offers" >

                                            <Panel header="Division">
                                                <RefinementList
                                                    attribute="offers.division"
                                                    limit="10"
                                                    transformItems={transformItems}
                                                />
                                            </Panel>
                                            <Panel header="Conference">
                                                <RefinementList
                                                    attribute="offers.conference"
                                                    searchablePlaceholder='Offer Conference...'
                                                    searchable="true"
                                                    limit="5"
                                                    showMore="true"
                                                />
                                            </Panel>
                                            <Panel header="Team">
                                                <RefinementList
                                                    attribute="offers.name"
                                                    searchable="true"
                                                    searchablePlaceholder='Offer...'
                                                    limit="5"
                                                    showMore="true"
                                                />
                                            </Panel>
                                            <Panel header="# of Offers" className='no-border'>
                                                <PriceSlider attribute="offercount" />
                                            </Panel>
                                        </SubMenu>
                                        <hr />

                                        <SubMenu label="Location" >
                                            <Panel style={{ marginTop: 0, paddingTop: 0 }}>
                                                <RefinementList
                                                    attribute="location"
                                                    searchable={true}
                                                    searchablePlaceholder='Location...'
                                                    limit="10"
                                                    showMoreLimit={70}
                                                    showMore="true"
                                                />
                                            </Panel>

                                        </SubMenu>
                                        <hr />

                                        <SubMenu label="Height (inches) & Weight" >
                                             <Panel className='no-border' header="Height">
                                                <PriceSlider attribute="height" />
                                            </Panel>

                                            <Panel className='no-border' header="Weight">
                                                <PriceSlider attribute="weight" />
                                            </Panel>
                                        </SubMenu>
                                        <hr />

                                        <SubMenu label="Committed" >
                                            <Panel header="Division">
                                                <RefinementList
                                                    attribute="committedDivision"
                                                    limit="10"
                                                    transformItems={transformItems}

                                                />
                                            </Panel>

                                            <Panel header="Conference">
                                                <RefinementList
                                                    attribute="committedConference"
                                                    searchable="true"
                                                    searchablePlaceholder='Commit Conference...'
                                                    limit="30"
                                                    showMore="true"
                                                />
                                            </Panel>

                                            <Panel header="Team">
                                                <RefinementList
                                                    attribute="committedTeam.name"
                                                    searchablePlaceholder='Commit Team...'
                                                    searchable="true"
                                                    limit="10"
                                                />
                                            </Panel>
                                        </SubMenu>
                                    </Menu>
                                </Sidebar>

                            </div>
                        </section>




                    </div>

                </div>

                <div className="col-md-9 col-xs-12" style={{ height: '100vh', overflowY: 'scroll', backgroundColor: '#F3F3F3' }}>

                    <div className="box-content">
                        <main className="container" >
                            <section className="container-results col-md-12">
                                <header className="container-header container-options">
                                    <div className="col-md-8">
                                        <ClearFilters />
                                        <CurrentRefinements />
                                    </div>
                                    <div className="col-md-2">
                                        <CSVSearchResults />
                                    </div>
                                    <div className="col-md-2">
                                        <SortBy
                                            items={[
                                                { label: 'Last Updated', value: 'PROD_RECRUIT_ATHLETE' },
                                                { label: 'High School', value: 'PROD_RECRUIT_ATHLETE_HIGHSCHOOL' },
                                                { label: 'NP Rating', value: 'PROD_RECRUIT_ATHLETE_RATING' },
                                                { label: 'County', value: 'PROD_RECRUIT_ATHLETE_COUNTY' },
                                                
                                            ]}
                                        />
                                    </div>

                                </header>
                                <div className="">
                                    <NoResultsBoundary fallback={<NoResults />}>
                                        <Hits
                                            hitComponent={Hit}
                                        />
                                    </NoResultsBoundary>

                                    <footer className="container-footer">
                                        <Pagination padding={2} showFirst={true} showLast={true} />
                                    </footer>
                                </div>
                            </section>
                        </main>

                    </div>
                </div>
            </InstantSearch>
        </div>

    );
}

function SubmitIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 18 18"
            aria-hidden="true"
        >
            <g
                fill="none"
                fillRule="evenodd"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.67"
                transform="translate(1 1)"
            >
                <circle cx="7.11" cy="7.11" r="7.11" />
                <path d="M16 16l-3.87-3.87" />
            </g>
        </svg>
    );
}

