import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { TextField } from '@mui/material';

const AdminDataGrid = ({ data, columns, onRowClick, height }) => {
    const [searchText, setSearchText] = useState('');
    const [filteredData, setFilteredData] = useState(data);

    useEffect(() => {
        setFilteredData(data);
    }, [data]);

    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchText(value);

        const filteredRows = data.filter((row) => {
            return Object.keys(row).some((field) =>
                String(row[field]).toLowerCase().includes(value)
            );
        });

        setFilteredData(filteredRows);
    };

    const getRowHeight = () => {
        // Return the height directly as a number
        return height ? height : 60; // Make sure this is a number
    };

    return (
        <div>
            <TextField
                value={searchText}
                onChange={handleSearch}
                placeholder="Search..."
                variant="outlined"
                fullWidth
                sx={{ marginBottom: 2 }}
            />
            {filteredData && (
                <DataGrid
                    rows={filteredData}
                    columns={columns}
                    pageSize={25}
                    autoHeight={false}
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    initialState={{
                        pagination: {
                            pageSize: 100,
                        },
                    }}
                    getRowHeight={getRowHeight}
                    onRowClick={onRowClick}
                    sx={{
                        '& .MuiDataGrid-row': { minHeight: `${height}px` },
                        '& .MuiDataGrid-cell': { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
                        '& .MuiDataGrid-columnHeaders': { color: 'white', backgroundColor: '#026534', fontWeight: 'bold' },
                    }}
                />
            )}
        </div>
    );
};

export default AdminDataGrid;
