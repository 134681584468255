import algoliasearch from "algoliasearch";
import { getAllTeamsAndAbbreviations, getRosters, getNCAATransfers, getTeams, upsertNCAATransfer } from "./supabase";

const client = algoliasearch('ETT8OD4DUG', 'bb501e3aa5c51ecc4515f46406dc894c');
const indexName = 'PROD_PORTAL_ATHLETE';

export async function rebuildNCAATransfers() {

  const index = client.initIndex(indexName);
  console.log('STARTING REBUILD');

  // Fetch data from Supabase
  const ncaaTransfers = await getNCAATransfers();
  console.log('NCAA Transfers:', ncaaTransfers.filter(x => x.statusCode === 'INC' && x.division === 1).length);
  console.log('NCAA Transfers:', ncaaTransfers.filter(x => x.statusCode === 'INC' && x.division === 1));

  const teams = await getAllTeamsAndAbbreviations();
  const shortTeams = await getTeams();
  console.log('Teams:', shortTeams.length);

  const aryAthletes = [];
  const aryNoAthlete = [];
  const oldAthletes = await getNCAATransfers()

  // Map each team to a promise that fetches its roster
  const rosterPromises = shortTeams.map(async (team) => {
    const roster = await getRosters(team.id);
    return roster;
  });

  // Wait for all roster promises to resolve
  const rostersArrays = await Promise.all(rosterPromises);
  const rosters = rostersArrays.flat();

  // Iterate through NCAA transfers and build index entries
  for (const ncaaTransfer of ncaaTransfers) {
    //Update athleteId
    /* if (!ncaaTransfer.athleteId) {
      const team = teams.find(x => x.name?.toUpperCase().trim() === ncaaTransfer.fromInstitutionName?.toUpperCase().trim());
      if (team) {
        const player = oldAthletes.find(x => x.lname?.toUpperCase().trim() === ncaaTransfer.lastName?.toUpperCase().trim() && x.lastTeam?.key === team.master_key);
        if (player) {
          ncaaTransfer.athleteId = player.id;
          ncaaTransfer.interactions = player.interactions;
          ncaaTransfer.twitterHandle = player.twitterHandle;
          ncaaTransfer.instagramHandle = player.instagramHandle;
          await upsertNCAATransfer(ncaaTransfer);
        }
      }
    } */

    const team = teams.find(x => x.name?.toUpperCase().trim() === ncaaTransfer.fromInstitutionName?.toUpperCase().trim());
    if (team) {
      if (team.espnId) {
        const roster = rosters.filter(x => x.teamId === team.id);

        if (!roster || roster.length === 0) {
          console.log('NO ROSTER: ' + team.name + ' |' + team.master_key);
        }
        else {
          //console.log('ROSTER: ' + JSON.stringify(roster));
          const player = roster.find(x => x.lastName.toUpperCase().trim() === ncaaTransfer.lastName.toUpperCase().trim());

          if (player) {
            const playerData = player.playerData;
            ncaaTransfer.espnId = player.id;
            ncaaTransfer.weight = playerData.weight;
            ncaaTransfer.experience = playerData.experience?.abbreviation;
            ncaaTransfer.position = playerData.position?.abbreviation;
            ncaaTransfer.image = playerData.headshot?.href;
            ncaaTransfer.city = playerData.birthPlace?.city;
            ncaaTransfer.state = playerData.birthPlace?.state;
            ncaaTransfer.espnUrl = playerData.links[0]?.href;
            ncaaTransfer.height = playerData.height;

            if (ncaaTransfer.position === 'HB') {
              ncaaTransfer.position = 'RB';
            }
            if (ncaaTransfer.position === 'ED') {
              ncaaTransfer.position = 'EDGE';
            }
            if (ncaaTransfer.position === 'Dl') {
              ncaaTransfer.position = 'DL';
            }
            if (ncaaTransfer.position === 'DT') {
              ncaaTransfer.position = 'DL';
            }
            if (ncaaTransfer.position === 'G') {
              ncaaTransfer.position = 'IOL';
            }
            if (ncaaTransfer.position === 'C') {
              ncaaTransfer.position = 'IOL';
            }
            if (ncaaTransfer.position === 'T') {
              ncaaTransfer.position = 'OT';
            }
            if (ncaaTransfer.position === 'PK') {
              ncaaTransfer.position = 'K';
            }

            await upsertNCAATransfer(ncaaTransfer);

          }
          else {
            console.log('NO PLAYER: ' + ncaaTransfer.firstName + ' ' + ncaaTransfer.lastName);
            aryNoAthlete.push(ncaaTransfer);
          }
        }

      }
      else {
        console.log('NO ESPN TEAM: ' + ncaaTransfer.fromInstitutionName);
      }
    }

    if (ncaaTransfer.statusCode === 'INC') {
      const indexEntry = buildIndexEntry(ncaaTransfer, teams);

      if (indexEntry && ((ncaaTransfer.division === 1) || (ncaaTransfer.division ===2 && ncaaTransfer.offercount > 0))){
        aryAthletes.push(indexEntry);
      }
    }
  }

  // Save objects to Algolia index
  await clearIndex(indexName);
  const { objectIDs } = await index.saveObjects(aryAthletes, { autoGenerateObjectIDIfNotExist: true });
  return objectIDs; 

}

// Function to build index entry for athlete
function buildIndexEntry(ncaaTransfer, teams) {

  if (!ncaaTransfer.state) {
    return null;
  }

  let displayLocation = ncaaTransfer.city ? ncaaTransfer.city + ", " : '';
  displayLocation += ncaaTransfer.state ? ncaaTransfer.state : '';
  const location = ncaaTransfer.state ? ncaaTransfer.state : 'Unknown';

  let aryInteractions = [];
  if (ncaaTransfer.interactions && ncaaTransfer.interactions.length > 0) {
    ncaaTransfer.interactions.forEach((interaction) => {
      aryInteractions.push(interaction)
    })
  }

  const exitingTeamLogo = teams.find(x => x.name?.toUpperCase().trim() === ncaaTransfer.fromInstitutionName?.toUpperCase().trim())?.logo

  let committedTeam = {};
  let committedConference = '';
  let committedDivision = '';
  let committedDate = '';
  let committed = false;

  const c = aryInteractions.find(x => parseInt(x.interactionType) === 4)
  if (c) {
    const teamId = c.teamId;
    committedTeam = teams.find(x => parseInt(x.id) === parseInt(teamId))
    committedDate = c.interactionDate;
    committedConference = committedTeam?.College?.Conference?.name;
    committedDivision = committedTeam?.College?.Conference?.conferenceDivisionId;
    committed = true;
  }

  let offerTeams = [];
  let offerTeam = {};

  //offers
  const o = aryInteractions.filter(x => parseInt(x.interactionType) === 2)
  if (o && o.length > 0) {
    o.forEach(item => {
      const teamId = item.teamId;
      offerTeam = teams.find(x => parseInt(x.id) === parseInt(teamId))
      offerTeam.offerDate = item.interactionDate;
      offerTeam.conference = offerTeam?.College?.Conference?.name;
      offerTeam.division = offerTeam?.College?.Conference?.conferenceDivisionId;
      offerTeams.push(offerTeam)
    })
  }


  let feet = '';
  let inches = '';
  let totalInches = ncaaTransfer.height ? ncaaTransfer.height : null;
  if (totalInches !== null) {
    feet = Math.floor(totalInches / 12); // Calculate the number of feet
    inches = totalInches % 12; // Calculate the remaining inches
  }


  let athleteName = ncaaTransfer.firstName + ' ' + ncaaTransfer.lastName
  if (ncaaTransfer.suffix && ncaaTransfer.suffix !== '') {
    athleteName += ' ' + ncaaTransfer.suffix
  }


  if (!ncaaTransfer.twitterHandle || ncaaTransfer.twitterHandle === '') {
    ncaaTransfer.twitterHandle = "Unknown"
  }

  if (!ncaaTransfer.experience) {
    if (parseInt(ncaaTransfer.graduatingYear) <= 2020) {
      ncaaTransfer.experience = 'SR';
    }
    else if (parseInt(ncaaTransfer.graduatingYear) === 2021) {
      ncaaTransfer.experience = 'JR';
    }
    else if (parseInt(ncaaTransfer.graduatingYear) === 2022) {
      ncaaTransfer.experience = 'SO';
    }            
    else if (parseInt(ncaaTransfer.graduatingYear) >= 2023) {
      ncaaTransfer.experience = 'FR';
    }
  }

  if (!ncaaTransfer.experience || ncaaTransfer.experience === '-' || ncaaTransfer.experience === 'Unknown' || ncaaTransfer.experience === '--') {
    return null
  }


  const indexEntry = {
    
    transferId: ncaaTransfer.transferId,
    objectID: ncaaTransfer.ncaaId,
    pffId: ncaaTransfer.pffId,
    espnUrl: ncaaTransfer.espnUrl,
    gradStudent: ncaaTransfer.isTransferGraduateStudent === 'Y' ? true : false,
    multipleTransfers: ncaaTransfer.multipleTransfers === 'Y' ? true : false,
    receivingFinancialAid: ncaaTransfer.isReceivingAthleticAid === 'Y' ? true : false,
    snaps: ncaaTransfer.snaps,
    experience: ncaaTransfer.experience ? ncaaTransfer.experience : '-',

    enteredPortalDate: ncaaTransfer.createTimestamp,
    fname: ncaaTransfer.firstName,
    lname: ncaaTransfer.lastName,
    division: ncaaTransfer.division,
    name: athleteName,
    graduatingYear: ncaaTransfer.graduatingYear,

    twitterHandle: ncaaTransfer.twitterHandle,
    instagramHandle: ncaaTransfer.instagramHandle,
    position: ncaaTransfer.position,
    heightFeet: feet,
    heightInches: inches,
    height: totalInches,
    weight: ncaaTransfer.weight,
    location: location,
    displayLocation: displayLocation,

    committed: committed,
    committedTeam: committedTeam,
    committedDate: committedDate,
    committedConference: committedConference,
    committedDivision: committedDivision?.toUpperCase(),

    exitTeam: ncaaTransfer.fromInstitutionName,
    exitingConference: ncaaTransfer.conferenceName,
    exitingDivision: ncaaTransfer.division,
    exitingTeamLogo: exitingTeamLogo,

    offercount: offerTeams && offerTeams.length ? offerTeams.length : 0,
    interactions: aryInteractions,

    offers: offerTeams,
    lastInteractionDate: ncaaTransfer.updated_at ? ncaaTransfer.updated_at : ncaaTransfer.updateTimestamp,
    image: ncaaTransfer.image,
    status: committed ? 'Committed' : 'Undecided'
  };

  return indexEntry;
}


export async function clearIndex(indexName) {
  try {
    const index = client.initIndex(indexName);
    index.clearObjects();
    return true;
  }
  catch (error) {
    console.log("ERROR: ", error)
    return false;
  }
}
