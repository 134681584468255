import React, { useEffect, useState } from 'react';
import { TextField, Button } from '@mui/material';
import { getAllTeams, addRecruit, getInteractionTypes, updateSocialPost, deleteSocialPost, processSocialPost } from '../../services/supabase';
import { toast } from 'react-toastify';
import Select from 'react-select';

const ProcessTweet = ({ post, onFormSubmit }) => {
  const fullName = post?.name?.trim().split(' ');

  const [updatedPost, setUpdatedPost] = useState(post);
  const [selectTeams, setSelectTeams] = useState([]);
  const [selectInteractionTypes, setSelectInteractionTypes] = useState([]);
  const [firstName, setFirstName] = useState(fullName?.length > 0 ? fullName[0] : '');
  const [lastName, setLastName] = useState(fullName?.length > 1 ? fullName.slice(1).join(' ') : '');
  const [twitter, setTwitter] = useState(post.handle);

  useEffect(() => {
    setUpdatedPost(post);
  }, [post]);

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const fetchedTeams = await getAllTeams();
        const sTeams = fetchedTeams.map((x) => ({
          value: x.id,
          label: x.name,
        }));
        setSelectTeams(sTeams);
      } catch (error) {
        console.error('Error fetching teams:', error);
      }
    };

    const fetchInteractionTypes = async () => {
      try {
        const types = await getInteractionTypes();
        const sTypes = types.map((x) => ({
          value: x.id,
          label: x.interactionType,
        }));
        setSelectInteractionTypes(sTypes);
      } catch (error) {
        console.error('Error fetching interaction types:', error);
      }
    };

    fetchTeams();
    fetchInteractionTypes();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!updatedPost.Recruit) {
        if (firstName && lastName && twitter) {
          const newRecruit = {
            firstName,
            lastName,
            twitter,
          };
          const recruit = await addRecruit(newRecruit);
          window.open(`/admin/athlete/${recruit.id}`, '_blank');
          onFormSubmit(updatedPost.postId); // Update only
          return;
        } else {
          toast.error('Please enter first name, last name, and twitter handle');
          return;
        }
      } else {
        updateSocialPost(updatedPost, true);
      }

      toast.success('Post updated successfully');
      onFormSubmit(updatedPost.postId); // Update only
    } catch (error) {
      toast.error('Error updating post');
      console.error('Error updating post:', error);
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    try {
        await deleteSocialPost(updatedPost.postId);
        toast.success('Post deleted successfully');
        onFormSubmit(updatedPost.postId, true); // Pass postId and a flag to indicate deletion
    } catch (error) {
        toast.error('Error deleting post');
        console.error('Error deleting post:', error);
    }
};


  return (
    <div>
      <form onSubmit={handleSubmit} className="post-form">
        <h2>{post.handle} - {post.name}</h2>
        <p style={{ fontSize: '12px' }}>{post.id} - {post.postId}</p>
        <span style={{ fontSize: '12px' }}>{post.source}</span>
        <p style={{ fontSize: '12px' }}>
          {post.link ? <a target="_blank" href={post.link} rel="noopener noreferrer">Original Tweet</a> : 'Tweet not found'}
        </p>
        <TextField
          label="Tweet"
          name="content"
          value={updatedPost.content}
          onChange={(e) => setUpdatedPost({ ...updatedPost, content: e.target.value })}
          fullWidth
          margin="normal"
          multiline
          rows={6}
        />
        <TextField
          label="Description"
          name="description"
          value={updatedPost.description}
          onChange={(e) => setUpdatedPost({ ...updatedPost, description: e.target.value })}
          fullWidth
          margin="normal"
          multiline
          rows={4}
        />

        <p>{updatedPost.location}</p>
        <hr />

        {!updatedPost.Recruit && (
          <div>
            <div className="form-group mb-3">
              <label className="form-text"><strong>First Name</strong></label>
              <input
                type="text"
                name="firstName"
                value={firstName}
                placeholder="First Name"
                required
                className="form-control"
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>

            <div className="form-group mb-3">
              <label className="form-text"><strong>Last Name</strong></label>
              <input
                type="text"
                name="lastName"
                value={lastName}
                placeholder="Last Name"
                required
                className="form-control"
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>

            <div className="form-group mb-3">
              <label className="form-text"><strong>Twitter Handle</strong></label>
              <input
                type="text"
                name="twitter"
                value={twitter}
                placeholder="Twitter Handle"
                required
                className="form-control"
                onChange={(e) => setTwitter(e.target.value)}
              />
            </div>
          </div>
        )}

        <p><strong>Team:</strong></p>
        {selectTeams.length > 0 ? (
          <Select
            id="selectedTeamId"
            isSearchable
            name="selectedTeamId"
            options={selectTeams}
            value={selectTeams.find(option => option.value === updatedPost.teamId) || null}
            onChange={(selectedOption) => setUpdatedPost({ ...updatedPost, teamId: selectedOption ? selectedOption.value : null })}
          />
        ) : (
          <p>Loading teams...</p>
        )}

        <p><strong>Type</strong></p>
        {selectInteractionTypes.length > 0 ? (
          <Select
            id="interactionTypeId"
            isSearchable
            name="interactionTypeId"
            options={selectInteractionTypes}
            value={selectInteractionTypes.find(option => option.value === updatedPost.interactionTypeId) || null}
            onChange={(selectedOption) => setUpdatedPost({ ...updatedPost, interactionTypeId: selectedOption ? selectedOption.value : null })}
          />
        ) : (
          <p>Loading interaction types...</p>
        )}
        <hr />

        <div className="form-footer" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleDelete}
            style={{ backgroundColor: 'red', color: 'white' }}
          >
            Delete
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            style={{ backgroundColor: 'green', color: 'white' }}
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ProcessTweet;
