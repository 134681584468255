import React, { useState } from 'react';
import { rebuildNCAATransfers } from '../../services/algoliaIndex';

function ImportRebuildTransfers() {
   
    async function rebuildIndex() {
        rebuildNCAATransfers();
    }
    
    return (
        <div>
            <h3 style={{ marginBottom: '20px' }}>
                Rebuild NCAA Transfer Index
            </h3>

            <p>Click the button</p>

            <p><button style={{ marginTop: '5px' }} className="btn btn-success" onClick={() => rebuildIndex()}>
               Rebuild Index →
            </button></p>

        </div>
    );
}

export default ImportRebuildTransfers;
