
import Users from '../components/admin/Users';
import HighSchools from '../components/admin/HighSchools';
import npLogo from '../assets/imgs/np.png';
import { Sidebar, SubMenu, MenuItem, Menu } from 'react-pro-sidebar';
import { useState } from "react";
import { Panel } from '../components/shared';

const AdminPage = (props) => {
    const [activeMenuItem, setActiveMenuItem] = useState(null);

    const handleMenuItemClick = (menuItem) => {
        setActiveMenuItem(menuItem);
    };

    return (

        <div className="row" style={{ marginRight: '0', marginLeft: '0' }}>
            <div className="col-md-3 col-xs-12 d-flex justify-content-center custom-scrollbar" style={{ height: '100vh', overflowY: 'scroll', backgroundColor: '#222222' }}>
                <div className="container-wrapper filter-sidebar">
                    <div className="d-flex flex-row w-100 align-items-center">
                        <div className="p-2"><img alt="National Preps Logo" className="topnav-logo" src={npLogo}></img></div>
                        <div className="ml-2 no-break" style={{ fontSize: '24px' }}>System Admin</div>
                    </div>
                    <hr />
                    <section className="container-filters pt-3" >
                        <div className="container-body">
                            <Sidebar>
                            <Menu menuItemStyles={{
                                        button: {
                                            border: 'none',
                                            minWidth: '0px',
                                            marginLeft: '0',
                                            paddingLeft: '0',

                                            [`&.active`]: {
                                                backgroundColor: '#222222',
                                                color: '#7AFD78'

                                            },
                                            [`&:hover`]: {
                                                backgroundColor: '#222222',
                                                color: '#7AFD78 !important',

                                            }
                                        },
                                    }} >

                                <SubMenu label="Users" >
                                    <Panel>
                                        <MenuItem onClick={() => handleMenuItemClick("Users")} > Users</MenuItem>
                                        <MenuItem onClick={() => handleMenuItemClick("HighSchools")} > High Schools</MenuItem>
                                    </Panel>
                                </SubMenu>
                                <hr />
                                <SubMenu label="Teams" >
                                    <Panel>
                                        <MenuItem onClick={() => handleMenuItemClick("Colleges")} > Colleges</MenuItem>
                                        <MenuItem onClick={() => handleMenuItemClick("Teams")} > Teams</MenuItem>
                                    </Panel>
                                </SubMenu>

                                </Menu>
                            </Sidebar>
                        </div>
                    </section>
                </div>
            </div>
            <div className="col-md-9 col-xs-12" style={{ height: '100vh', overflowY: 'scroll' }}>
                <div className="box-content">
                    <main className="container" >
                        {activeMenuItem === "Users" && <Users />}
                        {activeMenuItem === "HighSchools" && <HighSchools />}

                    </main>
                </div>
            </div>
        </div>

    )
}

export default AdminPage;