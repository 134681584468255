import React, { useEffect, useState } from 'react';
import { TextField, FormControl, FormControlLabel, Checkbox, FormLabel, Button } from '@mui/material';
import { getStates, upsertTeam } from '../../services/supabase';
import { toast } from 'react-toastify';

const EditTeam = ({ team, onFormSubmit }) => {
  const [formData, setFormData] = useState({
    id: team.id,
    name: team.name,
    states: team.states || [],
    logo: team.logo,
    years: team.years || []
  });

  const [states, setStates] = useState([]);
  const [years, setYears] = useState([]);
  const [commaSeparatedStates, setCommaSeparatedStates] = useState('');

  useEffect(() => {
    setFormData({
      id: team.id,
      name: team.name,
      states: team.states || [],
      logo: team.logo,
      years: team.years || []
    });
  }, [team]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      if (name === 'states') {
        setFormData((prevData) => {
          const updatedStates = checked
            ? [...prevData.states, value]
            : prevData.states.filter((state) => state !== value);
          return { ...prevData, states: updatedStates };
        });
      } else if (name === 'years') {
        setFormData((prevData) => {
          const updatedYears = checked
            ? [...prevData.years, value]
            : prevData.years.filter((year) => year !== value);
          return { ...prevData, years: updatedYears };
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const handleCommaSeparatedStatesChange = (e) => {
    const value = e.target.value;
    setCommaSeparatedStates(value);

    const stateAbbrevs = value.split(',').map(state => state.trim().toUpperCase());
    setFormData(prevData => ({
      ...prevData,
      states: stateAbbrevs.filter(state => states.some(s => s.stateAbbrev === state))
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await upsertTeam(formData);
      toast.success('Team updated successfully');
      onFormSubmit(formData);
    } catch (error) {
      toast.error('Error updating team');
      console.error('Error updating team:', error);
    }
  };

  const handleSelectAllStates = () => {
    const allStateAbbrevs = states.map(state => state.stateAbbrev);
    setFormData(prevData => ({ ...prevData, states: allStateAbbrevs }));
  };

  const handleSelectAllYears = () => {
    const allYears = years.map(year => year.toString());
    setFormData(prevData => ({ ...prevData, years: allYears }));
  };

  async function fetchStates() {
    try {
      const states = await getStates();
      setStates(states);
    } catch (error) {
      console.error('Error fetching states:', error);
    }
  }

  async function fetchYears() {
    try {
      const currentYear = new Date().getFullYear();
      const years = Array.from({ length: 6 }, (_, i) => (currentYear + i).toString());
      setYears(years);
    } catch (error) {
      console.error('Error fetching years:', error);
    }
  }

  useEffect(() => {
    fetchStates();
    fetchYears();
  }, []);

  return (
    <div>
      <form onSubmit={handleSubmit} className="team-form">
        <h2>{formData.name}</h2>
        
        <FormControl fullWidth margin="normal">
          <TextField
            label="Team Name"
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </FormControl>

        <FormControl component="fieldset" margin="normal">
          <FormLabel component="legend">
            Grad Years
            <Button variant="text" onClick={handleSelectAllYears}>Select All</Button>
          </FormLabel>
          <div>
            {years.map((year) => (
              <FormControlLabel
                key={year}
                control={
                  <Checkbox
                    checked={formData.years.includes(year)}
                    onChange={handleChange}
                    name="years"
                    value={year}
                  />
                }
                label={year.toString()}
              />
            ))}
          </div>
        </FormControl>

        <FormControl component="fieldset" margin="normal">
          <FormLabel component="legend">
            States
            <Button variant="text" onClick={handleSelectAllStates}>Select All</Button>
          </FormLabel>
          <div>
            <TextField
              label="Comma Separated States"
              type="text"
              value={commaSeparatedStates}
              onChange={handleCommaSeparatedStatesChange}
              fullWidth
              margin="normal"
            />
            {states.map((state) => (
              <FormControlLabel
                key={state.stateAbbrev}
                control={
                  <Checkbox
                    checked={formData.states.includes(state.stateAbbrev)}
                    onChange={handleChange}
                    name="states"
                    value={state.stateAbbrev}
                  />
                }
                label={state.stateName}
              />
            ))}
          </div>
        </FormControl>

        <div className="form-footer">
          <Button variant="contained" color="primary" type="submit">Submit</Button>
        </div>
      </form>
    </div>
  );
};

export default EditTeam;
