
import instagramIcon from '../../assets/icons/instagram.svg';
import hudlIcon from '../../assets/icons/hudl-icon.png';
import fallbackImage from '../../assets/imgs/np.png';
import twitterIcon from '../../assets/icons/twitter-x.svg';


export default function AthleteCard(props) {
    const { hit } = props;

    const uniqueOffers = (hit.Activity?.filter(x => x.interactionTypeId === 2) || []).reduce((acc, current) => {
        if (!acc.some(offer => offer.teamName === current.teamName)) {
            acc.push(current);
        }
        return acc;
    }, []);
    const committed = hit.Activity?.find(x => x.interactionTypeId === 4 || x.interactionTypeId === 5);
    
    return (
        <>

            <div className="container w-100" style={{ backgroundColor: '#fff', padding: '20px' }}>
                <div className="row w-100">
                    {/* First Column - full height */}
                    <div className="col-md-2" style={{ textAlign: 'center' }}>
                        <img src={hit.image || fallbackImage} title={hit.name} alt={hit.name} style={{ width: '100px', borderRadius: '15px' }} />
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                            <a
                                style={{ color: 'green', textAlign: 'center' }}
                                href={`/recruit/view/${hit.id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <span>View Profile</span>
                            </a>
                        </div>

                        {/* <AddFavorite /> */}
                    </div>

                    {/* Second Column */}
                    <div className="col">
                        {/* First Row in Second Column */}
                        <div className="row w-100" style={{ borderBottom: '1px solid #ccc', marginBottom: '12px' }}>
                            <div className="col">
                                <h1 style={{ fontSize: '1.85rem' }}><strong>{hit.name.toUpperCase()}</strong>
                                    {hit.twitter && hit.twitter !== 'Unknown' ? <a target="_blank" rel="noreferrer" href={`https://twitter.com/${hit.twitter}`}><img src={twitterIcon} style={{ marginLeft: '15px' }} /></a> : ''}
                                    {hit.instagram && hit.instagram !== '' ? <a target="_blank" rel="noreferrer" href={`https://instagram.com/${hit.instagram}`} alt="Instagram"><img src={instagramIcon} style={{ marginLeft: '15px' }} /></a> : ''}
                                    {hit.hudl && hit.hudl !== '' ? <a target="_blank" rel="noreferrer" href={`${hit.hudl}`}><img style={{ width: '20px', marginLeft: '15px' }} src={hudlIcon} alt="HUDL"></img></a> : ''}
                                </h1>
                            </div>
                            <div className="col" style={{ textAlign: 'right' }}>
                                <p className="cardLine"><h2 style={{ fontSize: '1.85rem' }}>{hit.year ? hit.year : ''}</h2></p>
                            </div>
                        </div>

                        {/* Second Row in Second Column with Four Equal Columns */}
                        <div className="row w-100">
                            <div className="col">
                                <p className='cardLine'>
                                    {hit.highSchool ? <strong>{hit.highSchool}</strong> : ''}<br />
                                    {hit.hsCity}, {hit.hsState ? hit.hsState : ''} {hit.hsZip}
                                    {hit.county ? hit.County : ''}
                                </p>
                                {hit.athleteAddress || hit.athleteCity || hit.athleteState || hit.athleteZip || hit.email || hit.cellPhone ? (
                                    <p className='cardLine'>
                                        <strong>Athlete Information</strong><br />
                                        {hit.athleteAddress ? (
                                            <>
                                                {hit.athleteAddress}<br />
                                            </>
                                        ) : ''}
                                        {(hit.athleteCity || hit.athleteState || hit.athleteZip) ? (
                                            <>
                                                {hit.athleteCity ? hit.athleteCity : ''}{hit.athleteCity && hit.athleteState ? ', ' : ''}{hit.athleteState ? hit.athleteState : ''}{hit.athleteZip ? ` ${hit.athleteZip}` : ''}<br />
                                            </>
                                        ) : ''}
                                        {hit.email ? (
                                            <>
                                                <a href={`mailto:${hit.email}`}>{hit.email.toLowerCase()}</a><br />
                                            </>
                                        ) : ''}
                                        {hit.cellPhone ? (
                                            <>
                                                {hit.cellPhone}<br />
                                            </>
                                        ) : ''}
                                    </p>) : ''}

                                {hit.jersey ? (
                                    <p className='cardLine'>
                                        <strong>Jersey</strong><br />
                                        #{hit.jersey}
                                    </p>
                                ) : null}


                                <p className='cardLine'>
                                    <strong>NP Rating</strong><br />
                                    {hit.rank}
                                </p>

                            </div>
                            <div className="col">

                                <p className='cardLine'>
                                    <table className="table table-hover">
                                        <tr><td style={{ width: '60%' }}><strong>Height</strong></td><td>{hit.height ? hit.height : ''}</td></tr>
                                        <tr><td><strong>Weight</strong></td><td>{hit.weight ? hit.weight : ''}</td></tr>

                                        {hit.offPosition || hit.defPosition || hit.specialTeamsPos || hit.scoutPos ? (
                                            <>
                                                <tr><td colSpan={2} style={{ borderTop: '1px solid #ccc', marginBottom: '15px', height: '3px;' }}>&nbsp;</td></tr>
                                            </>) : ''}

                                        {hit.offPosition ? <tr><td style={{ width: '60%' }}><strong>Offense Position</strong></td><td>{hit.offPosition}</td></tr> : ''}
                                        {hit.defPosition ? <tr><td style={{ width: '60%' }}><strong>Defense Position</strong></td><td>{hit.defPosition}</td></tr> : ''}
                                        {hit.specialTeamsPos ? <tr><td style={{ width: '60%' }}><strong>Special Teams Position</strong></td><td>{hit.specialTeamsPos}</td></tr> : ''}
                                        {hit.scoutPos ? <tr><td style={{ width: '60%' }}><strong>Scout Position</strong></td><td>{hit.scoutPos}</td></tr> : ''}

                                        {hit.gpa || hit.sat || hit.act ? (
                                            <>
                                                <tr>
                                                    <td colSpan={2} style={{ borderTop: '1px solid #ccc', marginBottom: '15px', height: '3px' }}>&nbsp;</td>
                                                </tr>
                                            </>
                                        ) : ''}
                                        {hit.gpa ? (<>
                                            <tr>
                                                <td style={{ width: '60%' }}><strong>GPA</strong></td>
                                                <td>{hit.gpa}</td>
                                            </tr>
                                        </>
                                        ) : ''}
                                        {hit.sat ? (<>
                                            <tr>
                                                <td style={{ width: '60%' }}><strong>SAT</strong></td>
                                                <td>{hit.sat}</td>
                                            </tr>
                                        </>
                                        ) : ''}

                                        {hit.act ? (<>
                                            <tr>
                                                <td style={{ width: '60%' }}><strong>ACT</strong></td>
                                                <td>{hit.act}</td>
                                            </tr>
                                        </>
                                        ) : ''}


                                    </table>
                                </p>
                            </div>


                            <div className="col">
                                <p className='cardLine' style={{ textAlign: 'center' }}>
                                    {hit.isCommitted ? (<><i>Committed to</i> <strong>{hit.committedTeam}</strong><br /><br /><img title={hit.committedTeam} style={{ width: '100px' }} src={hit.committedTeamLogo} /></>) : null}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <div className="container w-100" style={{ backgroundColor: '#fff', padding: '20px', marginBottom: '20px' }}>
                {
                    uniqueOffers && uniqueOffers.length > 0 && (
                        <div className="row w-100" style={{ backgroundColor: '#fff' }}>
                            <div className="d-flex" style={{ borderTop: '1px solid #F2F2F2', paddingTop: '15px', overflowX: 'auto' }}>
                                <p>Offers<br /></p>
                                {uniqueOffers?.map((offer, idx) => (
                                    <div className="col-sm-12 col-md-1" key={idx} style={{ padding: '10px' }}>
                                        <img src={offer.logo} style={{ maxWidth: '55px' }} alt={offer.teamName} title={offer.teamName} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    )
                }
            </div>
        </>
    )

    {/* <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', padding: '10px 0' }}>
                        <button className="btn btn-success" onClick={() => addToFavorites(hit)} >Add to Favorites</button>
    
                    </div> */}

    {/* Offers Row */ }

}