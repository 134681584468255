import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import logo from '../../assets/imgs/np.png'
import NavDropdown from 'react-bootstrap/NavDropdown';
import { signOut } from '../../services/utilities';
import { supabaseClient } from '../../services/supabaseclient';

async function handleLogout() {
    await signOut();
}

const EndUserNavigation = (props) => {
    const { session, role } = props;

    return (
        <Navbar expand="lg" style={{ color: '#fff' }}>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', textAlign: 'center' }}>
                <div className='col-md-3 d-flex flex-column align-items-center justify-content-center pt-2'>
                   
                        <span>
                            <a href="/">
                                <img src={logo} style={{ width: '75px' }} alt="National Preps Logo" />
                            </a>
                        </span>
                        <span style={{ fontSize: '12px', color: '#fff' }}>{session.user.email} ({role?.User_Role?.type})</span>
                </div>
                <div className="col-md-9 d-grid align-items-top justify-content-end pt-2">

                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            {/* <Nav.Link href="/">Dashboard &nbsp; /</Nav.Link> */}
                            <Nav.Link href="/recruit/portal">Transfer Portal &nbsp; /</Nav.Link>
                            <Nav.Link href="/recruit/athlete">HS Athletes &nbsp; /</Nav.Link>
                            <Nav.Link href="/reports/highschools">High Schools &nbsp; /</Nav.Link>
                            <Nav.Link href="/reports/dailysummary">Daily Update</Nav.Link>

                            {role && role.User_Role?.type === 'A' ? (
                                <NavDropdown title=" /&nbsp;&nbsp;&nbsp;Admin" id="basic-nav-dropdown">
                                    <NavDropdown.Item href="/admin/athletes">Athletes</NavDropdown.Item>
                                    <NavDropdown.Item href="/admin/users">Users</NavDropdown.Item>
                                    <NavDropdown.Item href="/admin/teams">Teams</NavDropdown.Item>
                                    <NavDropdown.Item href="/admin/imports">Imports</NavDropdown.Item>
                                    <NavDropdown.Item href="/admin/tweets">Process Tweets</NavDropdown.Item>
                                    <NavDropdown.Item href="/admin/highschools">High Schools</NavDropdown.Item>
                                    <NavDropdown.Item href="/admin/scrapetweets">Scrape Tweets</NavDropdown.Item>
                                    <NavDropdown.Item href="/admin/processposts">Process Scraped Tweets</NavDropdown.Item>
                                </NavDropdown>
                            ) : null}

                            <Nav.Link style={{ marginLeft: '40px', marginRight: '40px', borderRadius: '5px', border: '1px solid #198754', backgroundColor: 'green' }} onClick={handleLogout}>Logout</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </div>
            </div>
        </Navbar>

    )
}

export default EndUserNavigation