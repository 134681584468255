import React, { useEffect, useState } from 'react';
import { TextField, FormControl, FormControlLabel, RadioGroup, Radio, FormLabel, Button, InputLabel } from '@mui/material';
import { getTeams, createUser } from '../../services/supabase';
import Select from 'react-select';
import { toast } from 'react-toastify';

const AddUser = () => {
  const [formData, setFormData] = useState({
    email: '',
    firstname: '',
    lastname: '',
    role: '3', // default value set to "User"
    team: '',
    password: ''
  });

  const customStyles = {
    control: base => ({
      ...base,
      height: 85,
      minHeight: 85
    })
  };

  const [selectTeams, setSelectTeams] = useState([]);
  const [team, setTeam] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleAddUser = async (e) => {
    e.preventDefault();
    const updatedFormData = {
      ...formData,
      team: parseInt(team.value),
      role: parseInt(formData.role)
    };

    // Handle form submission (e.g., send data to an API)
    const user = await createUser(updatedFormData);
    if (user) {
      toast.success("New User Added", {
        position: "top-right",
        autoClose: 1000,
    })}
    else {
      toast.error("Error Adding New User", {
        position: "top-right",
        autoClose: 1000,
      });
    }
  };

  async function fetchTeams() {
    try {
      const teams = await getTeams();
      teams.unshift({ id: 0, name: 'All Teams' });
      const sTeams = teams.map((x) => ({
        value: x.id,
        label: x.name
      }));
      setSelectTeams(sTeams);
    } catch (error) {
      console.error('Error fetching teams:', error);
    }
  }

  useEffect(() => {
    fetchTeams();
  }, []);

  return (
    <div>
      <form onSubmit={handleAddUser} className="user-form">
        <FormControl fullWidth margin="normal">
          <TextField
            label="Email"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            autoComplete="off"
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <TextField
            label="Password"
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
            autoComplete="off"
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <TextField
            label="First Name"
            type="text"
            name="firstname"
            value={formData.firstname}
            onChange={handleChange}
            required
            autoComplete="off"
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <TextField
            label="Last Name"
            type="text"
            name="lastname"
            value={formData.lastname}
            onChange={handleChange}
            required
            autoComplete="off"
          />
        </FormControl>
        <FormControl component="fieldset" margin="normal">
          <FormLabel component="legend">Role</FormLabel>
          <RadioGroup name="role" value={formData.role} onChange={handleChange}>
            <FormControlLabel value="1" control={<Radio />} label="Admin" />
            <FormControlLabel value="2" control={<Radio />} label="Super User" />
            <FormControlLabel value="3" control={<Radio />} label="User" />
          </RadioGroup>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel>Team</InputLabel>
          {selectTeams.length > 0 ? (
            <Select
              label=""
              placeholder=""
              id="team"
              isSearchable={true}
              isClearable={true}
              name="team"
              options={selectTeams}
              value={team}
              onChange={(selectedOption) => setTeam(selectedOption)}
              styles={customStyles}
            />
          ) : (
            <p>Loading teams...</p>
          )}
        </FormControl>
        <Button variant="contained" color="primary" type="submit">
          Submit
        </Button>
      </form>
    </div>
  );
};

export default AddUser;
