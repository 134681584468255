import React, { useState, useEffect } from "react";
import { getUnprocessedSocialPosts, getShallowRecruitByHandle, getStates } from "../../services/supabase";
import AdminDataGrid from "../shared/AdminDataGrid";
import ProcessTweet from "./ProcessTweet";
import AthleteAdd from "../athletes/AthleteAdd"; // Assuming this component exists
import { format } from 'date-fns';
import { Button, Drawer, Select, MenuItem } from '@mui/material';

const Tweets = (props) => {
    const [data, setData] = useState([]);
    const [states, setStates] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [socialPost, setSocialPost] = useState(null);
    const [loading, setLoading] = useState(true);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [drawerType, setDrawerType] = useState(null);
    const [state, setState] = useState('all');
    const [filter, setFilter] = useState('all');

    const fetchData = async () => {
        try {
            const noState = [];
            const tweets = [];
            const res = await getUnprocessedSocialPosts();
            res.map((x) => {
                if (x.Recruit && x.Recruit.hsState) {
                    x.state = x.Recruit.hsState;
                }
                else if (x.location && x.location.includes(',')) {
                    const l = x.location.split(',');
                    if (l.length > 1) {
                        x.state = l[1].trim();
                    }
                }
                else {
                    x.state = 'N/A';
                    noState.push(x);
                }
                tweets.push(x)
            });
            setData(tweets);
            console.log('No State:', noState.length);
            console.log('Tweets:', tweets.length);
            
            const statesRes = await getStates();
            setStates(statesRes);
            applyFilters(tweets, state, filter);
            setLoading(false);
        } catch (error) {
            console.error("Error:", error);
            setLoading(false);
        }
    };

    const handleRowClick = async (params) => {
        const post = params.row;
        setSocialPost(post);

        if (!post.recruitId) {
            console.log('No recruit found');
            try {
                const res = await getShallowRecruitByHandle(post.handle);
                if (res) {
                    console.log('Recruit found');
                    post.recruitId = res.id;
                    setSocialPost(post);
                    console.log(post);
                }
            } catch (error) {
                console.error("Error fetching recruit:", error);
            }
        }

        setDrawerType(post.recruitId ? 'process' : 'add');
        setDrawerOpen(true);
    };

    const handleFormSubmit = (postId, isDelete = false) => {
        if (isDelete) {
            setData(prevData => prevData.filter(post => post.postId !== postId));
            setFilteredData(prevData => prevData.filter(post => post.postId !== postId));
        } else {
            fetchData(); // Refresh data after form submission if it's an update
        }
        setSocialPost(null);
        setDrawerOpen(false);
    };
    /* 

    const handleFormSubmit = () => {
        setSocialPost(null);
        setDrawerOpen(false);
        fetchData(); // Refresh data after form submission
    };
 */
    const handleFilterChange = (event) => {
        const selectedFilter = event.target.value;
        setFilter(selectedFilter);
        applyFilters(data, state, selectedFilter);
    };

    const handleStateChange = (event) => {
        const selectedState = event.target.value;
        setState(selectedState);
        applyFilters(data, selectedState, filter);
    };

    const applyFilters = (data, selectedState, selectedFilter) => {
        let filtered = data;

        if (selectedState !== 'all') {
            filtered = filtered.filter((x) => x.state.toUpperCase() === selectedState);
        }

        if (selectedFilter === 'RecruitUnknown') {
            filtered = filtered.filter((x) => x.recruitId === null);
        } else if (selectedFilter === 'TypeUnknown') {
            filtered = filtered.filter((x) => x.interactionTypeId === null);
        } else if (selectedFilter === 'TeamUnknown') {
            filtered = filtered.filter((x) => x.teamId === null);
        }

        setFilteredData(filtered);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const columns = [
        {
            field: "tweetCreated",
            headerName: "Date",
            flex: 1,
            sortable: true,
            renderCell: (params) => (
                <>
                    <p>
                        {format(new Date(params.row.tweetCreated), 'MM/dd/yyyy')}
                    </p>
                </>
            )
        },
        {
            field: "hsState",
            headerName: "State",
            flex: 1,
            sortable: true,
            renderCell: (params) => (
                <>
                    <p>
                        <strong>
                            {params.row.state}
                        </strong>
                    </p>
                </>
            )
        },
        {
            field: "handle",
            headerName: "Handle",
            flex: 2,
            sortable: true,
            renderCell: (params) => (
                <>
                    <p>
                        <strong>
                            {params.row.handle} ({params.row.Recruit ? params.row.Recruit.firstName + ' ' + params.row.Recruit.lastName : 'Unknown'})
                        </strong>
                    </p>
                </>
            )
        },
        {
            field: "description",
            headerName: "Description",
            flex: 2,
            sortable: true,
            renderCell: (params) => (
                <>
                    {params.row.description}
                </>
            )
        },
        {
            field: "content",
            headerName: "Tweet",
            flex: 4,
            sortable: false
        },
        {
            field: "teamId",
            headerName: "Team",
            flex: 2,
            sortable: true,
            renderCell: (params) => (
                <>
                    {params.row.Team?.name}
                </>
            )
        },
        {
            field: "interactionTypeId",
            headerName: "Type",
            flex: 1,
            sortable: true,
        },
    ];

    return (
        <div className="row w-100">
            <div className="col-12">
                {loading ? (
                    <p>Loading...</p>
                ) : (
                    <>
                        <div style={{ marginBottom: '20px' }}>
                            <h2>{filteredData.length} Unprocessed Tweets</h2>

                            <Select value={state} onChange={handleStateChange} style={{width:'200px'}}>
                                <MenuItem value="all">All</MenuItem>
                                {states.map((state) => (
                                    <MenuItem key={state.stateAbbrev} value={state.stateAbbrev}>
                                        {state.stateName}
                                    </MenuItem>
                                ))}
                            </Select> &nbsp;&nbsp;&nbsp;

                            <Select value={filter} onChange={handleFilterChange}  style={{width:'200px'}}>
                                <MenuItem value="all">All</MenuItem>
                                <MenuItem value="RecruitUnknown">Recruit Unknown</MenuItem>
                                <MenuItem value="TypeUnknown">Type Unknown</MenuItem>
                                <MenuItem value="TeamUnknown">Team Unknown</MenuItem>
                            </Select>
                                <br /><br />
                            <AdminDataGrid data={filteredData} columns={columns} onRowClick={handleRowClick} height={200} />
                        </div>
                    </>
                )}
            </div>
            <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                sx={{ width: '400px'}}
            >
                {socialPost && (
                    <div style={{ padding: '20px' }}>
                        <ProcessTweet post={socialPost} onFormSubmit={handleFormSubmit} />
                    </div>
                )}
            </Drawer>
        </div>
    );
};

export default Tweets;
