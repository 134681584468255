import {
  getUserList,
  deleteUser,
  getAllAuthUsers,
} from "../../services/supabase";
import { useState, useEffect } from "react";
import { escapeValue } from "../../services/utilities";
import AdminDataGrid from "../shared/AdminDataGrid";
import fallbackImage from "../../assets/imgs/unknown.png";
import EditUser from "./EditUser";
import AddUser from "./AddUser";

const Users = (props) => {
  const [data, setData] = useState(null);
  const [user, setUser] = useState(null);
  const [addUser, setAddUser] = useState(false);

  async function fetchData() {
    try {
      const res = await getUserList();
      setData(res);
    } catch (error) {
      console.error("Error fetching user list:", error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const handleRowClick = (params) => {
    setUser(params.row);
    setAddUser(false);
  };

  const handleAddUserClick = () => {
    setAddUser(!addUser);
    setUser(null);
  };

  const handleDelUserClick = async () => {
    if (user) {
      const confirm = window.confirm(
        `Are you sure you want to delete ${user.firstname} ${user.lastname}?`
      );
      if (confirm) {
        try {
          // Call deleteUser API
          await deleteUser(user.uuid);
          console.log("Deleted user", user);

          // Remove the deleted user from the list
          setData((prevData) =>
            prevData.filter((item) => item.uuid !== user.uuid)
          );

          // Clear selected user
          setUser(null);
        } catch (error) {
          console.error("Error deleting user:", error);
        }
      }
    }
  };

  const handleExport = async () => {
    const csvRows = [];
    const headers = [
      "First Name",
      "Last Name",
      "Email",
      "Role",
      "Team",
      "Last Login",
    ];
    csvRows.push(headers.join(","));

    const authUsers = await getAllAuthUsers();

    // Group the users by team
    const groupedUsers = data.reduce((acc, user) => {
      const teamName = user.Team ? user.Team.name : "No Team Assigned";
      if (!acc[teamName]) {
        acc[teamName] = [];
      }
      acc[teamName].push(user);
      return acc;
    }, {});

    // Iterate through each team group
    Object.keys(groupedUsers).forEach((teamName) => {
      // Add a row for the team name
      //csvRows.push(`\nTeam: ${teamName}`);

      // Add user data under each team
      groupedUsers[teamName].forEach((user) => {
        const lastLogin = authUsers.find(
          (x) => x.email === user.email
        )?.last_sign_in_at;
        const values = [
          escapeValue(user.firstname),
          escapeValue(user.lastname),
          escapeValue(user.email),
          escapeValue(user.User_Role?.label),
          escapeValue(teamName),
          lastLogin,
        ];
        if ((user.firstname && user.firstname.trim() !== '') && (user.lastname && user.lastname.trim() !== '') && (user.email && user.email.trim() !== '')) {
          csvRows.push(values.join(","));
        }
      });
    });

    // Convert to CSV and trigger download
    const csvData = csvRows.join("\n");
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "users_by_team.csv";
    a.click();
  };

  const columns = [
    {
      field: "logo",
      headerName: "School",
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <img
          style={{ width: "45px" }}
          src={params.row.Team ? params.row.Team.logo : fallbackImage}
          alt="profile"
        />
      ),
    },
    {
      field: "Team",
      headerName: "Team",
      flex: 3,
      valueGetter: (params) => {
        return params.row.Team ? params.row.Team.name : "No Team Assigned";
      },
    },
    {
      field: "firstname",
      headerName: "First Name",
      sortable: true,
      renderCell: (params) => `${params.row.firstname} ${params.row.lastname}`,
      flex: 3,
    },
    {
      field: "User_Role",
      headerName: "Role",
      valueGetter: (params) => {
        return params.row.User_Role?.label;
      },
      flex: 2,
    },
  ];

  return (
    <div className="w-100">
      <div
        className="row w-100"
        style={{ marginBotton: "20px", justifyContent: "end" }}
      >
        <button
          className="btn btn-success"
          style={{ float: "right", width: "150px", marginBottom: "20px" }}
          onClick={handleExport}
        >
          Export Users
        </button>
        <hr />
      </div>
      <div className="row w-100">
        <div className="col-md-6">
          {data ? (
            <div>
              {/* <p><button className="btn btn-success" onClick={handleExport}>Export Users</button></p> */}
              <AdminDataGrid
                data={data}
                columns={columns}
                onRowClick={handleRowClick}
              />
            </div>
          ) : (
            <p>Loading...</p>
          )}
        </div>
        <div className="col-md-6">
          <button
            className="btn btn-success"
            style={{ float: "left" }}
            onClick={handleAddUserClick}
          >
            {addUser ? "Cancel" : "Add New User"}
          </button>

          {user ? (
            <div style={{ width: "100%", paddingBottom: "20px" }}>
              <button
                className="btn btn-danger"
                style={{ float: "right" }}
                onClick={handleDelUserClick}
              >
                {addUser ? "Cancel" : "Delete User"}
              </button>
              <EditUser key={user.id} user={user} />
            </div>
          ) : addUser ? (
            <AddUser />
          ) : (
            <p style={{ clear: "both", paddingTop: "20px" }}>
              Please select a user or click the Add a New User button...
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Users;
