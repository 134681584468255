import React, { useEffect, useState } from 'react';

import { parseDate } from '../../services/utilities';



import instagramIcon from '../../assets/icons/instagram.svg';
import hudlIcon from '../../assets/icons/hudl-icon.png';
import fallbackImage from '../../assets/imgs/np.png';
import twitterIcon from '../../assets/icons/twitter-x.svg';
import unknownImage from '../../assets/imgs/unknown.png'
import pffImage from '../../assets/imgs/pff.png';
import espnIcon from '../../assets/icons/espn.svg';

import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { type } from '@testing-library/user-event/dist/type';
import { fetchFavoriteByUser, getAllTeamsAndAbbreviations, getTeams } from '../../services/supabase';
import RecruitNav from '../../layouts/navigation/EndUserNavigation';




export function FavoriteList() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const isEditMode = searchParams.get('edit') === 'true';

    const [favoriteList, setFavoriteList] = useState(null);
    const [teamList, setTeamList] = useState([]);

    function openAthleteDetailsInNewTab(id) {
        const url = `/admin/athlete/${id}`;
        window.open(url, '_blank');
    }

    async function fetchFavorite(type) {
        const data = await fetchFavoriteByUser('Transfer');

        return data
    }

    useEffect(() => {
        const mount = async () => {
            try {
                const data = await fetchFavorite('Transfer')
                setFavoriteList(data)

                const teamData = await getTeams()
                setTeamList(teamData)

                console.log('teamData:', teamData)
                console.log('favList:', data)
            } catch (error) {
                console.log('Error mounting data', error);
                toast.error('Error mounting data', {
                    autoClose: 5000,
                    position: 'top-right',
                });
            }
        };
        mount();
    }, []);


    const getTeamLogo = (name) => {

        console.log('name:', teamList)
        const team = teamList.find(obj => obj.name === name)
        return `${team.logo}`
    }

    return (
        <>
            <RecruitNav />
            <div className="container w-100">
                <div className="row w-100">

                    {favoriteList && teamList.length && favoriteList.map((favList, index) => (
                        <>
                            <div className="col-sm-12 d-flex" style={{ justifyContent: 'space-around', marginTop:'20px' }}>
                                <div className="pr-3 d-flex flex-row col-md-3 col-sm-12">
                                    <header className="hit-image-container align-items-left">
                                        <img src={favList.transfers.image || fallbackImage} title={favList.transfers.name} alt={favList.transfers.name} style={{ width: '200px', borderRadius: '15px' }} />
                                        <p style={{ fontSize: '12px', marginTop: '8px' }}>
                                            Entered: <strong>{favList.transfers.enteredPortalDate ? parseDate(favList.transfers.enteredPortalDate) : 'Unknown'}</strong><br />
                                            {parseDate(favList.transfers.lastInteractionDate) !== parseDate(favList.transfers.enteredPortalDate) ? (
                                                <>
                                                    Updated: <strong>{favList.transfers.lastInteractionDate ? parseDate(favList.transfers.lastInteractionDate) : 'Unknown'}</strong>
                                                </>
                                            ) : null}
                                        </p>
                                    </header>
                                </div>
                                <div className="d-flex col-md-5 col-sm-12">

                                    <h1 className="hit-name w-100">
                                        <strong>{favList.transfers.firstName} {favList.transfers.lastName}</strong>
                                        <div className="d-flex mt-2">
                                            {favList.transfers.pffId && favList.transfers.pffId !== '' ? <div className="m-2 p-10"><a target="_blank" rel="noreferrer" href={`https://ultimate.pff.com/ncaa/players/${favList.transfers.pffId}/snaps_and_grades`}><img src={pffImage} style={{ width: '50px' }} /></a></div> : ''}
                                            {favList.transfers.twitterHandle && favList.transfers.twitterHandle !== 'Unknown' ? <div className="m-2 p-10"><a target="_blank" rel="noreferrer" href={`https://twitter.com/${favList.transfers.twitterHandle}`}><img src={twitterIcon} /></a></div> : ''}
                                            {favList.transfers.instagramHandle && favList.transfers.instagramHandle !== '' ? <div className="m-2 p-10"><a target="_blank" rel="noreferrer" href={`https://instagram.com/${favList.transfers.instagramHandle}`} alt="Instagram"><img src={instagramIcon} /></a></div> : ''}
                                            {favList.transfers.hudlUrl && favList.transfers.hudlUrl !== '' ? <div className="m-2 p-10"><a target="_blank" rel="noreferrer" href={`${favList.transfers.hudlUrl}`}><img style={{ width: '20px' }} src={hudlIcon} alt="HUDL"></img></a></div> : ''}
                                            {favList.transfers.espnUrl && favList.transfers.espnUrl !== '' ? <div className="m-2 p-10"><a target="_blank" rel="noreferrer" href={`${favList.transfers.espnUrl}`}><img style={{ width: '60px' }} src={espnIcon} alt="ESPN"></img></a></div> : ''}
                                        </div>
                                        <p style={{ fontSize: '16px', marginTop: '20px' }}>
                                            {favList.transfers.experience && favList.transfers.experience != 'Unknown' ? favList.transfers.experience + ' | ' : ''}
                                            {favList.transfers.position ? favList.transfers.position + ' | ' : ''}
                                            {favList.transfers.height ? Math.floor(favList.transfers.height/12) + '\' ' + (favList.transfers.height%12) + '" | ' : ''}
                                            {favList.transfers.weight ? favList.transfers.weight + ' lbs | ' : ''}
                                            {favList.transfers.location && favList.transfers.location != 'Unknown' ? favList.transfers.location : ''}</p>
                                        <p style={{ fontSize: '12px', marginTop: '8px' }}>
                                            {favList.transfers.snaps ? <div className="stats-table-row">{favList.transfers.snaps} snaps in 2023</div> : ''}
                                            {favList.transfers.gradStudent ? <div className="stats-table-row"><div>Grad Student</div></div> : ''}
                                            {favList.transfers.multipleTransfers ? <div className="stats-table-row"><div>Multiple Transfers</div></div> : ''}
                                            {favList.transfers.receivingFinancialAid ? <div className="stats-table-row">Receives Financial Aid</div> : ''}
                                        </p>

                                    </h1>
                                </div>

                                <div className="d-flex col-md-4 col-sm-12">
                                    <table className="table" style={{ width: '300px' }}>
                                        <thead>
                                            <tr>
                                                <th className="text-center">
                                                    Exiting
                                                </th>
                                                <th className="text-center">
                                                    Committed
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="text-center align-middle" style={{ textAlign: 'center' }}>
                                                    {favList.transfers.fromInstitutionName ? (
                                                        <img src={getTeamLogo(favList.transfers.fromInstitutionName )} title={favList.transfers.exitTeam} alt={favList.transfers.exitTeam} style={{ maxWidth: '65px', margin: 'auto', display: 'block' }} />
                                                    ) : (
                                                        'hi'
                                                    )}
                                                </td>
                                                <td className="text-center align-middle" style={{ textAlign: 'center' }}>
                                                    {favList.transfers.committedTeam?.logo ?
                                                        <img src={favList.transfers.committedTeam?.logo} title={favList.transfers.committedTeam?.name} alt={favList.transfers.committedTeam?.name} style={{ maxWidth: '55px', margin: 'auto', display: 'block' }} /> :
                                                        <img src={unknownImage} alt="Default" style={{ width: '50px', margin: 'auto', display: 'block' }} />
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="text-center" style={{ textAlign: 'center' }}>
                                                    <span style={{ fontWeight: 'normal', color: 'red' }}> {favList.transfers.fromInstitutionName}</span>
                                                </td>
                                                <td className="text-center" style={{ textAlign: 'center' }}>
                                                    <span style={{ fontWeight: 'normal', color: 'green' }}>{favList.transfers.committedTeam?.name ? favList.transfers.committedTeam.name : 'TBD'}</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>


                            {favList.transfers.offercount > 0 ? (
                                <div className="d-flex" style={{ borderTop: '1px solid #F2F2F2', paddingTop: '15px', overflowX: 'auto' }}>
                                    <p>Offers<br /></p>
                                    {
                                        favList.transfers.offers?.map((offer, idx) => {
                                            return (
                                                <div key={idx} style={{ padding: '10px' }}><img src={offer.logo} style={{ maxWidth: '55px' }} alt={offer.name} title={offer.name} /></div>
                                            )
                                        })
                                    }
                                </div>
                            ) : ''
                            }
                        </>
                    ))}
                </div>
            </div>
        </>
    );
};

