import algoliasearch from "algoliasearch";
import { getTeams, getRecruitsInBatches, getRecruit, getHighSchools } from "./supabase";
import { delActivityFromIndex, delRecruitActivitiesFromIndex } from "./algoliaActivities";
import { convertToUnixTimestamp } from "./utilities";
import { rebuildActivity } from "./algoliaActivities";
import pLimit from 'p-limit'; // If using a concurrency limiter library

// Initialize Algolia client
const client = algoliasearch('ETT8OD4DUG', 'bb501e3aa5c51ecc4515f46406dc894c');

const indexName = 'PROD_RECRUIT_ATHLETE';
const BATCH_SIZE = 100; // Size of each batch

// Cache for teams
let teamsCache = null;
let hsCache = null;

async function getHighSchoolsOnce() {
 if (!hsCache) {
   hsCache = await getHighSchools();
 }
 return hsCache
}

async function getTeamsOnce() {
  if (!teamsCache) {
    teamsCache = await getTeams();
  }
  return teamsCache;
}

export async function deleteRecruitFromIndex(recruit) {
  const index = client.initIndex(indexName);
  
  await index.deleteObject(recruit.id);
  if (recruit.Activity && recruit.Activity.length > 0) {
    for (const activity of recruit.Activity) {
      await delActivityFromIndex(activity.id);
    }
  }
  
  return true;
}

export async function rebuildRecruit(id) {
  const recruit = await getRecruit(id);

  if (recruit) {
    const index = client.initIndex(indexName);
    const teams = await getTeamsOnce();
    const highSchools = await getHighSchoolsOnce();

    const newIndexEntry = buildIndexEntry(recruit, teams, highSchools);
    if (newIndexEntry) {
      await index.saveObject(newIndexEntry, { autoGenerateObjectIDIfNotExist: true });
      await rebuildActivity(id);
    }
  }
}

export async function rebuildRecruits() {
  console.log('STARTING REBUILD');
  await clearIndex(indexName);
  await clearIndex('PROD_RECRUIT_ACTIVITIES');

  let batchStart = 0;
  let recruitsBatch = [];
  const limit = pLimit(10); // Limit concurrency to 10 recruits at a time

  do {
    recruitsBatch = await getRecruitsInBatches(batchStart, BATCH_SIZE);

    if (!Array.isArray(recruitsBatch)) {
      console.error('Error fetching recruits batch:', recruitsBatch);
      break;
    }

    console.log(`Fetched ${recruitsBatch.length} recruits starting from ${batchStart}`);

    if (recruitsBatch.length > 0) {
      // Process each recruit in parallel with a concurrency limit
      const promises = recruitsBatch.map(recruit =>
        limit(() => rebuildRecruit(recruit.id))
      );

      try {
        await Promise.all(promises); // Await all recruits in the batch
      } catch (error) {
        console.error('Error rebuilding recruits:', error);
      }
    }

    batchStart += BATCH_SIZE;

  } while (recruitsBatch.length === BATCH_SIZE);

  console.log('FINISHED REBUILD');
}

function heightToInches(height) {
  const [feet, inches] = height.split("'");
  const feetInInches = parseInt(feet, 10) * 12;
  const totalInches = feetInInches + (inches ? parseInt(inches, 10) : 0);
  return totalInches;
}

function buildIndexEntry(recruit, teams, highSchools) {
  if (recruit && recruit.rank && recruit.rank !== 'Q (Questionnaire)' && recruit.id != 18759) {
    recruit.objectID = recruit.id;
    recruit.npPlayerId = recruit.npPlayerId;
    recruit.displayLocation = (recruit.hsCity ? recruit.hsCity + ", " : '') + (recruit.hsState ? recruit.hsState : '');
    recruit.location = recruit.hsState ? recruit.hsState : 'Unknown';

    const highSchool = highSchools.find(x => x.ETS.toUpperCase() === recruit.ets?.toUpperCase());
    if (highSchool && recruit.ets && recruit.ets.trim() !== '') {
      recruit.highSchool = highSchool.School;
      recruit.hsState = highSchool.State;
      recruit.hsStreet = highSchool.Address;
      recruit.hsCity = highSchool.City;
      recruit.hsZip = highSchool.Zip;
      recruit.hsCounty = highSchool.County;
      recruit.hsPhone = highSchool.Phone;
      recruit.coachName = highSchool.Coach;
      recruit.coachEmail = highSchool.Email;
      recruit.coachPhone = highSchool.Cell;
      recruit.coachTwitter = highSchool.Twitter;
    }

    recruit.isSigned = false;
    recruit.isCommitted = false;
    recruit.hasOffers = false;
    recruit.offerNumber = 0;
    recruit.status = "Uncommitted";
    if (recruit.highSchool) {
      recruit.highSchool = !recruit.highSchool.includes('(' + recruit.location + ')') ? recruit.highSchool + " (" + recruit.location + ")" : recruit.highSchool;
    }

    const limitedInteractions = [];

    if (recruit.Activity && recruit.Activity.length > 0) {
      for (const activity of recruit.Activity) {
        if (activity.teamId !== null) {
          const t = teams.find(x => x.id === activity.teamId);
          if (t) {
            if (activity.interactionTypeId === 4) {
              recruit.isCommitted = true;
              recruit.committedTeam = activity.teamName;
              recruit.committedTeamLogo = activity.logo;
            }
            if (activity.interactionTypeId === 2) {
              recruit.hasOffers = true;
              recruit.offerNumber++;
            }
            
            const newActivity = {
              interactionType: activity.interactionTypeId,
              created_at: activity.activity_at,
              date: convertToUnixTimestamp(activity.activity_at),
              id: t.id,
              name: t.name,
              logo: t.logo,
              conference: t.College?.Conference?.name,
              division: t.College?.Conference?.conferenceDivisionId?.toUpperCase()
            };
            limitedInteractions.push(newActivity);
          } else {
            console.log('Team not found:', activity.teamId);
          }
        }
      }
    }

    if (recruit.isCommitted) {
      recruit.status = "Committed";
    } else if (recruit.hasOffers) {
      recruit.status = "Uncommitted with offers";
    }

    recruit.gpa = recruit.gpa ? parseFloat(recruit.gpa) : 0;
    recruit.weight = recruit.weight ? parseFloat(recruit.weight) : 0;
    recruit.sat = recruit.sat ? parseInt(recruit.sat) : 0;
    recruit.act = recruit.act ? parseInt(recruit.act) : 0;

    recruit.heightInches = recruit.height ? heightToInches(recruit.height) : 0;
    recruit.name = `${recruit.firstName} ${recruit.lastName}`;
    recruit.Activity = limitedInteractions;
    recruit.twitter = recruit.twitter || "Unknown";
    recruit.added = convertToUnixTimestamp(recruit.created_at);

    return recruit;
  }
  return null;
}

export async function clearIndex(indexName) {
  try {
    const index = client.initIndex(indexName);
    await index.clearObjects();
    return true;
  } catch (error) {
    console.log("ERROR: ", error);
    return false;
  }
}
