import React, { useState, useEffect } from 'react';
import { getTransfers, getNCAATransfers, upsertNCAATransfer } from '../../services/supabase';

function ImportTwitterHandles() {
    const [loading, setLoading] = useState(true);
    const [transfers, setTransfers] = useState([]);
    const [NCAAtransfers, setNCAATransfers] = useState([]);
    

    useEffect(() => {
        async function fetchData() {
            try {
                const transfers = await getTransfers();
                const NCAATransfers = await getNCAATransfers();
                setTransfers(transfers);
                setNCAATransfers(NCAATransfers);
                console.log('Transfers:', transfers.length)
                console.log('NCAA Transfers:', NCAAtransfers.length)
            } catch (error) {
                console.error('Error fetching teams:', error);
            }
        }

        fetchData();
    }, []);

    async function updateInfo(transfer, ncaaTransfer) {
        ncaaTransfer.athleteId = transfer.id;
        ncaaTransfer.interactions = transfer.interactions;
        ncaaTransfer.lastTeam = transfer.lastTeam;
        ncaaTransfer.twitterHandle = transfer.twitterHandle;
        ncaaTransfer.threadsHandle = transfer.threadsHandle;
        ncaaTransfer.instagramHandle = transfer.instagramHandle;
        ncaaTransfer.graduatingYear = transfer.graduatingYear;
        ncaaTransfer.image = transfer.image;
        if (!ncaaTransfer.state && transfer.athleteStateId){
            ncaaTransfer.state = transfer.athleteStateId;
        }
        if (!ncaaTransfer.city && transfer.city){
            ncaaTransfer.city = transfer.city;
        }
        await upsertNCAATransfer(ncaaTransfer)
    }

    async function importTwitter() {
        for (const transfer of transfers) {
            /* if (transfer.pffId) {
                const ncaaTransfer = NCAAtransfers.find(x => x.pffId === transfer.pffId)
                if (ncaaTransfer) {
                    console.log('Found PFF Id Info')
                    updateInfo(transfer, ncaaTransfer)
                }
            } */
            if (!transfer.athleteId) //no pffId
            {
                const ncaaTransfer = NCAAtransfers.find(x => x.firstName === transfer.fname.toUpperCase() && x.lastName === transfer.lname.toUpperCase())
                if (ncaaTransfer) {
                    console.log('Found athlete by name', ncaaTransfer.firstName + ' ' + ncaaTransfer.lastName)
                    await updateInfo(transfer, ncaaTransfer)
                }
            }
        }
    }

    
    return (
        <div>
            <h3 style={{ marginBottom: '20px' }}>
                Import Twitter Handles
            </h3>

            <p>Click the button</p>

            <p><button style={{ marginTop: '5px' }} className="btn btn-success" onClick={() => importTwitter()}>
               Import Twitter Handles →
            </button></p>
        </div>
    );
}

export default ImportTwitterHandles;
