import React, { useState } from 'react';
import npLogo from '../../assets/imgs/np.png';
import { useNavigate } from 'react-router-dom';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { useLocation } from 'react-router-dom';
import ImportPFF from './ImportPFF';
import ImportESPN from './ImportESPN';
import ImportNCAA from './ImportNCAA';
import ImportTwitterHandles from './ImportTwitterHandles'
import ImportTransfers from './ImportRebuildTransfers';
import ImportRecruits from './ImportRebuildRecruits';
import ImportActivities from './ImportRebuildActivities';

export default function Imports() {
    const navigate = useNavigate();
    const location = useLocation();
    const [activeMenuItem, setActiveMenuItem] = useState(null);

    const handleMenuItemClick = (menuItem) => {
        setActiveMenuItem(menuItem);
    };

    return (
        <div className="row" style={{ marginRight: '0', marginLeft: '0' }}>
            <div className="col-md-3 col-xs-12 d-flex justify-content-center custom-scrollbar" style={{ height: '100vh', overflowY: 'scroll', backgroundColor: '#222222' }}>

                <div className="filter-sidebar">
                    <h3 style={{ width: '100%', textAlign: 'center' }}>Recruits</h3>

                    <hr />
                    <section className="container-filters pt-3" >
                        <div className="container-body">

                            <Sidebar style={{ width: '100%' }}>
                                <Menu menuItemStyles={{
                                    button: {
                                        border: 'none',
                                        minWidth: '0px',
                                        marginLeft: '0',
                                        paddingLeft: '0',
                                        backgroundColor: activeMenuItem === "PFFPlayers" ? '#222222' : 'inherit',
                                        color: activeMenuItem === "PFFPlayers" ? '#7AFD78' : 'inherit',
                                    },
                                    [`&:hover`]: {
                                        backgroundColor: '#222222 !important',
                                        color: '#7AFD78 !important',
                                    }
                                }}>
                                    <MenuItem onClick={() => handleMenuItemClick("ImportPFF")} > Import PFF Players</MenuItem>
                                    <MenuItem onClick={() => handleMenuItemClick("ImportESPN")} > Import ESPN Rosters</MenuItem>
                                    <MenuItem onClick={() => handleMenuItemClick("ImportNCAA")} > Import NCAA Transfer Players</MenuItem>
                                    <MenuItem onClick={() => handleMenuItemClick("ImportTwitterHandles")} > Import Twitter and Offers</MenuItem>
                                    <MenuItem onClick={() => handleMenuItemClick("RebuildTransfers")} > Build Transfer Index</MenuItem>
                                    <MenuItem onClick={() => handleMenuItemClick("RebuildRecruits")} > Build Recruit Index</MenuItem>
                                    <MenuItem onClick={() => handleMenuItemClick("RebuildActivities")} > Build Activities Index</MenuItem>

                                </Menu>
                            </Sidebar>
                        </div>
                    </section>
                </div>

            </div>

            <div className="col-md-9 col-xs-12" style={{ height: '100vh', overflowY: 'scroll', backgroundColor: '#F3F3F3' }}>

                <div>
                    <main className="container" >
                        <section className="container-results col-md-12">

                            {activeMenuItem === "ImportPFF" && <ImportPFF />}
                            {activeMenuItem === "ImportESPN" && <ImportESPN />}
                            {activeMenuItem === "ImportNCAA" && <ImportNCAA />}
                            {activeMenuItem === "ImportTwitterHandles" && <ImportTwitterHandles />}
                            {activeMenuItem === "RebuildTransfers" && <ImportTransfers />}
                            {activeMenuItem === "RebuildRecruits" && <ImportRecruits />}
                            {activeMenuItem === "RebuildActivities" && <ImportActivities />}
                        </section>
                    </main>
                </div>
            </div>
        </div>
    );
}
