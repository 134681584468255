import { getHighSchools } from "../../services/supabase";
import React, { useState, useEffect } from "react";
import AdminDataGrid from "../shared/AdminDataGrid";
import EditHighSchool from "./EditHighSchool";
import { Drawer } from '@mui/material';

const HighSchools = (props) => {
    const [data, setData] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [hs, setHighSchool] = useState(null);

    const handleRowClick = async (params) => {
        const hs = params.row;
        setHighSchool(hs);
        setDrawerOpen(true);
    };

    const handleAddHighSchool = async (params) => {
        setHighSchool(null);
        setDrawerOpen(true);
    };

    const fetchData = async () => {
        try {
            const res = await getHighSchools();
            setData(res);
        } catch (error) {
            console.error("Error:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []); // Empty dependency array ensures this runs only once

    const columns = [
        { field: 'School', headerName: 'Name', flex: 4, sortable: true },
        { field: 'ETS', headerName: 'ETS', flex: 2, sortable: true },
        {
            field: 'Address', headerName: 'Address', flex: 4, sortable: true, renderCell: (params) => (
                <div>
                    {params.row.Address}<br />
                    {params.row.City}, {params.row.State} {params.row.Zip}
                </div>
            )
        },
        { field: 'State', headerName: 'State', flex: 1, sortable: true },
        { field: 'County', headerName: 'County', flex: 2, sortable: true },
        {
            field: 'Coach',
            headerName: 'Coach',
            flex: 4,
            sortable: true,
            renderCell: (params) => (
                <div>
                    {params.row.Coach ? params.row.Coach : 'Unknown'}<br />
                    {params.row.Phone ? params.row.Phone : ''}<br />
                    {params.row.Cell}<br />
                    <a href={`mailto:${params.row.Email}`}>{params.row.Email}</a><br />
                    <a href={`https://twitter.com/${params.row.Twitter}`} target="_blank" rel="noopener noreferrer">
                        {params.row.Twitter}
                    </a>
                </div>
            )

        }
    ];

    return (
        <div style={{ width: '100%' }}>
            {data ? (
                <div>
                    <button className="btn btn-success" onClick={handleAddHighSchool}>Add New High School</button>
                    <AdminDataGrid data={data} columns={columns} onRowClick={handleRowClick} height={100} />
                    <Drawer anchor='right' open={drawerOpen} onClose={() => setDrawerOpen(false)}>
                        <div style={{ width: '400px', padding: 20 }}>
                            <EditHighSchool highSchool={hs} />
                        </div>
                    </Drawer>
                </div>
            ) : null}
        </div>
    );
};

export default HighSchools;
