import { Card, CardBody } from "react-bootstrap";
import DailySummary from "../components/reports/DailySummary";


const DashboardPage = (props) => {
const {title} = props;
    return (

<main className="main container-fluid" >
            <div  className="no-padding no-margin">
                <DailySummary />
            </div>
        </main>



    )

}

export default DashboardPage;