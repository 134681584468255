import React from 'react';
import { rebuildActivities } from '../../services/algoliaActivities';

function ImportRebuildActivities() {
   
    async function rebuildIndex() {
        rebuildActivities();
    }
    
    return (
        <div>
            <h3 style={{ marginBottom: '20px' }}>
                Rebuild Activities Index
            </h3>

            <p>Click the button</p>

            <p><button style={{ marginTop: '5px' }} className="btn btn-success" onClick={() => rebuildIndex()}>
               Rebuild Index →
            </button></p>

        </div>
    );
}

export default ImportRebuildActivities;