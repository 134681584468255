import React from 'react';
import EndUserNavigation from './navigation/EndUserNavigation';

export default function AdminLayout(props) {
    const { title, showNav = true, session, role } = props;
    return (
        <main className="main container-fluid" style={{ minHeight:'100vh', background:'#fff'}} >
            {showNav ? <div style={{ backgroundColor: '#222222' }}><EndUserNavigation title={title} session={session} role={role} /></div> : null}
            <div className="box-content">
                {props.content}
            </div>
        </main>
    );
}
