import React, { useState } from 'react';
import { upsertPFFAthlete } from '../../services/supabase';

function ImportPFF() {
  const [jsonInput, setJsonInput] = useState('');
  const [parsedJson, setParsedJson] = useState(null);

  const handleInputChange = (event) => {
    setJsonInput(event.target.value);
  };

  const parseJson = () => {
    try {
        const players = JSON.parse(jsonInput).players;
        for (const player of players) {
            upsertPFFAthlete(player);
        }
    } catch (error) {
        console.error(`Error reading JSON content:`, error);
    }
  };

  return (
    <div>
        <h3 style={{marginBottom:'20px'}}>
        Import PFF Players
        </h3>
        <ol>
            <li>Login to PFF</li>
            <li>Navigate to https://www.pff.com/college/grades/position/qb</li>
            <li>Change position from the drop down for the position you want to retrieve</li>
            <li>In Developer tools, go to the Network tab</li>
            <li>Look for the grades? url and copy the response for each position</li>
            <li>Paste the players JSON into the textbox below</li>
        </ol>

      <textarea
        placeholder="Paste PFF Players here..."
        value={jsonInput}
        onChange={handleInputChange}
        rows={20}
        cols={100}
      />
       <p><button className="btn btn-success" onClick={() => parseJson()}>Import PFF Players
                        </button></p>
      
      {parsedJson && (
        <div>
          <h2>Parsed JSON:</h2>
          <pre>{JSON.stringify(parsedJson, null, 2)}</pre>
        </div>
      )}
    </div>
  );
}

export default ImportPFF;