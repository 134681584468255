import algoliasearch from "algoliasearch";
import { getActivitiesInBatches, getRecruitActivities, getAllTeams, getHighSchools, getRecruit } from "./supabase";

// Initialize Algolia client
const client = algoliasearch('ETT8OD4DUG', 'bb501e3aa5c51ecc4515f46406dc894c');
const indexName = 'PROD_RECRUIT_ACTIVITIES';
const BATCH_SIZE = 300; // Size of each batch

// Cache for teams
let teamsCache = null;
let hsCache = null;

async function getHighSchoolsOnce() {
 if (!hsCache) {
   hsCache = await getHighSchools();
 }
 return hsCache
}

async function getTeamsOnce() {
    if (!teamsCache) {
        teamsCache = await getAllTeams();
    }
    return teamsCache;
}

function heightToInches(height) {
    const [feet, inches] = height.split("'");
    const feetInInches = parseInt(feet, 10) * 12;
    const totalInches = feetInInches + (inches ? parseInt(inches, 10) : 0);
    return totalInches;
}

export async function addActivityToIndex(data) {
    const index = client.initIndex(indexName);
    const newIndexEntry = await buildIndexEntry(data);
    if (newIndexEntry) {
        await index.saveObject(newIndexEntry);
    }
}

export async function delActivityFromIndex(id) {
    const index = client.initIndex(indexName);
    await index.deleteObject(id);
}

export async function rebuildActivity(id) {
    const index = client.initIndex(indexName);

    const data = await getRecruitActivities(id);
    if (data && data.length > 0) {
        for (const activity of data) {
            const newIndexEntry = await buildIndexEntry(activity);
            if (newIndexEntry && newIndexEntry.rank !== 'Q (Questionnaire)') {
                await index.saveObject(newIndexEntry, { autoGenerateObjectIDIfNotExist: true });
            }
        }
    }

    console.log('FINISHED REBUILD - ACTIVITY - ', data.length);
}

// Main function to rebuild activities
export async function rebuildActivities() {
    const index = client.initIndex(indexName);
    console.log('STARTING REBUILD - ACTIVITIES');
    await clearIndex(indexName);

    let aryData = [];
    let batchStart = 0;
    let dataBatch;

    try {
        const teams = await getTeamsOnce();
        const highSchools = await getHighSchoolsOnce();

        do {
            dataBatch = await getActivitiesInBatches(batchStart, BATCH_SIZE);
            console.log(`Fetched ${dataBatch?.length} activities starting from ${batchStart}`);
            
            if (dataBatch && dataBatch.length > 0) {
                for (const data of dataBatch) {
                    if (data.Recruit) {
                        const activity = await buildIndexEntry(data, teams, highSchools);
                        if(activity) {
                            aryData.push(activity);
                        }
                    }
                }

                await index.saveObjects(aryData, { autoGenerateObjectIDIfNotExist: true });
                aryData = [];
            } else {
                console.log(`ISSUE WITH ${dataBatch ? dataBatch?.length : '0'} activities starting from ${batchStart}`);
            }

            batchStart += BATCH_SIZE;

        } while (dataBatch?.length === BATCH_SIZE);

        // Save any remaining activities
        if (aryData.length > 0) {
            await index.saveObjects(aryData, { autoGenerateObjectIDIfNotExist: true });
        }

        console.log('FINISHED REBUILD');
    } catch (error) {
        console.error('Error fetching activities:', error);
    }
}

function convertToUnixTimestamp(dateString) {
    const date = new Date(dateString);
    return date.getTime();
}

// Function to build activity index entry for an athlete
async function buildIndexEntry(data, teams, highSchools) {
    if (data.Recruit && data.Recruit.rank && data.Recruit.rank !== 'Q (Questionnaire)' && data.Recruit.id != 18759) {

        if (!teams) {
            teams = await getTeamsOnce();
        }

        if (!highSchools) {
            highSchools = await getHighSchoolsOnce();
        }
       
        const team = teams.find(x => x.id === data.teamId);
        const highSchool = highSchools.find(x => x.ETS?.toUpperCase() === data.Recruit.ets?.toUpperCase());

        if (highSchool && data.Recruit.ets && data.Recruit.ets.trim() !== '') {
            data.Recruit.highSchool = highSchool.School;
            data.Recruit.hsCity = highSchool.City;
            data.Recruit.hsState = highSchool.State;
            data.Recruit.hsZip = highSchool.Zip;
            data.Recruit.hsCounty = highSchool.County;
            data.Recruit.hsStreet = highSchool.Address;
            data.Recruit.coachname = highSchool.Coach;
            data.Recruit.coachName = highSchool.Coach;
            data.Recruit.hsPhone = highSchool.Phone;
            data.Recruit.coachEmail = highSchool.Email;
            data.Recruit.coachPhone = highSchool.Cell;
            data.Recruit.coachTwitter = highSchool.Twitter;
        }

        const highSchoolLocation = data.Recruit.highSchool?.endsWith(`(${data.Recruit.hsState})`) ?
        data.Recruit.highSchool :
        `${data.Recruit.highSchool} (${data.Recruit.hsState})`;


        return {
            objectID: data.id,
            id: data.id,
            detail: data.detail,
            link: data.link,
            date: convertToUnixTimestamp(data.created_at),
            logo: data.logo,
            conference: team?.College?.Conference ? team.College.Conference.name : '',
            team: team ? team.name : '',
            division: team?.College?.Conference ? team.College.Conference.conferenceDivisionId.toUpperCase() : '',
            interactionType: data.InteractionType.interactionType,
            year: data.Recruit.year,
            highSchool: highSchoolLocation,
            rank: data.Recruit.rank,
            name: `${data.Recruit.firstName} ${data.Recruit.lastName}`,
            firstName: data.Recruit.firstName,
            lastName: data.Recruit.lastName,
            recruitId: data.Recruit.id,
            npPlayerId: data.Recruit.npPlayerId,
            address: data.Recruit.athleteAddress,
            city: data.Recruit.athleteCity,
            state: data.Recruit.athleteState,
            zip: data.Recruit.athleteZip,
            hsStreet: data.Recruit.hsStreet,
            hsCity: data.Recruit.hsCity,
            hsState: data.Recruit.hsState,
            hsZip: data.Recruit.hsZip,
            hsCounty: data.Recruit.hsCounty,
            height: data.Recruit.height,
            heightInches: data.Recruit.height ? heightToInches(data.Recruit.height) : 0,
            offPosition: data.Recruit.offPosition,
            defPosition: data.Recruit.defPosition,
            specialTeamsPosition: data.Recruit.specialTeamsPosition,
            scoutPos: data.Recruit.scoutPos,
            twitter: data.Recruit.twitter || "Unknown",
            hudl: data.Recruit.hudl,
            displayLocation: `${data.Recruit.hsCity ? data.Recruit.hsCity + ", " : ''}${data.Recruit.hsState || 'Unknown'}`,
            location: data.Recruit.hsState || 'Unknown',
            gpa: data.Recruit.gpa ? parseFloat(data.Recruit.gpa) : 0,
            weight: data.Recruit.weight ? parseFloat(data.Recruit.weight) : 0,
            sat: data.Recruit.sat ? parseInt(data.Recruit.sat) : 0,
            satMath: data.Recruit.satMath ? parseInt(data.Recruit.satMath) : 0,
            satReading: data.Recruit.satReading ? parseInt(data.Recruit.satReading) : 0,
            act: data.Recruit.act ? parseInt(data.Recruit.act) : 0,
            jersey: data.Recruit.jersey,
            standingBroadJump: data.Recruit.standingBroadJump ? parseFloat(data.Recruit.standingBroadJump) : 0,
            forty: data.Recruit.forty ? parseFloat(data.Recruit.forty) : 0,
            verticalJump: data.Recruit.verticalJump ? parseFloat(data.Recruit.verticalJump) : 0,
            cellPhone: data.Recruit.cellPhone,
            homePhone: data.Recruit.homePhone,
            email: data.Recruit.email,
            scoutComments: data.Recruit.scoutComments,
            coachName: data.Recruit.coachName,
            coachPhone: data.Recruit.coachPhone,
            coachEmail: data.Recruit.coachEmail,
            coachTwitter: data.Recruit.coachTwitter,
            Activity: data.Recruit.Activity,
            ets: data.Recruit.ets,
        };
    }
    return null;
}

// Function to clear Algolia index
export async function clearIndex(indexName) {
    try {
        const index = client.initIndex(indexName);
        await index.clearObjects();
        return true;
    } catch (error) {
        console.log("ERROR: ", error);
        return false;
    }
}
