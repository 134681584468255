import { getAllTeamsAndAbbreviations } from "../../services/supabase";
import { getOn3Transfers } from "../../services/supabase";
import { getStates, buildTransfer, addBulkAthletes } from "../../services/supabase"
import { getTransfers } from "../../services/supabase";
import { useEffect, useState } from 'react'

const ImportOn3Transfers = () => {
    const [teams, setTeams] = useState([]);
    const [transfers, setTransfers] = useState([]);
    const [states, setStates] = useState([]);
    const [on3Transfers, setOn3Transfers] = useState([]);
    const [updates, setUpdates] = useState([]);
    const [response, setResponse] = useState();

    useEffect(() => {
        const fetchTransfers = async () => {
            try {
                const response = await getTransfers();
                setTransfers(response);
            } catch (error) {
                console.error('Error getting Transfers:', error.message);
            }
        };

        const fetchOn3Transfers = async () => {
            try {
                const response = await getOn3Transfers();
                setOn3Transfers(response);
            } catch (error) {
                console.error('Error getting On3 Transfers:', error.message);
            }
        };

        const fetchTeams = async () => {
            try {
                const response = await getAllTeamsAndAbbreviations();
                setTeams(response);
            } catch (error) {
                console.error('Error getting Teams:', error.message);
            }
        }

        const fetchStates = async () => {
            try {
                const response = await getStates();
                setStates(response);
            } catch (error) {
                console.error('Error getting States:', error.message);
            }
        }

        fetchTransfers();
        fetchTeams();
        fetchStates();
        fetchOn3Transfers();
    }, []);




    const ImportOn3 = async () => {
        const addAthletes = [];
        try {
            for (const x of on3Transfers) {
                const findTransfer = transfers.find(t => t.on3Id === parseInt(x.key));
    
                if (!findTransfer || findTransfer.length === 0) {
                    const newTransfer = buildTransfer(x, teams);
                    addAthletes.push(newTransfer);
                }
            }
    
            setUpdates(addAthletes); // Set updates outside the loop
    
            const bulkUpdate = async (athletes) => {
                try {
                    //const response = await addBulkAthletes(athletes);
                    //setResponse(JSON.stringify(response));
                } catch (error) {
                    console.error('Error adding Athletes:', error.message);
                }
            };
    
            // Call bulk update function
            await bulkUpdate(addAthletes);
        } catch (error) {
            console.error('Error importing On3 Transfers:', error.message);
        }
    };
    

    const ClearResults = () => {
        setUpdates([]);
    };

    return (
        <div>
            <button
                style={{ marginTop: '5px' }}
                className="btn btn-success"
                onClick={() => ImportOn3()}
            >
                Import On3 Transfers →
            </button>{' '}
            <button
                style={{ marginTop: '5px' }}
                className="btn btn-success"
                onClick={() => ClearResults()}
            >
                Clear Results →
            </button>
            {/* Display the lengths or relevant information */}
            <p>Teams: {teams?.length}</p>
            <p>Transfers: {transfers?.length}</p>
            <p>States: {states?.length}</p>
            <p>On3 Transfers: {on3Transfers?.length}</p>

            <h7>Updates</h7>
            <p>{updates.length}</p>
            { response }
                
        </div>
    );
}

export default ImportOn3Transfers;