import { getHighSchools } from "../../services/supabase";
import React, { useState, useEffect } from "react";
import AdminDataGrid from "../shared/AdminDataGrid";
import { getRole } from "../../services/utilities";
import { escapeValue } from "../../services/utilities";

const HighSchools = (props) => {
  // state for search Text
  const [highSchools, setHighSchools] = useState(null);
  const [favoriteCount, setFavoriteCount] = useState(0);
  const [role, setRole] = React.useState(null);

  async function getUserRole() {
    const role = await getRole();
    return role;
  }

  const handleExport = async () => {
    const csvRows = [];
    const headers = [
      "ETS",
      "School",
      "Address",
      "City",
      "State",
      "Zip",
      "County",
      "Coach",
      "Phone",
      "Cell",
      "Email",
      "Twitter",
    ];
    csvRows.push(headers.join(","));

    // Convert to CSV and trigger download

    highSchools.forEach((school) => {
      const values = [
        escapeValue(school.ETS),
        escapeValue(school.School),
        escapeValue(school.Address),
        escapeValue(school.City),
        escapeValue(school.State),
        escapeValue(school.Zip),
        escapeValue(school.County),
        escapeValue(school.Coach),
        escapeValue(school.Phone),
        escapeValue(school.Cell),
        escapeValue(school.Email),
        escapeValue(school.Twitter),
      ];
      csvRows.push(values.join(","));
    });
    const csvData = csvRows.join("\n");
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "highschools.csv";
    a.click();
  };

  async function fetchHighSchools() {
    try {
      const res = await getHighSchools();
      setHighSchools(res);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      await fetchHighSchools();
    };
    fetchData();
  }, []);

  useEffect(() => {
    getUserRole().then((role) => {
      setRole(role);
    });
  }, []);

  const columns = [
    { field: "id", headerName: "Id", flex: 1, sortable: true },
    { field: "School", headerName: "Name", flex: 4, sortable: true },
    { field: "ETS", headerName: "ETS", flex: 2, sortable: true },
    {
      field: "Address",
      headerName: "Address",
      flex: 4,
      sortable: true,
      renderCell: (params) => (
        <div>
          {params.row.Address}
          <br />
          {params.row.City}, {params.row.State} {params.row.Zip}
        </div>
      ),
    },
    { field: "State", headerName: "State", flex: 1, sortable: true },
    { field: "County", headerName: "County", flex: 2, sortable: true },
    {
      field: "Coach",
      headerName: "Coach",
      flex: 4,
      sortable: true,
      renderCell: (params) => (
        <div>
          {params.row.Coach ? params.row.Coach : "No Coach Assigned"}
          <br />
          {params.row.Phone ? params.row.Phone : "No Phone Provided"}
          <br />
          {params.row.Cell ? params.row.Cell : "No Cell Provided"}
          <br />
          {params.row.Email ? (
            <a href={`mailto:${params.row.Email}`}>{params.row.Email}</a>
          ) : (
            "No Email Provided"
          )}
          <a href={`https://twitter.com/${params.row.Twitter}`}>
            {params.row.Twitter}
          </a>
        </div>
      ),
    },
  ];

  return (
    <div className="w-100">
      <div className="row">
        <div
          className="col-md-12 col-xs-12"
          style={{ backgroundColor: "#F3F3F3" }}
        >
          <div className="box-content">
            <main className="container">
              <div className="row w-100">
                <div className="col-md-12">
                  <div className="d-flex justify-content-between">
                    <h2>High Schools</h2>
                    <button
                      className="btn btn-success"
                      style={{
                        float: "right",
                        width: "150px",
                        marginBottom: "20px",
                      }}
                      onClick={handleExport}
                    >
                      Export High Schools
                    </button>
                  </div>

                  {highSchools ? (
                    <>
                      <AdminDataGrid
                        data={highSchools}
                        columns={columns}
                        height={130}
                      />
                    </>
                  ) : null}
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HighSchools;
