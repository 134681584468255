import React, { useState } from 'react';
import { TextField, FormControl, Button } from '@mui/material';
import { verifyEmail } from '../../services/supabase';
import { toast } from 'react-toastify';

const VerifyEmail = () => {
    const [uuid, setUuid] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        const verified = await verifyEmail(uuid);
        if (verified) {
            toast.success('Success!');
        } else {
            toast.error('Error verifying email');
        }

    };


    return (
        <div>
            <form onSubmit={handleSubmit} className="team-form">``
                <h2>Verify Email</h2>
                <FormControl fullWidth margin="normal">
                    <TextField
                        label="uuid"
                        type="text"
                        name="uuid"
                        value={uuid}
                        onChange={(e) => setUuid(e.target.value)}
                        required
                    />
                </FormControl>

                <div className="form-footer">
                    <Button variant="contained" color="primary" type="submit">Submit</Button>
                </div>
            </form>
        </div>
    );
};

export default VerifyEmail;
