import { getRecruits } from "../../services/supabase";
import { useState, useEffect } from "react";
import AdminDataGrid from "../shared/AdminDataGrid";
import fallbackImage from '../../assets/imgs/unknown.png';
import EditUser from "./EditUser";
import AddUser from "./AddUser";
import { Button } from "react-bootstrap";

const Athletes = (props) => {
    const [data, setData] = useState(null);


    async function fetchData() {
        try {
            const res = await getRecruits();
            setData(res);
        } catch (error) {
            console.error("Error fetching user list:", error);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);


    const columns = [
        { field: 'firstName', headerName: 'FIRST NAME', sortable: true, filterable: true, flex: 2 },
        { field: 'lastName', headerName: 'LAST NAME', sortable: true, filterable: true, flex: 2 },
        { field: 'year', headerName: 'YEAR', flex: 1, sortable: true, filterable: true },
        { field: 'rank', headerName: 'NP RATING', sortable: true, filterable: true, flex: 2 },
        { field: 'twitter', headerName: 'Twitter', sortable: true, filterable: true, flex: 2 },
        { field: 'highSchool', headerName: 'HS', sortable: true, filterable: true, flex: 3 },
        { field: 'location', headerName: 'ST', sortable: true, filterable: true, flex: 1 },
        { field: 'hsCounty', headerName: 'COUNTY', sortable: true, filterable: true, flex: 2 },
        { field: 'height', headerName: 'HT', sortable: true, filterable: true, flex: 1 },
        { field: 'weight', headerName: 'WT', sortable: true, filterable: true, flex: 1 },
        { field: 'scoutPos', headerName: 'SCOUT', sortable: true, filterable: true, flex: 2 },
        {
            field: 'id',
            headerName: 'Edit',
            sortable: true,
            filterable: true,
            flex: 1,
            renderCell: (params) => (
                <a href={`/admin/athlete/${params.value}`} target="_blank" rel="noopener noreferrer">
                    Edit
                </a>
            ),
        }
    ];


    return (
        <div className="row w-100">
            <div className="col-md-12">
                <p><a className="btn btn-success" href="/admin/athlete/add" >
                    Add Athlete
                </a></p>

                {data ? (
                    <AdminDataGrid data={data} columns={columns} />
                ) : (
                    <p>Loading...</p>
                )}
            </div>

        </div>
    );
}

export default Athletes;
