import React, { useState, useEffect } from 'react';
import { getAllPFFAthletes, getAllTeams, upsertPFFAthlete } from '../../services/supabase';
import axios from 'axios';
import cheerio from 'cheerio'; // Import Cheerio

import {
    DataGridPro,
    GridRowModes,
    GridToolbarContainer,
    GridActionsCellItem,
    GridRowEditStopReasons,
} from '@mui/x-data-grid-pro';

function ImportESPN() {
    const [loading, setLoading] = useState(true);
    const [teams, setTeams] = useState([]);
    const [pffAthletes, setPFFAthletes] = useState([]);
    const [rows, setRows] = useState([]);
    const [rowModesModel, setRowModesModel] = useState({});

    useEffect(() => {
        async function fetchData() {
            try {
                const fetchedTeams = await getAllTeams();
                const athletes = await getAllPFFAthletes();
                setTeams(fetchedTeams);
                setPFFAthletes(athletes);
                setRows(fetchedTeams); // Set rows initially to fetched teams
                setLoading(false);
            } catch (error) {
                console.error('Error fetching teams:', error);
            }
        }

        fetchData();
    }, []);

    async function importESPNRosters() {
        for (const team of rows) {
            if (team.espnId && team.espnSlug) {
                const url = `https://www.espn.com/college-football/team/roster/_/id/${team.espnId}`;
                
                try {
                    const response = await axios.get(url);
                    if (response.status === 200) {
                        const html = response.data;
                        const $ = cheerio.load(html);
                        const players = [];

                        $('tbody.Table__TBODY > tr').each((index, element) => {
                            const player = {};
                            player.image = $(element).find('.Table__TD--headshot > div > a > div > img').attr('src');
                            player.name = $(element).find('.Table__TD > div > a').text().trim();
                            player.number = $(element).find('.Table__TD > div > span.n10').text().trim();
                            player.position = $(element).find('.Table__TD').eq(2).text().trim();
                            player.height = $(element).find('.Table__TD').eq(3).text().trim();
                            player.weight = $(element).find('.Table__TD').eq(4).text().trim();
                            player.class = $(element).find('.Table__TD').eq(5).text().trim();
                            const birthplace = $(element).find('.Table__TD').eq(6).text().replace(/\n/g, '').trim();
                            const birth = birthplace.split(',');
                            try {
                                player.birthCity = birth[0];
                                player.birthState = birth[1];
                            } catch {
                                player.birthCity = '';
                                player.birthState = '';
                            }

                            player.birthplace = birthplace;
                            player.franchiseId = team.pffFranchiseId;
                            player.espnTeamId = team.espnId;
                            player.espnTeamSlug = team.espnSlug; 
                            const detailLink = $(element).find('.Table__TD > div > a').attr('href');
                            player.detailLink = detailLink ? detailLink : 'N/A';
                            if (detailLink) {
                                const urlParts = detailLink.split('/');
                                player.espnSlug = urlParts[urlParts.length - 1];
                                player.espnId = urlParts[urlParts.length - 2];
                            } else {
                                player.espnSlug = 'N/A';
                                player.espnId = 'N/A';
                            }

                            // Find the player and update PFF_Athlete
                            const athlete = pffAthletes.find(x => x.franchise_id.toString().trim() === player.franchiseId.toString().trim() && x.slug.toString().toLowerCase().trim() === player.espnSlug.toString().toLowerCase().trim());
                            if (athlete) {
                                athlete.birthCity = player.birthCity;
                                athlete.birthState = player.birthState;
                                athlete.espnTeamSlug = player.espnTeamSlug;
                                athlete.espnTeamId = player.espnTeamId;
                                athlete.espnInfo = player;
                                athlete.espnId = player.espnId;
                                upsertPFFAthlete(athlete)
                            } else {
                                console.log('Player not found', player.franchiseId.toString() + ': ' + player.espnSlug)
                            }
                            players.push(player);
                        });

                        //fs.writeFileSync(`./data/espn/rosters/players_${team.espnSlug}.json`, JSON.stringify(players, null, 2));
                    }
                } catch (error) {
                    console.error(`Error fetching roster for ${team.name}:`, error.message);
                }
                
                // Add a delay to prevent overwhelming the server
                //await delay(1000); // Adjust the delay time as needed
            }
        }
    }

    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id) => () => {
        setRows(rows.filter((row) => row.id !== id));
    };

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }
    };

    const processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow, isNew: false };
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
    };

    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const columns = [
        { field: 'name', headerName: 'Team Name', flex: 3 },
        { field: 'espnSlug', headerName: 'ESPN Slug', flex: 3 },
        { field: 'espnId', headerName: 'ESPN Id', flex: 1 },
        { field: 'pffFranchiseId', headerName: 'PFF Id', flex: 1 },
        { field: 'ncaaOrgId', headerName: 'NCAA Id', flex: 1 },
    ];

    return (
        <div>
            <h3 style={{ marginBottom: '20px' }}>
                Import ESPN Rosters and Update PFF Player Profiles
            </h3>

            <p>Make sure all the teams you want to import players for have an ESPNId and ESPN slug then click Import</p>

            <p><button style={{ marginTop: '5px' }} className="btn btn-success" onClick={() => importESPNRosters()}>
               Import Rosters →
            </button></p>
            {loading ? (
                <p>Loading teams...</p>
            ) : (
                <DataGridPro
                    rows={rows}
                    columns={columns}
                    editMode="row"
                />


            )}
        </div>
    );
}

export default ImportESPN;
