import React, { useState, useEffect } from "react";
import { getTeams } from "../../services/supabase";
import AdminDataGrid from "../shared/AdminDataGrid";
import EditTeam from "./EditTeam";
import { Button } from "react-bootstrap";

const Teams = (props) => {
    const [data, setData] = useState(null);
    const [team, setTeam] = useState(null);

    async function fetchData() {
        try {
            const res = await getTeams();
            setData(res);
        } catch (error) {
            console.error("Error:", error);
        }
    }

    const handleRowClick = (params) => {
        setTeam(params.row);
    };

    const handleFormSubmit = (updatedTeam) => {
        setData((prevData) => {
            const updatedData = prevData.map((t) => (t.id === updatedTeam.id ? updatedTeam : t));
            if (!prevData.some((t) => t.id === updatedTeam.id)) {
                updatedData.push(updatedTeam);
            }
            return updatedData;
        });
        setTeam(null);
    };

    useEffect(() => {
        const fetchIt = async () => {
            await fetchData();
        };
        fetchIt();
    }, []);

    const columns = [
        {
            field: "id",
            headerName: "id",
            width: 50,
            sortable: true
        },
        {
            field: "logo",
            headerName: "Logo",
            width: 150,
            renderCell: (params) => <img src={params.row.logo} width="50px" alt="Team Logo" />, // renderCell will render the component
            sortable: true
        },
        {
            field: "name",
            headerName: "Team",
            width: 200,
            sortable: true
        },
        {
            field: "years",
            headerName: "Years",
            width: 200,
            renderCell: (params) => {JSON.stringify(params.row.years)}, // renderCell will render the component
            sortable: false
        },
        {
            field: "states",
            headerName: "States",
            width: 300,
            renderCell: (params) => {JSON.stringify(params.row.states)}, // renderCell will render the component
            sortable: true
        },
    ];

    return (
        <div className="row w-100">
            <div className="col-md-6">
                {data ? (
                    <AdminDataGrid data={data} columns={columns} onRowClick={handleRowClick} />
                ) : null}
            </div>
            <div className="col-md-6">
                {team ? (
                    <EditTeam team={team} onFormSubmit={handleFormSubmit} />
                ) : null}
            </div>
        </div>
    );
};

export default Teams;
