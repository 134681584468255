//const token = "AAAAAAAAAAAAAAAAAAAAAFHapAEAAAAAsZ80uSyZRqGIX3989m3G6vN9gtg%3DnR05GL2tQPNyK7DxDTHXiMbz1XGqecTczb4ksbWiB5ZYCaAp6T";

import { getSocialQueries, upsertSocialPost } from '../../services/supabase';
import { useState } from 'react';
import axios from 'axios';

function ScrapeTweets() {
  const [logData, setLogData] = useState([]);

  const searchEndpointUrl = "/api/2/tweets/search/recent";
  const token = "AAAAAAAAAAAAAAAAAAAAAFHapAEAAAAAsZ80uSyZRqGIX3989m3G6vN9gtg%3DnR05GL2tQPNyK7DxDTHXiMbz1XGqecTczb4ksbWiB5ZYCaAp6T";
  const getTweetEndpointUrl = "/api/2/tweets?ids=";

  function formatDateToISOString(date) {
    return date.toISOString().slice(0, 23) + 'Z';
  }

  async function scrapeTweets() {
    setLogData([]);
    try {
      const queries = await getSocialQueries();

      for (const q of queries) {
        if (q.query.includes("is:retweet")) {
          setLogData((prev) => [...prev, `==========================`]);
          setLogData((prev) => [...prev, `${q.query}`]);
          setLogData((prev) => [...prev, `==========================`]);
          await getRetweets(q); // Ensure the function is awaited
        }
      }
    } catch (error) {
      console.error('Error fetching tweets from Supabase:', error);
    }
  }

  async function getRetweets(q) {
    const now = new Date();
    const twentySecondsAgo = new Date(now.getTime() - 20 * 1000);
    const nowMinus = new Date(now.getTime() - 0.5 * 24 * 60 * 60 * 1000); // 12 hours ago

    const lastId = null; // Assume this will be fetched from some state or API

    const startDate = formatDateToISOString(nowMinus);
    const endDate = formatDateToISOString(twentySecondsAgo);

    const params = {
      query: q.query,
      expansions: "author_id",
      "tweet.fields": "referenced_tweets",
      max_results: 100,
    };

    if (lastId) {
      params.since_id = lastId;
    } else {
      params.start_time = startDate;
      params.end_time = endDate;
    }

    setLogData((prev) => [...prev, `Search endpoint URL: ${searchEndpointUrl}`]);
    setLogData((prev) => [...prev, `params: ${JSON.stringify(params)}`]);

    try {
      const res = await axios.get(searchEndpointUrl, {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Extract rate limit information from headers
      const rateLimitRemaining = res.headers['x-rate-limit-remaining'];
      const rateLimitReset = res.headers['x-rate-limit-reset'];

      setLogData((prev) => [...prev, `${res.data.meta.result_count} results found`]);
      setLogData((prev) => [...prev, `Rate limit remaining: ${rateLimitRemaining}`]);
      setLogData((prev) => [...prev, `Rate limit resets at: ${new Date(rateLimitReset * 1000).toLocaleString()}`]);

      if (rateLimitRemaining === "0") {
        const waitTime = rateLimitReset * 1000 - Date.now();
        setLogData((prev) => [...prev, `Pausing for ${waitTime / 1000} seconds due to rate limiting...`]);
        await new Promise(resolve => setTimeout(resolve, waitTime));
      }

      if (res.data) {
        const tweetIds = [];
        const data = res.data;

        if (data && data.data) {
          let maxId = res.data.meta.newest_id;
          for (const d of data.data) {
            if (d.referenced_tweets) {
              const retweets = d.referenced_tweets?.filter(
                (p) => p.type === "retweeted" || p.type === "quoted"
              );
              for (const r of retweets) {
                tweetIds.push(r.id);
              }
            } else {
              tweetIds.push(d.id);
            }
          }
          q.lastId = maxId;
        }

        setLogData((prev) => [...prev, `Tweets: ${tweetIds}`]);
        // Parse the original tweets
        const tweets = await getOriginalTweets(tweetIds);
        await denormalizeTwitterData(tweets, q);

      } else {
        setLogData((prev) => [...prev, `Unsuccessful Twitter Request`]);
        throw new Error("Unsuccessful Twitter Request");
      }
    } catch (error) {
      setLogData((prev) => [...prev, `Error fetching retweets: ${error}`]);
      console.error('Error fetching retweets:', error);
    }
  }

  async function getOriginalTweets(ids) {
    const batchSize = 10;
    const result = [];

    for (let i = 0; i < ids.length; i += batchSize) {
      const batchIds = ids.slice(i, i + batchSize);
      const tweetIdsString = batchIds.join(",");

      const params = {
        ids: tweetIdsString,
        expansions: "author_id,attachments.media_keys",
        "tweet.fields": "author_id,created_at",
        "media.fields": "url",
        "user.fields":
          "id,created_at,description,location,name,profile_image_url,username",
      };

      try {
        const res = await axios.get(getTweetEndpointUrl, {
          params,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (res.data) {
          result.push(res.data);
        } else {
          setLogData((prev) => [...prev, `<span class='alert-danger'>Unsuccessful Twitter request ${JSON.stringify(res)}</span>`]);
          console.log("Error - Unsuccessful Twitter request");
        }
      } catch (error) {
        setLogData((prev) => [...prev, `<span class='alert-danger'>ERROR Twitter request: ${error}</span>`]);
        console.error('Error fetching original tweets:', error);
      }
    }

    return result;
  }

  async function denormalizeTwitterData(tweets, q) {
    for (let i = 0; i < tweets.length; i++) {
      let documents = [];
      const jsonData = tweets[i].data;

      if (tweets[i] && tweets[i].includes) {
        const { media, users } = tweets[i].includes;
        const tweetList = jsonData;

        // Create a dictionary of media objects keyed by their media_key
        const mediaDict = {};
        if (media) {
          media.forEach((m) => (mediaDict[m.media_key] = m));
        }

        // Create a dictionary of user objects keyed by their id
        const userDict = {};
        if (users) {
          users.forEach((u) => (userDict[u.id] = u));
        }

        for (const d of tweetList) {
          // Add the author field to the original dataset
          d.author = userDict[d.author_id];

          // Add the attachments to the original dataset
          if (d.attachments && d.attachments.media_keys) {
            d.attachments.media = d.attachments.media_keys.map(
              (key) => mediaDict[key]
            );
          }
        }

        for (const d of tweetList) {
          const documentData = {
            _id: d.id,
            fullText: d.text,
            date: d.created_at,
            createdAt: d.created_at,
            name: d.author?.name,
            handle: d.author?.username,
            profileImage: d.author?.profile_image_url,
            userId: d.author?.id,
            description: d.author?.description,
            location: d.author?.location,
            postId: d.id,
            processed: false,
            query: q.query,
            attachments: JSON.stringify(d.attachments),
            socialQueryId: q.id,
          };
          documents.push(documentData);
        }
      }

      await processTweets(documents, q);
    }
    return true;
  }

  async function processTweets(documents, q) {
    if (documents.length > 0) {
      for (const doc of documents) {
        const post = {
          id: doc.postId,
          link: "https://x.com/x/status/" + doc.postId,
          content: doc.fullText,
          postId: doc.postId,
          authorId: doc.userId,
          profileImage: doc.profileImage,
          description: doc.description ? doc.description : "",
          name: doc.name ? doc.name : "",
          handle: doc.handle,
          location: doc.location ? doc.location : "",
          processed: false,
          recruitId: null,
          tweetCreated: doc.createdAt,
          attachments: doc.attachments,
          socialQueryId: q.id,
          source: q.query,
        };

        try {
          await upsertSocialPost(post);
        } catch (error) {
          console.error('Error upserting social post:', error);
        }
      }
    }
  }

  return (
    <div>
      <h3 style={{ marginBottom: '20px' }}>
        Scrape Tweets
      </h3>

      <p><button style={{ marginTop: '5px' }} className="btn btn-success" onClick={() => scrapeTweets()}>
        Scrape Tweets →
      </button></p>

      <div>
        {logData.map((log, index) => (
          <div key={index} dangerouslySetInnerHTML={{ __html: log }} />
        ))}
      </div>
    </div>
  );
}

export default ScrapeTweets;
