import React, { useEffect, useState } from 'react';
import { TextField, FormControl, FormControlLabel, RadioGroup, Radio, FormLabel, Button, MenuItem, InputLabel } from '@mui/material';
import { getTeams, updateUser } from '../../services/supabase';
import Select from 'react-select';
import { uniqueId } from 'react-bootstrap-typeahead/types/utils';
import { toast } from 'react-toastify';

const EditUser = ({ user }) => {
  const [selectTeams, setSelectTeams] = useState([]);
  const [team, setTeam] = useState({});
  const [formData, setFormData] = useState(user);
 

  useEffect(() => {
    setFormData(user);
    if (user?.team) {
      setTeam({
        value: user.team,
        label: user.Team.name
      });
    }
    fetchTeams();
  }, [user]);

  async function fetchTeams() {
    try {
      const teams = await getTeams();
      const sTeams = teams.map((x) => ({
        value: x.id,
        label: x.name
      }));
      setSelectTeams(sTeams);
    } catch (error) {
      console.error('Error fetching teams:', error);
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    setFormData({
      ...formData,
      [name]: value
    });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const userData = {
      email: formData.email,
      firstname: formData.firstname,
      lastname: formData.lastname,
      id: formData.id,
      role: parseInt(formData.role),
      team: parseInt(formData.team)
    }

    const updatedUser = await updateUser(userData);
    if (updatedUser) {
      toast.success("User Updated", {
        position: "top-right",
        autoClose: 1000,
      });
    }
    else{
      toast.error("Error updating user", {
        position: "top-right",
        autoClose: 1000,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit} className="user-form">
      <FormControl fullWidth margin="normal">

        <TextField
          label="Email"
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </FormControl>

      <FormControl fullWidth margin="normal">
        <TextField
          label="First Name"
          type="text"
          name="firstname"
          value={formData.firstname}
          onChange={handleChange}
          required
        />
      </FormControl>
      <FormControl fullWidth margin="normal">
        <TextField
          label="Last Name"
          type="text"
          name="lastname"
          value={formData.lastname}
          onChange={handleChange}
          required
        />
      </FormControl>
     
      <FormControl component="fieldset" margin="normal">
        <FormLabel component="legend">Role</FormLabel>
        <RadioGroup
          name="role"
          value={formData.role?.toString() || "3"}
          required
          onChange={handleChange}
        >
          <FormControlLabel value="1" control={<Radio />} label="Admin" />
          <FormControlLabel value="2" control={<Radio />} label="Super User" />
          <FormControlLabel value="3" control={<Radio />} label="User" />
        </RadioGroup>
      </FormControl>
      <FormControl fullWidth margin="normal">
        <InputLabel>Team</InputLabel>
        {selectTeams.length > 0 ? (
          <Select
            id="team"
            isSearchable={true}
            isClearable={true}
            name="team"
            options={selectTeams}
            value={team}
            onChange={(selectedOption) => setTeam(selectedOption)}
          />
        ) : (
          <p>Loading teams...</p>
        )}
      </FormControl>
      <Button variant="contained" color="primary" type="submit">
        Submit
      </Button>
    </form>
  );
};

export default EditUser;
