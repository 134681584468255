import { supabaseAdmin, supabaseClient } from "./supabaseclient.js";
import { rebuildRecruit, deleteRecruitFromIndex } from "./algolia.js";
import { toast } from "react-toastify";
import { convertToUnixTimestamp } from "./utilities.js";

export const getRecruits = async () => {
  const { data, error } = await supabaseClient.from("Recruit").select();

  if (!error) {
    return data;
  } else {
    console.log("Error retrieving data: ", JSON.stringify(error));
  }
};

export const verifyEmail = async (uuid) => {
  const { data, error } = await supabaseAdmin.auth.admin.updateUserById(uuid, {
    email_confirm: true,
  });
  if (!error) {
    return data;
  } else {
    console.log("Error verifying email: ", JSON.stringify(error));
    return null;
  }
};

export const getRecruitsByState = async (state) => {
  const { data, error } = await supabaseClient
    .from("Recruit")
    .select()
    .eq("athleteStateId", state);

  if (!error) {
    return data;
  } else {
    console.log("Error retrieving data: ", JSON.stringify(error));
  }
};

export const getMaxId = async (id) => {
  const { data, error } = await supabaseClient
    .from("SocialPost")
    .select("postId")
    .eq("socialQueryId", id)
    .order("postId", { ascending: false })
    .limit(1);
  if (!error) {
    return data;
  } else {
    console.log("Error retrieving data: ", JSON.stringify(error));
  }
};

export const getActivitiesInBatches = async (batchStart, batchSize) => {
  const { data, error } = await supabaseClient
    .from("Activity")
    .select("*,Recruit(*, Activity(*)),InteractionType(*))")
    .range(batchStart, batchStart + batchSize - 1)
    .order("date", { ascending: false });

  if (!error) {
    return data;
  } else {
    console.log("Error retrieving data: ", JSON.stringify(error));
  }
};
export const uploadFile = async (fileName, file, id, title) => {
  const { data: uploadData, error: uploadError } = await supabaseClient.storage
    .from("recruitFiles")
    .upload(`${fileName}`, file);

  if (uploadError) {
    console.log("Error uploading file: ", JSON.stringify(uploadError));
    return null;
  } else {
    const recruitFile = {
      recruitId: id,
      file: fileName,
      title: title,
    };
    const { data: insertData, error: insertError } = await supabaseClient
      .from("Recruit_Files")
      .insert(recruitFile)
      .select();

    if (insertError) {
      console.error(
        `Failed to update Recruit_Files with ${recruitFile.file}:`,
        insertError.message
      );
      return null;
    } else {
      console.log("Final data:", insertData);
      return insertData[0]; // Return the inserted record
    }
  }
};

export const getRecruitsInBatches = async (batchStart, batchSize) => {
  const { data, error } = await supabaseClient
    .from("Recruit")
    .select(
      "*,Activity(*,InteractionType(*),Team(*, College(*, Conference(*))))"
    )
    .range(batchStart, batchStart + batchSize - 1);

  if (!error) {
    return data;
  } else {
    console.log("Error retrieving data: ", JSON.stringify(error));
  }
};

export const getRecruitActivities = async (id) => {
  const { data, error } = await supabaseClient
    .from("Activity")
    .select("*,Recruit(*),InteractionType(*)")
    .eq("recruitId", id);

  if (!error) {
    return data;
  } else {
    console.log("Error retrieving data: ", JSON.stringify(error));
  }
};

export const getRecruitActivitiesWithTeam = async (id) => {
  const { data, error } = await supabaseClient
    .from("Activity")
    .select("*,InteractionType(*),Team(*)")
    .eq("recruitId", id);

  if (!error) {
    return data;
  } else {
    console.log("Error retrieving data: ", JSON.stringify(error));
  }
};

export const getRecruitFiles = async (id) => {
  console.log("id:", id);
  const { data, error } = await supabaseClient
    .from("Recruit_Files")
    .select()
    .eq("recruitId", id);

  if (!error) {
    console.log("data:", data);
    return data;
  } else {
    console.log("Error retrieving data: ", JSON.stringify(error));
  }
};

export const getRecruit = async (id) => {
  try {
    const { data, error } = await supabaseClient
      .from("Recruit")
      .select("*, Activity(*, InteractionType(*))")
      .eq("id", id);

    if (error) {
      console.error("Error retrieving data:", JSON.stringify(error));
      return null;
    }

    if (data && data.length > 0) {
      const recruit = data[0];

      // Fetch high school details
      const highSchool = await getHighSchool(recruit.ets);

      if (highSchool && highSchool.length > 0) {
        const hs = highSchool[0];
        recruit.highSchool = hs.School;
        recruit.hsCity = hs.City;
        recruit.hsState = hs.State;
        recruit.hsZip = hs.Zip;
        recruit.hsCounty = hs.County;
        recruit.hsStreet = hs.Address;
        recruit.hsPhone = hs.Phone;
        recruit.coachname = hs.Coach;
        recruit.coachName = hs.Coach;
        recruit.coachEmail = hs.Email;
        recruit.coachPhone = hs.Cell;
        recruit.coachTwitter = hs.Twitter;
      }

      return recruit;
    } else {
      return null;
    }
  } catch (error) {
    console.error("Unexpected error:", error);
    return null;
  }
};

export const getUserRoles = async (id) => {
  const { data, error } = await supabaseClient
    .from("user_profile")
    .select("*,Team(*),User_Role(*)")
    .eq("uuid", id);

  if (!error) {
    return data;
  } else {
    console.log("Error retrieving data: ", JSON.stringify(error));
  }
};

export const upsertActivity = async (activity) => {
  const { data, error } = await supabaseClient
    .from("Activity")
    .upsert(activity, { onConflict: "id" })
    .select();

  if (!error) {
    return data;
  } else {
    console.log("ERROR: ", JSON.stringify(error));
    return null;
  }
};

export const getAcvityByLink = async (link) => {
  const { data, error } = await supabaseClient
    .from("Activity")
    .select()
    .eq("link", link);

  if (!error) {
    return data;
  } else {
    console.log("Error retrieving data: ", JSON.stringify(error));
    return null;
  }
};

export const upsertActivityBySocialPost = async (activity) => {
  if (activity.link && activity.link != "") {
    const existingActivity = await getAcvityByLink(activity.link);
    if (existingActivity.length === 0) {
      const { data, error } = await supabaseClient
        .from("Activity")
        .insert(activity)
        .select();

      if (!error) {
        return data;
      } else {
        console.log("ERROR: ", JSON.stringify(error));
        return null;
      }
    }
    return null;
  }
};

export const upsertTransfer = async (transfer) => {
  const { data, error } = await supabaseClient
    .from("Transfer")
    .upsert(transfer, { onConflict: "id" })
    .eq("id", transfer.id);

  if (!error) {
    return data;
  } else {
    console.log("Error retrieving data: ", JSON.stringify(error));
  }
};

export const upsertTeam = async (team) => {
  const { data, error } = await supabaseClient
    .from("Team")
    .upsert(team, { onConflict: "id" })
    .eq("id", team.id);

  if (!error) {
    return data;
  } else {
    console.log("Error retrieving data: ", JSON.stringify(error));
  }
};

export const getNCAATransfers = async () => {
  const { data, error } = await supabaseClient.from("NCAA_Transfers").select();

  if (!error) {
    return data;
  } else {
    console.log("Error retrieving data: ", JSON.stringify(error));
  }
};

export const insertUserFavorite = async (favObj) => {
  console.log("favO:", favObj);

  const formData = {
    transfers: favObj.transfers ? favObj.transfers : null,
    athlete: favObj.athlete ? favObj.athlete : null,
    type: favObj.type,
    user_profile: localStorage.getItem("user_profile_id"),
  };
  console.log("fd:", formData);

  const { data, error } = await supabaseClient
    .from("user_favorite")
    .insert([formData]);

  if (!error) {
    return data;
  } else {
    console.error("Error inserting data: ", JSON.stringify(error));
    return null;
  }
};

export const fetchFavoriteByUser = async (type) => {
  const userProfileId = localStorage.getItem("user_profile_id");

  const { data, error } = await supabaseClient
    .from("user_favorite")
    .select("id, transfers(*),athlete(*), type ")
    .eq("user_profile", userProfileId)
    .eq("type", type);

  if (!error) {
    return data;
  } else {
    console.log("Error retrieving data: ", JSON.stringify(error));
  }
};

export const fetchFavoriteCountByUser = async () => {
  const userProfileId = localStorage.getItem("user_profile_id");

  const { count, error } = await supabaseClient
    .from("user_favorite")
    .select("id", { count: "exact" })
    .eq("user_profile", userProfileId);

  if (!error) {
    return count;
  } else {
    console.error("Error retrieving count: ", JSON.stringify(error));
  }
};

export const getRivalsTransfers = async () => {
  const { data, error } = await supabaseClient
    .from("Rivals_Transfers")
    .select();

  if (!error) {
    return data;
  } else {
    console.log("Error retrieving data: ", JSON.stringify(error));
  }
};

export const getTransfers = async () => {
  const { data, error } = await supabaseClient.from("NCAATransfers").select();

  if (!error) {
    return data;
  } else {
    console.log("Error retrieving data: ", JSON.stringify(error));
  }
};

export const getHighSchool = async (ets) => {
  if (!ets || ets === "") {
    return null;
  }

  const { data, error } = await supabaseClient
    .from("HighSchools")
    .select()
    .eq("ETS", ets);

  if (!error) {
    return data;
  } else {
    console.log("Error retrieving data: ", JSON.stringify(error));
  }
};

export const getHighSchools = async () => {
  const { data, error } = await supabaseClient.from("HighSchools").select();

  if (!error) {
    return data;
  } else {
    console.log("Error retrieving data: ", JSON.stringify(error));
  }
};

export const getColleges = async () => {
  const { data, error } = await supabaseClient
    .from("College")
    .select("id, name, city, state")
    .order("name", { ascending: true });

  if (!error) {
    return data;
  } else {
    console.log("Error retrieving data: ", JSON.stringify(error));
  }
};

export const getInteractionTypes = async () => {
  const { data, error } = await supabaseClient.from("InteractionType").select();

  if (!error) {
    return data;
  } else {
    console.log("Error retrieving data: ", JSON.stringify(error));
    return null;
  }
};

export const upsertPFFAthlete = async (athlete) => {
  const { data, error } = await supabaseClient
    .from("PFF_Athlete")
    .upsert(athlete);

  if (!error) {
    return data;
  } else {
    console.log(JSON.stringify(error));
    return null;
  }
};

export const upsertNCAATransfer = async (athlete) => {
  const { data, error } = await supabaseClient
    .from("NCAA_Transfers")
    .upsert(athlete);

  if (!error) {
    return data;
  } else {
    console.log(JSON.stringify(error));
    return null;
  }
};

export const getAllPFFAthletes = async () => {
  let allData = [];
  let startRange = 0;
  let endRange = 999;

  while (true) {
    const { data, error } = await supabaseClient
      .from("PFF_Athlete")
      .select()
      .range(startRange, endRange);

    if (!error) {
      allData = allData.concat(data);
      if (data.length < 1000) {
        break;
      }
      startRange += 1000;
      endRange += 1000;
    } else {
      console.log(JSON.stringify(error));
      return null;
    }
  }
  console.log(allData[0]);
  return allData;
};

export const checkRecruitHandle = async (handle) => {
  console.log("handle:", handle);
  const { data, error } = await supabaseClient
    .from("Recruit")
    .select()
    .eq("twitter", handle);

  if (!error && data.length > 0) {
    return data[0];
  } else {
    return null;
  }
};

export const getRecruitByTwitterHandle = async (handle) => {
  const { data, error } = await supabaseClient
    .from("Recruit")
    .select()
    .ilike("twitter", "%" + handle.trim() + "%");

  if (!error) {
    return data;
  } else {
    console.log(JSON.stringify(error));
    return null;
  }
};

export const getRecruitByHandle = async (handle) => {
  const { data, error } = await supabaseClient
    .from("Recruit")
    .select("*,HighSchool()")
    .eq("twitter", handle);

  if (!error && data.length > 0) {
    return data[0];
  } else {
    console.log("Error retrieving data: ", JSON.stringify(error));
    return null;
  }
};

export const getShallowRecruitByHandle = async (handle) => {
  const { data, error } = await supabaseClient
    .from("Recruit")
    .select("*")
    .eq("twitter", handle);

  if (!error && data.length > 0) {
    return data[0];
  } else {
    console.log("Error retrieving data: ", JSON.stringify(error));
    return null;
  }
};

export const getOn3Transfers = async () => {
  const { data, error } = await supabaseClient.from("On3_Transfers").select();

  if (!error) {
    return data;
  } else {
    console.log("Error retrieving data: ", JSON.stringify(error));
    return null;
  }
};

export const getRosters = async (teamId) => {
  const { data, error } = await supabaseClient
    .from("Roster")
    .select()
    .eq("teamId", teamId);

  if (!error) {
    return data;
  } else {
    console.log("Error retrieving data: ", JSON.stringify(error));
    return null;
  }
};

export const getTeam = async (id) => {
  const { data, error } = await supabaseClient
    .from("Team")
    .select("*,College(*,Conference(*))")
    .eq("id", id);

  if (!error) {
    if (data && data.length > 0) {
      return data[0];
    }
    return null;
  } else {
    console.log("Error retrieving data: ", JSON.stringify(error));
    return null;
  }
};

export const getTeamByName = async (name, teams) => {
  const team = teams.find(
    (x) => x.name.toLowerCase().trim === name.toLowerCase().trim
  );
  if (team && team.length > 0) {
    return team;
  } else {
    return null;
  }
};

export const getAllTeamsAndAbbreviations = async () => {
  const table = "Team";
  const { data, error } = await supabaseClient.from(table).select();

  if (!error) {
    const aryAllTeams = [];
    data.forEach((team) => {
      aryAllTeams.push(team); // Add the original team to aryAllTeams

      if (team.abbreviations && team.abbreviations.length > 0) {
        team.abbreviations.forEach((abbreviation) => {
          if (abbreviation && abbreviation !== "") {
            // Create a new team object for each abbreviation
            const newTeam = {
              ...team, // Copy properties from the original team
              name: abbreviation, // Update the name to the abbreviation
            };
            aryAllTeams.push(newTeam); // Add the new team to aryAllTeams
          }
        });
      }
    });
    return aryAllTeams;
  } else {
    console.log("Error retrieving data: ", JSON.stringify(error));
    return null;
  }
};

export const getAllTeams = async () => {
  const table = "Team";
  const { data, error } = await supabaseClient
    .from(table)
    .select("*,College(*,Conference(*))")
    .order("name", { ascending: true });

  if (!error) {
    return data;
  } else {
    console.log("Error retrieving data: ", JSON.stringify(error));
    return null;
  }
};

export const getAllSocialQueries = async () => {
  const { data, error } = await supabaseClient.from("SocialQuery").select();

  if (!error) {
    return data;
  } else {
    console.log("Error retrieving data: ", JSON.stringify(error));
  }
};

export const getStates = async () => {
  const table = "State";
  const { data, error } = await supabaseClient
    .from(table)
    .select("stateAbbrev,stateName,latitude,longitude");

  if (!error) {
    return data;
  } else {
    console.log("Error retrieving data: ", JSON.stringify(error));
  }
};

export const getTweets = async () => {
  const table = "Tweets";

  const { data, error } = await supabaseClient.from(table).select();
  if (!error) {
    return data;
  } else {
    console.log("Error retrieving data: ", JSON.stringify(error));
  }
};

export const getSocialPostByPostId = async (id) => {
  console.log("Getting Social Post:", id);
  const table = "SocialPost";

  const { data, error } = await supabaseClient
    .from(table)
    .select("*, Recruit(*),Team(*)")
    .eq("postId", id);

  if (!error && data.length > 0) {
    return data[0];
  } else {
    console.log("Error retrieving data: ", JSON.stringify(error));
    return null;
  }
};

export const getSocialPost = async (id) => {
  console.log("Getting Social Post:", id);
  const table = "SocialPost";

  const { data, error } = await supabaseClient
    .from(table)
    .select("*, Recruit(*),Team(*)")
    .eq("id", id);

  if (!error && data.length > 0) {
    return data[0];
  } else {
    console.log("Error retrieving data: ", JSON.stringify(error));
    return null;
  }
};

export const getUnprocessedSocialPosts = async () => {
  const { data, error } = await supabaseClient
    .from("SocialPost")
    .select("*, Recruit(*),Team(*)")
    .is("processed", false);

  if (data) {
    return data;
  } else {
    console.log("Error retrieving social posts data: ", JSON.stringify(error));
    return [];
  }
};

export const getUnprocessedTweets = async () => {
  const { data: activityData, error: activityError } = await supabaseClient
    .from("Activity")
    .select("tweetId")
    .not("tweetId", "is", null);

  if (activityError) {
    console.log(
      "Error retrieving activity data: ",
      JSON.stringify(activityError)
    );
    return [];
  }

  // Ensure tweetIds are integers
  const tweetIds = activityData
    ? activityData.map((activity) => parseInt(activity.tweetId))
    : [];
  console.log("Tweets:", tweetIds.length);

  const { data, error } = await supabaseClient.from("Tweets").select();

  if (!error) {
    return data.filter((tweet) => !tweetIds.includes(tweet.id));
  } else {
    console.log("Error retrieving tweet data: ", JSON.stringify(error));
    return [];
  }
};

export const getSocialPosts = async () => {
  const { data, error } = await supabaseClient
    .from("SocialPost")
    .select()
    .is("processed", false);
  if (!error) {
    return data;
  } else {
    console.log("ERROR: ", JSON.stringify(error));
    return null;
  }
};

export const getSocialPostsByQueryId = async (id) => {
  const table = "SocialPost";

  const { data, error } = await supabaseClient
    .from(table)
    .select()
    .eq("socialQueryId", id)
    .eq("processed", false);

  if (!error) {
    return data;
  } else {
    console.log("Error retrieving data: ", JSON.stringify(error));
  }
};

export const createUser = async (userForm) => {
  const { data, error } = await supabaseAdmin.auth.admin.createUser({
    email: userForm.email,
    password: userForm.password,
    email_confirm: true,

    user_metadata: {
      firstname: userForm.firstname,
      lastname: userForm.lastname,
      email: userForm.email,
      team: parseInt(userForm.team),
      role: parseInt(userForm.role),
    },
  });

  if (!error) {
    return data;
  } else {
    console.log("Error signing up user: ", JSON.stringify(error));
    return null;
  }
};

export const deleteUser = async (uuid) => {
  const { data, error } = await supabaseAdmin.auth.admin.deleteUser(uuid);

  if (!error) {
    return data;
  } else {
    console.log("Error deleting user: ", JSON.stringify(error));
    return null;
  }
};

export const updateHighSchool = async (highSchool) => {
  const { data, error } = await supabaseClient
    .from("HighSchools")
    .update(highSchool)
    .eq("id", highSchool.id)
    .select();

  if (!error) {
    return data;
  } else {
    console.log("error retrieving user organization: ", JSON.stringify(error));
    return null;
  }
};

export const addHighSchool = async (highSchool) => {
  const { data, error } = await supabaseClient
    .from("HighSchools")
    .insert(highSchool)
    .select();

  if (!error) {
    return data;
  } else {
    console.log("error retrieving user organization: ", JSON.stringify(error));
    return null;
  }
};

export const updateUser = async (userData) => {
  const { data, error } = await supabaseClient
    .from("user_profile")
    .update(userData)
    .eq("id", userData.id)
    .select();

  if (!error) {
    return data;
  } else {
    console.log("error retrieving user organization: ", JSON.stringify(error));
    return null;
  }
};

export const updatePassword = async (userData) => {
  const { data, error } = await supabaseClient
    .from("user_profile")
    .update(userData)
    .eq("id", userData.id)
    .select();

  if (!error) {
    return data;
  } else {
    console.log("error retrieving user organization: ", JSON.stringify(error));
    return null;
  }
};

export const getUser = async () => {
  const user = await supabaseClient.auth.getUser();
  return user;
};

export const getUserProfile = async (email) => {
  console.log("email:", email);
  const user_profile_id = localStorage.getItem("user_profile_id");
  const { data, error } = await supabaseClient
    .from("user_profile")
    .select("id,role(*),email")
    .eq(email ? "email" : "id", email ? email : user_profile_id);

  if (!error) {
    //console.log('org info: ', JSON.stringify(data))
    return data;
  } else {
    console.log("error retrieving user organization: ", JSON.stringify(error));
    return null;
  }
};

export const getAllAuthUsers = async () => {
  let allUsers = [];
  let page = 1;
  const pageSize = 100; // Supabase pagination limit (you can adjust this)
  let hasMorePages = true;

  while (hasMorePages) {
    try {
      const { data, error } = await supabaseAdmin.auth.admin.listUsers({
        page,
        perPage: pageSize,
      });

      if (error) {
        console.error("Error fetching users:", error);
        hasMorePages = false;
        break;
      }

      if (data?.users?.length > 0) {
        allUsers = [...allUsers, ...data.users];
        if (data.users.length < pageSize) {
          hasMorePages = false; // If users count is less than page size, we reached the end
        } else {
          page += 1; // Move to the next page
        }
      } else {
        hasMorePages = false; // No users found on this page
      }
    } catch (err) {
      console.error("Error:", err);
      break;
    }
  }
  console.log("All users:", allUsers);
  return allUsers; // Return the combined users array
};

export const getUserList = async () => {
  const { data, error } = await supabaseClient
    .from("user_profile")
    .select("*, Team(*), User_Role(*)");

  if (!error) {
    //console.log('org info: ', JSON.stringify(data))
    return data;
  } else {
    console.log("error retrieving user organization: ", JSON.stringify(error));
    return null;
  }
};

export const getAllRoles = async () => {
  const { data, error } = await supabaseClient.from("user_role").select("*");

  if (!error) {
    //console.log('org info: ', JSON.stringify(data))
    return data;
  } else {
    console.log("error retrieving user organization: ", JSON.stringify(error));
    return null;
  }
};

export const createUserByEmail = async (email) => {
  const { data, error } = await supabaseClient.auth.admin.inviteUserByEmail(
    email
  );
  if (error) {
    console.log("Error creating user: ", JSON.stringify(error));
    toast.error("Error creating user", {
      autoClose: 5000,
      position: "top-right",
    });
    return null;
  } else return data;
};

export const createUserProfileByUser = async (user, role_id) => {
  const tableName = "user_profile";

  const params = {
    email: user.user.email,
    user_id: user.user.id,
    role_id: role_id,
  };

  const { data, error } = await supabaseClient.from(tableName).insert({
    user_id: params.user_id,
    email: params.email,
    role: params.role_id,
  });
  if (!error) {
    return data;
  } else {
    console.log("error adding User profile ", JSON.stringify(error));
    toast.error("Error Adding user profile", {
      autoClose: 5000,
      position: "top-right",
    });
    return null;
  }
};

export const getUserRole = async (user_profile_id) => {
  const { data, error } = await supabaseClient
    .from("user_profile")
    .select("id,User_Role(*)")
    .eq("uuid", user_profile_id);

  if (!error) {
    //console.log('role info: ', JSON.stringify(data))
    return data;
  } else {
    console.log("error retrieving user role: ", JSON.stringify(error));
    return null;
  }
};

export const getAthleteTypes = async () => {
  const table = "AthleteType";

  const { data, error } = await supabaseClient.from(table).select();

  if (!error) {
    return data;
  } else {
    console.log("Error retrieving data: ", JSON.stringify(error));
  }
};

export const getTeams = async () => {
  const table = "Team";

  const { data, error } = await supabaseClient
    .from(table)
    .select("*,College(*,Conference(*))");

  if (!error) {
    return data;
  } else {
    console.log("Error retrieving data: ", JSON.stringify(error));
  }
};

export const upsertSocialPost = async (post) => {
  const { data: d, error: e } = await supabaseClient
    .from("SocialPost")
    .upsert(post, { onConflict: "postId" })
    .select();

  if (e) {
    console.log("error: ", JSON.stringify(e));
    return null;
  } else {
    return d;
  }
};

export const getSocialQueries = async () => {
  const table = "SocialQuery";

  const { data, error } = await supabaseClient
    .from(table)
    .select()
    .is("active", true);

  if (!error) {
    return data;
  } else {
    console.log("Error retrieving data: ", JSON.stringify(error));
  }
};

export const upsertTweet = async (tweet) => {
  const table = "Tweets";

  const { data, error } = await supabaseClient
    .from(table)
    .update(tweet)
    .eq("id", tweet.id);

  if (!error) {
    return data;
  } else {
    console.log("Error updating tweet: ", JSON.stringify(error));
  }
};

export const updateSocialPost = async (socialPost, process = false) => {
  delete socialPost.Team;
  delete socialPost.InteractionType;
  delete socialPost.Recruit;
  delete socialPost.state;

  console.log("Updating Social Post:", socialPost);

  const table = "SocialPost";

  const { data, error } = await supabaseClient
    .from(table)
    .update(socialPost)
    .eq("postId", socialPost.postId);

  if (!error) {
    const updatedSocialPost = await getSocialPostByPostId(socialPost.postId);
    if (process) {
      await processSocialPost(updatedSocialPost);
    }
    return socialPost;
  } else {
    console.log("Error retrieving data: ", JSON.stringify(error));
  }
};

export const updateAthlete = async (athlete) => {
  const table = "Athlete";
  const { data, error } = await supabaseClient
    .from(table)
    .update(athlete)
    .eq("id", athlete.id)
    .select();

  if (!error) {
    return data;
  } else {
    console.log("Error retrieving data: ", JSON.stringify(error));
    return null;
  }
};

export const updateRecruitInteractions = async (
  id,
  interactions,
  numOffers
) => {
  const table = "Recruit";
  const { data, error } = await supabaseClient
    .from(table)
    .update({ interactions: interactions, numberOfOffers: numOffers })
    .eq("id", id)
    .select();

  if (!error) {
    return data;
  } else {
    console.log("Error retrieving data: ", JSON.stringify(error));
    return null;
  }
};

export const updateLastLogin = async (id) => {
  const { data, error } = await supabaseClient
    .from("user_profile")
    .update({ last_login: new Date().toISOString() })
    .eq("id", id);

  if (!error) {
    return data;
  } else {
    console.log("Error updating last login: ", JSON.stringify(error));
    return null;
  }
};

export const processSocialPost = async (post) => {
  if (post.recruitId && post.teamId && post.interactionTypeId) {
    const team = getTeam(post.teamId);

    const activity = {
      interactionTypeId: post.interactionTypeId,
      recruitId: post.recruitId,
      teamId: post.teamId,
      activity_at: post.tweetCreated,
      date: convertToUnixTimestamp(post.tweetCreated),
      detail: post.content,
      link: post.link.replace("twitter.com", "x.com"),
      source: "scraped " + post.source,
      logo: team.logo,
      teamName: team.name,
      division: team.College?.Conference?.conferenceDivisionId,
      conference: team.College?.Conference?.name,
    };

    const a = await upsertActivityBySocialPost(activity);

    if (a) {
      post.activityId = a.id;
      post.processed = true;
      const updatedPost = await updateSocialPost(post, false);

      if (updatedPost) {
        const newRecruit = await rebuildRecruit(post.recruitId);
        return updatedPost;
      } else {
        return null;
      }
    } else {
      console.log(
        `Error upserting activity for post ${post.id} with recruit ID ${post.recruitId}, team ID ${post.teamId}, and interaction type ${post.interactionTypeId}`
      );
      return null;
    }
  }
};

export const addRecruit = async (athlete) => {
  const table = "Recruit";
  const { data, error } = await supabaseClient
    .from(table)
    .insert(athlete)
    .select();

  if (!error) {
    const recruit = data[0];
    console.log("ADDED RECRUIT", recruit);
    const activity = {
      detail: "Athlete added to database",
      interactionTypeId: 7,
      recruitId: recruit.id,
      activity_at: new Date().toISOString(),
      source: "Manual Entry",
      date: convertToUnixTimestamp(new Date().toISOString()),
    };
    await addNewActivity(activity);
    console.log("Added activity for recruit", recruit.id, activity);

    const socialPosts = (await getUnprocessedSocialPosts()).filter(
      (x) => x.handle === recruit.twitter
    );
    for (const post of socialPosts) {
      post.recruitId = recruit.id;
      if (post.teamId && post.interactionTypeId) {
        await updateSocialPost(post, true);
      } else {
        await updateSocialPost(post, false);
      }
    }
    rebuildRecruit(recruit.id);
    return recruit;
  } else {
    console.log("Error adding recruit: ", JSON.stringify(error));
    return null;
  }
};

export const updateRecruit = async (athlete) => {
  const table = "Recruit";
  console.log("athlete:", athlete);
  delete athlete.Activity;
  delete athlete.coachName;

  if (athlete.id > 0) {
    const { data, error } = await supabaseClient
      .from(table)
      .update(athlete)
      .eq("id", athlete.id)
      .select();

    if (!error) {
      const a = await getRecruit(athlete.id);
      return a;
    } else {
      console.log("Error updating Recruit: ", JSON.stringify(error));
      return null;
    }
  } else {
    const { data, error } = await supabaseClient
      .from(table)
      .insert(athlete)
      .select();

    if (!error) {
      return data;
    } else {
      console.log("Error adding new Recruit: ", JSON.stringify(error));
      return null;
    }
  }
};

export const addNewActivity = async (activity) => {
  const table = "Activity";

  const { data, error } = await supabaseClient
    .from(table)
    .insert(activity)
    .select();

  if (!error) {
    return data;
  } else {
    console.log(
      "Error inserting activity: ",
      JSON.stringify(activity + ": " + JSON.stringify(error))
    );
  }
};

export const deleteRecruit = async (id) => {
  const table = "Recruit";
  const recruit = await getRecruit(id);

  const { data, error } = await supabaseClient
    .from(table)
    .delete()
    .eq("id", id);

  if (!error) {
    const deleted = await deleteRecruitFromIndex(recruit);
    return data;
  } else {
    console.log(
      "Error deleting recruit: ",
      JSON.stringify(id + ": " + JSON.stringify(error))
    );
  }
};

export const deleteFile = async (id) => {
  const table = "Recruit_Files";
  const { data, error } = await supabaseClient
    .from(table)
    .delete()
    .eq("id", id);

  if (!error) {
    return true;
  } else {
    return null;
    console.log(
      "Error deleting recruit: ",
      JSON.stringify(id + ": " + JSON.stringify(error))
    );
  }
};

export const deleteActivityForRecruit = async (recruitId) => {
  const table = "Activity";

  const { data, error } = await supabaseClient
    .from(table)
    .delete()
    .eq("recruitId", recruitId);

  if (!error) {
    return data;
  } else {
    console.log(
      "Error deleting activity: ",
      JSON.stringify(recruitId + ": " + JSON.stringify(error))
    );
  }
};

export const deleteActivity = async (id) => {
  const table = "Activity";

  const { data, error } = await supabaseClient
    .from(table)
    .delete()
    .eq("id", id);

  if (!error) {
    return data;
  } else {
    console.log(
      "Error deleting activity: ",
      JSON.stringify(id + ": " + JSON.stringify(error))
    );
  }
};

export const deleteSocialPost = async (postId) => {
  const table = "SocialPost";
  console.log("Deleting Social Post:", postId);
  const { data, error } = await supabaseClient
    .from(table)
    .delete()
    .eq("postId", postId);

  if (!error) {
    return data;
  } else {
    console.log(
      "Error deleting post: ",
      JSON.stringify(postId + ": " + JSON.stringify(error))
    );
  }
};

export const addAthlete = async (athlete) => {
  const table = "Athlete";

  const { data, error } = await supabaseClient
    .from(table)
    .insert(athlete)
    .select();

  if (!error) {
    return data;
  } else {
    console.log(
      "Error inserting athlete: ",
      JSON.stringify(athlete + ": " + JSON.stringify(error))
    );
    return null;
  }
};

export function buildTransfer(x, teams) {
  const interactions = [];
  const source = "On3";

  let homeTownState = null;
  let homeTown = x.homeTownName;
  const aryHomeTown = homeTown.split(",");
  if (aryHomeTown.length > 0) {
    homeTown = aryHomeTown[0];
    homeTownState = aryHomeTown[1]?.replace("(", "").replace(")", "").trim();
  }

  if (x.commitStatus) {
    const teamKey = x.lastTeam ? x.lastTeam.key : null;
    let team = null;
    if (teamKey) {
      team = teams.find((t) => t.master_key === x.lastTeam.key);
    }
    const teamId = team ? team.id : null;
    const interaction = {
      interactionType: 6,
      interactionDate: x.commitStatus.transferEntered,
      teamId: teamId,
      interactionSource: source,
      team: team,
    };
    interactions.push(interaction);
  }
  if (x.interestStatus) {
    const teamKey = x.interestStatus.committedOrganization
      ? x.interestStatus.committedOrganization.key
      : null;
    let team = null;
    if (teamKey) {
      team = teams.find((t) => t.master_key === teamKey);
    }
    const committedTeamId = team ? team.id : null;

    const interaction = {
      interactionType: 4,
      interactionDate: x.interestStatus.date,
      teamId: committedTeamId,
      interactionSource: source,
      team: team,
    };
    interactions.push(interaction);
  }

  const transfer = {
    on3Id: x.key,
    on3Info: x.industryComparison.find((y) => y.type === "On3"),
    rivalsInfo: x.industryComparison.find((y) => y.type === "Rivals"),
    info247: x.industryComparison.find((y) => y.type === "247"),
    rivalsId: x.rivalsId,
    espnId: x.espnId,
    id247: x.Id247,
    rivalsSlug: x.rivalsSlug,
    slug247: x.Slug247,
    espnSlug: x.espnSlug,
    espnInfo: x.industryComparison.find((y) => y.type === "Espn"),
    image: x.defaultAssetUrl,
    fname: x.firstName,
    lname: x.lastName,
    offPosition: x.positionAbbreviation,
    graduatingYear: x.classYear,
    heightFeet: x.heightFeet,
    heightInches: x.heightInches,
    city: homeTown,
    athleteStateId: homeTownState,
    weight: x.weight,
    status: x.status,
    athleteAthleteTypeId: 3,
    rank: "7 Transfer Portal",
    lastTeam: x.lastTeam,
    source: "On3",
    interactions: interactions,
  };

  return transfer;
}
