import React, { useState, useEffect, useCallback } from "react";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import AthleteCard from "../components/athletes/AthleteCard";
import AthleteTable from "../components/athletes/AthleteTable";
import { generateHeights, generateWeights } from "../services/utilities";
import { SubmitIcon } from "../services/utilities";

import {
  Hits,
  CurrentRefinements,
  InstantSearch,
  Pagination,
  RefinementList,
  SearchBox,
  SortBy,
  Configure,
  useInstantSearch,
} from "react-instantsearch";

import { Sidebar, Menu, SubMenu } from "react-pro-sidebar";

import {
  ClearFilters,
  NoResults,
  NoResultsBoundary,
  Panel,
  PriceSlider,
  CustomStats,
} from "../components/shared";

import algoliasearch from "algoliasearch";

const searchClient = algoliasearch(
  "ETT8OD4DUG",
  "bb501e3aa5c51ecc4515f46406dc894c"
);
const indexName = "PROD_RECRUIT_ATHLETE";

function convertData(input, role) {
  const headers = [
    "NP Player Id",
    "Legacy Id",
    "Rank",
    "Gr-Yr",
    "ETS #",
    "High School",
    "First Name",
    "Last Name",
    "Address",
    "City",
    "State",
    "Zip",
    "High School Street",
    "High School City",
    "High School State",
    "High School County",
    "High School Zip",
    "Off Pos",
    "Def Pos",
    "ST Pos",
    "Scout Pos",
    "Ht",
    "Wt",
    "Forty",
    "Vertical",
    "Standing Broad Jump",
    "Jersey Number",
    "Cell Phone",
    "Home Phone",
    "E-Mail",
    "Twitter",
    "GPA",
    "SAT",
    "SAT Math",
    "SAT Reading",
    "ACT",
    "Offer",
    "Commit",
    "Added",
    "NP Scout Comments",
    "Hudl",
    "Coach Name",
    "Coach Phone",
    "Coach E-Mail",
    "Coach Twitter",
    "Locker Link",
  ];

  const stateList = role?.Team?.states || [];
  const yearList = role?.Team?.years || [];

  const escapeValue = (value) => {
    if (typeof value === "string") {
      // Escape double quotes by doubling them
      const escapedValue = value.replace(/"/g, '""');
  
      // Wrap in quotes if it contains commas, double quotes, or line breaks
      if (escapedValue.includes(",") || escapedValue.includes('"') || /\r?\n/.test(escapedValue)) {
        return `"${escapedValue}"`;
      }
  
      return escapedValue; // Return the value as-is if no special characters
    }
    return value;
  };

  let filteredInput = input;

  if (stateList.length > 0) {
    filteredInput = filteredInput.filter((entry) =>
      stateList.includes(entry.hsState)
    );
  }

  if (yearList.length > 0) {
    filteredInput = filteredInput.filter((entry) =>
      yearList.includes(entry.year?.toString())
    );
  }

  const csvRows = filteredInput.map((entry) =>
    [
      escapeValue(entry.id),
      escapeValue(entry.npPlayerId),
      escapeValue(entry.rank),
      escapeValue(entry.year),
      escapeValue(entry.ets),
      escapeValue(entry.highSchool),
      escapeValue(entry.firstName),
      escapeValue(entry.lastName),
      escapeValue(entry.athleteAddress),
      escapeValue(entry.athleteCity),
      escapeValue(entry.athleteState),
      escapeValue(entry.athleteZip),
      escapeValue(entry.hsStreet),
      escapeValue(entry.hsCity),
      escapeValue(entry.hsState),
      escapeValue(entry.hsCounty),
      escapeValue(entry.hsZip),
      escapeValue(entry.offPosition),
      escapeValue(entry.defPosition),
      escapeValue(entry.specialTeamsPosition),
      escapeValue(entry.scoutPos),
      escapeValue(entry.height),
      escapeValue(entry.weight),
      escapeValue(entry.forty),
      escapeValue(entry.verticalJump),
      escapeValue(entry.standingBroadJump),
      escapeValue(entry.jersey),
      escapeValue(entry.cellPhone),
      escapeValue(entry.homePhone),
      escapeValue(entry.email),
      escapeValue(entry.twitter),
      escapeValue(entry.gpa),
      escapeValue(entry.sat),
      escapeValue(entry.satMath),
      escapeValue(entry.satReading),
      escapeValue(entry.act),
      escapeValue(
        entry.Activity?.filter((a) => a.interactionType === 2)
          .map((a) => a.name)
          .join(", ")
      ),
      escapeValue(
        entry.Activity?.filter((a) => a.interactionType === 4)
          .map((a) => a.name)
          .join(", ")
      ),
      escapeValue(
        entry.Activity?.filter((a) => a.interactionType === 7)
          .map((a) => a.created_at)
          .join(", ")
      ),
      escapeValue(entry.scoutComments),
      escapeValue(entry.hudl),
      escapeValue(entry.coachName),
      escapeValue(entry.coachPhone),
      escapeValue(entry.coachEmail),
      escapeValue(entry.coachTwitter),
      escapeValue("https://nationalpreps.io/recruit/view/" + entry.id),
    ].join(",")
  );

  return [headers.map(escapeValue).join(","), ...csvRows].join("\n");
}

const getGPAs = () => {
  const items = [];
  for (let i = 0; i <= 6; i += 0.1) {
    const value = i.toFixed(1);
    items.push({ value, label: value });
  }
  return items;
};

const getSATs = () => {
  const items = [];
  items.push({ value: 0, label: 0 });
  for (let i = 600; i <= 1600; i += 10) {
    const value = i;
    items.push({ value, label: value });
  }
  return items;
};

const getACT = () => {
  const items = [];
  for (let i = 0; i <= 36; i += 1) {
    const value = i;
    items.push({ value, label: value });
  }
  return items;
};

function ExportButton(props) {
  const { scopedResults } = useInstantSearch();
  const { role } = props;

  const handleExport = async () => {
    let totalHits = [];

    if (scopedResults[0].results?._rawResults[0]?.nbHits > 0) {
      console.log("Exporting Records...");
      const results = scopedResults[0].results?._rawResults[0];
      const query = results?.query;
      const filters = new URLSearchParams(results.params);
      const facetFilters = filters.get("facetFilters");
      const numericFilters = filters.get("numericFilters");

      let browseIndex = searchClient.initIndex(indexName);
      console.log("numeric filters:", numericFilters);

      await browseIndex.browseObjects({
        query: query || "",
        facetFilters: facetFilters,
        numericFilters: numericFilters ? numericFilters : "", // Check if numericFilters exists
        batch: (batch) => {
          totalHits = totalHits.concat(batch);
        },
      });

      console.log(totalHits.length + " Records Found");

      const data = convertData(totalHits, role);

      // Trigger CSV download
      const csvLink = document.createElement("a");
      const blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), ...data], {
        type: "text/csv;charset=utf-8;",
      });
      const url = URL.createObjectURL(blob);
      csvLink.href = url;
      csvLink.setAttribute("download", "high_school_athletes.csv");
      document.body.appendChild(csvLink);
      csvLink.click();
      document.body.removeChild(csvLink);
      console.log("Export Complete");
    }
  };

  return (
    <button onClick={handleExport} className="btn btn-success">
      Export Records →
    </button>
  );
}

export default function Athlete(props) {
  const [view, setView] = useState("grid");
  const { role } = props;
  const [query, setQuery] = useState("");
  const [minGPA, setMinGPA] = useState(0);
  const [minSAT, setMinSAT] = useState(0);
  const [minACT, setMinACT] = useState(0);
  const heightList = generateHeights();
  const weightList = generateWeights();
  const [minHeight, setMinHeight] = useState(0);
  const [minWeight, setMinWeight] = useState(0);

  const handleChangeGPA = (event) => {
    setMinGPA(event.target.value);
  };

  const handleChangeSAT = (event) => {
    console.log("sat:", event.target.value);
    setMinSAT(event.target.value);
  };

  const handleChangeACT = (event) => {
    setMinACT(event.target.value);
  };

  const handleChangeHeight = (event) => {
    setMinHeight(event.target.value);
  }

  const handleChangeWeight = (event) => {
    setMinWeight(event.target.value);
  }

  const handleCard = useCallback(() => {
    setView("card");
  }, []);

  const handleGrid = useCallback(() => {
    setView("grid");
  }, []);

  // Generate the state and year filter strings
  // Generate the state and year filter strings using OR
// Generate the state and year filter strings using OR
let stateFilters = role?.Team?.states?.map((state) => `location:"${state}"`).join(" OR ") || "";
  let yearFilters = role?.Team?.years?.map((year) => `year:${year}`).join(" OR ") || "";
  const weightFilters = `weight >= ${minWeight || 0}`;
  const heightFilters = `(heightInches >= ${minHeight || 0})`;
  const academicFilters = `(gpa >= ${minGPA || 0} AND sat >= ${minSAT || 0} AND act >= ${minACT || 0})`;

  stateFilters = stateFilters ? `${stateFilters}` : "";
  yearFilters = yearFilters ? `${yearFilters}` : "";
  
  let combinedFilter = stateFilters !== "" ? "(" + stateFilters + ")" : "";
  combinedFilter = yearFilters !== "" ? combinedFilter + " AND (" + yearFilters + ")" : combinedFilter;
  combinedFilter = weightFilters !== "" ? combinedFilter + " AND " + weightFilters : combinedFilter; 
  combinedFilter = heightFilters !== "" ? combinedFilter + " AND " + heightFilters : combinedFilter;
  combinedFilter = academicFilters !== "" ? combinedFilter + " AND (" + academicFilters + ")" : combinedFilter;
  
  console.log("Combined Filter:", combinedFilter);
  if (combinedFilter.startsWith(' AND')) {
    combinedFilter = combinedFilter.substring(5);  
  } 

  
  return (
    <div className="row" style={{ marginRight: "0", marginLeft: "0" }}>
      <InstantSearch
        searchClient={searchClient}
        indexName={indexName}
        insights={true}
      >
        <Configure
          analytics={false}
          hitsPerPage={1000}
          filters={combinedFilter}
        />

        <div
          className="col-md-3 col-xs-12 d-flex justify-content-center custom-scrollbar"
          style={{
            height: "100vh",
            overflowY: "scroll",
            backgroundColor: "#222222",
          }}
        >
          <div className="filter-sidebar">
            <h3 style={{ width: "100%", textAlign: "center" }}>HS Athletes</h3>
            <hr />
            {role && role.User_Role?.type === "A" && (
              <div className="d-flex justify-content-center">
                <Link
                  to="/admin/athlete/add"
                  className="btn btn-success"
                  style={{ backgroundColor: "#000" }}
                >
                  Add Athlete
                </Link>
              </div>
            )}
            <section className="container-filters pt-3">
              <div className="container-body">
                <SearchBox placeholder="Name, Twitter Handle" />
                <CustomStats />
                <Sidebar>
                  <Menu
                    menuItemStyles={{
                      button: {
                        border: "none",
                        minWidth: "0px",
                        marginLeft: "0",
                        paddingLeft: "0",
                        [`&.active`]: {
                          backgroundColor: "#222222",
                          color: "#7AFD78",
                        },
                        [`&:hover`]: {
                          backgroundColor: "#222222",
                          color: "#7AFD78 !important",
                        },
                      },
                    }}
                  >
                    <SubMenu label="Class">
                      <Panel header="Year Graduating">
                        <RefinementList attribute="year" limit="10" />
                      </Panel>
                    </SubMenu>
                    <hr />
                    <SubMenu label="High School">
                      <Panel header="High School">
                        <RefinementList
                          attribute="highSchool"
                          searchable={true}
                          limit="5"
                          showMore={true}
                        />
                      </Panel>
                    </SubMenu>
                    <hr />
                    <SubMenu label="Location">
                      <Panel header="State">
                        <RefinementList
                          attribute="hsState"
                          limit="100"
                          searchable={true}
                        />
                      </Panel>
                      <Panel header="County">
                        <RefinementList
                          attribute="hsCounty"
                          searchable={true}
                        />
                      </Panel>
                    </SubMenu>
                    <hr />
                    <SubMenu label="NP Rating">
                      <Panel>
                        <RefinementList attribute="rank" limit="10" />
                      </Panel>
                    </SubMenu>
                    <hr />
                    <SubMenu label="Position">
                      <Panel header="Off Position">
                        <RefinementList attribute="offPosition" limit="30" />
                      </Panel>
                      <Panel header="Def Position">
                        <RefinementList attribute="defPosition" limit="30" />
                      </Panel>
                      <Panel header="Special Teams Position">
                        <RefinementList
                          attribute="specialTeamsPosition"
                          limit="30"
                        />
                      </Panel>
                      <Panel header="Scout Position">
                        <RefinementList attribute="scoutPos" limit="30" />
                      </Panel>
                    </SubMenu>
                    <hr />
                    <SubMenu label="Offers & Commitments">
                      <Panel header="Number of Offers">
                        <PriceSlider attribute="offerNumber" />
                      </Panel>
                      <Panel header="Committed">
                        <RefinementList attribute="isCommitted" />
                      </Panel>
                      <Panel header="Offer Division">
                        <RefinementList attribute="Activity.division" />
                      </Panel>
                      <Panel header="Offer Conference">
                        <RefinementList
                          attribute="Activity.conference"
                          searchable={true}
                        />
                      </Panel>
                      <Panel header="Offer College">
                        <RefinementList
                          attribute="Activity.name"
                          searchable={true}
                        />
                      </Panel>
                    </SubMenu>
                    <hr />
                    <SubMenu label="Height (inches) & Weight">
                      <Panel className="no-border" header="Height At Least">
                      <select
                          style={{
                            padding: "10px",
                            borderRadius: "5px",
                            width: "100px",
                          }}
                          name="gpa"
                          id="gpa"
                          onChange={handleChangeHeight}
                        >
                          {heightList.map((ht, index) => (
                            <option key={index} value={ht}>
                              {ht}
                            </option>
                          ))}
                        </select>&nbsp;<span style={{color:'white', marginLeft:'15px'}}>inches</span>
                      </Panel>
                      <Panel className="no-border" header="Weight At Least">
                      <select
                          style={{
                            padding: "10px",
                            borderRadius: "5px",
                            width: "100px",
                          }}
                          name="gpa"
                          id="gpa"
                          onChange={handleChangeWeight}
                        >
                          {weightList.map((wt, index) => (
                            <option key={index} value={wt}>
                              {wt}
                            </option>
                          ))}
                        </select>&nbsp;<span style={{color:'white', marginLeft:'15px'}}>lbs</span>


                      </Panel>
                    </SubMenu>
                    <hr />
                    <SubMenu label="Academics">
                      <Panel>
                        <span style={{ color: "#fff" }}>
                          GPA at least&nbsp;&nbsp;
                        </span>
                        <select
                          style={{
                            padding: "10px",
                            borderRadius: "5px",
                            width: "100px",
                          }}
                          name="gpa"
                          id="gpa"
                          onChange={handleChangeGPA}
                        >
                          {getGPAs().map((gpa, index) => (
                            <option key={index} value={gpa.value}>
                              {gpa.label}
                            </option>
                          ))}
                        </select>
                      </Panel>
                      <Panel>
                        <span style={{ color: "#fff" }}>
                          SAT at least&nbsp;&nbsp;
                        </span>
                        <select
                          style={{
                            padding: "10px",
                            borderRadius: "5px",
                            width: "100px",
                          }}
                          name="sat"
                          id="sat"
                          onChange={handleChangeSAT}
                        >
                          {getSATs().map((sat, index) => (
                            <option key={index} value={sat.value}>
                              {sat.label}
                            </option>
                          ))}
                        </select>
                      </Panel>

                      <Panel>
                        <span style={{ color: "#fff" }}>
                          ACT at least&nbsp;&nbsp;
                        </span>
                        <select
                          style={{
                            padding: "10px",
                            borderRadius: "5px",
                            width: "100px",
                          }}
                          name="act"
                          id="act"
                          onChange={handleChangeACT}
                        >
                          {getACT().map((act, index) => (
                            <option key={index} value={act.value}>
                              {act.label}
                            </option>
                          ))}
                        </select>
                      </Panel>
                    </SubMenu>

                    {/* <SubMenu label="Academics">
                                            <Panel header='GPA'>
                                                <PriceSlider attribute="gpa" />
                                            </Panel>
                                            <Panel header='SAT'>
                                                <PriceSlider attribute="sat" />
                                            </Panel>
                                            <Panel header='ACT'>
                                                <PriceSlider attribute="act" />
                                            </Panel>
                                        </SubMenu> */}
                  </Menu>
                </Sidebar>
              </div>
            </section>
          </div>
        </div>
        <div
          className="col-md-9 col-xs-12"
          style={{
            height: "100vh",
            overflowY: "scroll",
            backgroundColor: "#F3F3F3",
          }}
        >
          <div className="box-content">
            <main className="container">
              <section className="container-results col-md-12">
                <header className="container-header container-options">
                  <div className="col-md-3 buttons">
                    <div onClick={handleCard} className="list">
                      <i className="fa fa-list"></i>&nbsp;Card View
                    </div>
                    <div onClick={handleGrid} className="grid">
                      <i className="fa fa-th-large"></i>&nbsp;List View
                    </div>
                  </div>
                  <div className="col-md-5">
                    <ClearFilters />
                    <CurrentRefinements />
                  </div>
                  <div className="col-md-2">
                    <ExportButton role={role} />
                  </div>
                  <div className="col-md-2">
                    <SortBy
                      items={[
                        { label: "Most Recent", value: "PROD_RECRUIT_ATHLETE" },
                        {
                          label: "NP Rating",
                          value: "PROD_RECRUIT_ATHLETE_RATING",
                        },
                        {
                          label: "County",
                          value: "PROD_RECRUIT_ATHLETE_COUNTY",
                        },
                        {
                          label: "High School",
                          value: "PROD_RECRUIT_ATHLETE_HIGHSCHOOL",
                        },
                      ]}
                    />
                  </div>
                </header>
                <div className="">
                  <NoResultsBoundary fallback={<NoResults />}>
                    {view === "grid" ? (
                      <AthleteTable role={role} />
                    ) : (
                      <Hits hitComponent={AthleteCard} />
                    )}
                  </NoResultsBoundary>
                  <footer className="container-footer">
                    <Pagination padding={2} showFirst={true} showLast={true} />
                  </footer>
                </div>
              </section>
            </main>
          </div>
        </div>
      </InstantSearch>
    </div>
  );
}
