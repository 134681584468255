import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';

const LeftNav = () => {

    return (
        <>
        <hr style={{color:'#fff'}} />
        <Sidebar style={{border:'none'}} >
            <Menu menuItemStyles={{
                button: {
                    border: 'none',
                    minWidth: '500px',
                    backgroundColor: '#222222',
                    color: '#fff',
                    // the active class will be added automatically by react router
                    // so we can use it to style the active menu item
                    [`&.active`]: {
                        color: 'green',
                    },
                    [`&:hover`]: {
                        backgroundColor: '#FCFCFC',
                        color: '#fff'
                    }
                },
            }} >
                <MenuItem component={<Link to="/admin/dashboard" />}> Dashboard </MenuItem>
                <MenuItem component={<Link to="/admin/athletes" />}> Athletes </MenuItem>
                <MenuItem  component={<Link to="/admin/transfer" />}> Transfer Portal </MenuItem>
                <MenuItem component={<Link to="/admin/recruit" />}> Recruit Board </MenuItem>
                <SubMenu label="Reports" >
                    <MenuItem component={<Link to="/admin/report-athlete" />}> Athletes </MenuItem>
                    <MenuItem component={<Link to="/admin/report-high-school" />}> High Schools </MenuItem>
                </SubMenu>
                <SubMenu label="Maintenance">
                    <MenuItem component={<Link to="/admin/colleges" />}> Colleges </MenuItem>
                    <MenuItem component={<Link to="/admin/conferences" />}> Conferences </MenuItem>
                    <MenuItem component={<Link to="/admin/divisions" />}> Divisions </MenuItem>
                    <MenuItem component={<Link to="/admin/highschools" />}> High Schools </MenuItem>
                    <MenuItem component={<Link to="/admin/socialqueries" />}> Social Queries </MenuItem>
                    <MenuItem component={<Link to="/admin/rebuildindices" />}> Rebuild Indices </MenuItem>
                </SubMenu>
                <SubMenu label="Lookup Lists">
                    <MenuItem component={<Link to="/admin/genders" />}> Genders </MenuItem>
                    <MenuItem component={<Link to="/admin/interactiontypes" />}> Interaction Types </MenuItem>
                    <MenuItem component={<Link to="/admin/states" />}> States </MenuItem>
                    <MenuItem component={<Link to="/admin/highschooltypes" />}> High School Types </MenuItem>
                    <MenuItem component={<Link to="/admin/socialnetworks" />}> Social Networks </MenuItem>
                </SubMenu>
                <MenuItem component={<Link to="/admin/account" />}> My Account </MenuItem>
                <MenuItem component={<Link to="/admin/help" />}> Help </MenuItem>
                <MenuItem component={<Link to="/admin/logout" />}> Logout </MenuItem>
            </Menu>
        </Sidebar>
        </>
    )

}

export default LeftNav;