import React, { useState, useEffect } from 'react';
import { getAllPFFAthletes, getAllTeams, upsertNCAATransfer } from '../../services/supabase';

function ImportNCAA() {
  const [jsonInput, setJsonInput] = useState('');
  const [parsedJson, setParsedJson] = useState([]);
  const [teams, setTeams] = useState([]);
  const [pffAthletes, setPFFAthletes] = useState([]);

  const handleInputChange = (event) => {
    setJsonInput(event.target.value);
  };

  const parseJson = () => {
    try {
        const players = JSON.parse(jsonInput).data;
        const importedPlayers = [];
        for (const player of players) {
            let team = null;
           
                const splitName = player.lastName.split(' ')
                if (splitName.length > 1) {
                player.lastName = splitName[0]
                player.suffix = splitName[1]
                }
            
            const playerSlug = player.firstName.toLowerCase() + '-' + player.lastName.toLowerCase(); 
            player.slug = playerSlug.replace(' ','-');
                team = teams.find(x => x.ncaaOrgId === player.orgId)
                if (team) {
                    player.espnTeamId = team.espnId;
                    player.espnTeamSlug = team.espnSlug;
                    if (team.pffFranchiseId) {
                        player.pffFranchiseId = team.pffFranchiseId
                        const pffAthlete = pffAthletes.find(y => y.franchise_id === team.pffFranchiseId && y.slug == playerSlug)
                        {
                            if (pffAthlete) {
                                player.pffId = pffAthlete.id;
                                player.position = pffAthlete.position;
                                player.height = pffAthlete.height;
                                player.weight = pffAthlete.weight;
                                player.city = pffAthlete.birthCity;
                                player.state = pffAthlete.birthState;
                                player.snaps = pffAthlete.overall_snaps;
                            }
                            else
                            {
                                //importedPlayers.push(`NO PFF ATHLETE FOUND: ${player.firstName} ${player.lastName} ${playerSlug}`)
                            }
                        }
                    }
                    else
                    {

                        //importedPlayers.push(`NO PFF FRANCHISE IN TEAM: ${team.name} ${player.lastName} ${player.orgId}`)
                    }
                
                }
                else {
                    importedPlayers.push(`NO NCAA ORGID IN TEAM: ${player.institutionName} ${player.orgId}`)
                }
                
            upsertNCAATransfer(player)
            //importedPlayers.push(`${player.firstName} ${player.lastName}`);
        }
        setParsedJson(importedPlayers);
    } catch (error) {
        console.error(`Error reading JSON content:`, error);
    }
  };

  useEffect(() => {
    async function fetchData() {
        try {
            const fetchedTeams = await getAllTeams();
            const athletes = await getAllPFFAthletes();
            setTeams(fetchedTeams);
            setPFFAthletes(athletes);
            setParsedJson([]);
        } catch (error) {
            console.error('Error fetching teams:', error);
        }
    }

    fetchData();
}, []);

  return (
    <div>
        <h3 style={{marginBottom:'20px'}}>
        Import NCAA Transfer Players
        </h3>
        <ol>
            <li>Login to NCAA Portal</li>
            <li>Navigate to https://www.pff.com/college/grades/position/qb</li>
            <li>Select the current year</li>
            <li>Set the number of results to 500</li>
            <li>Set the Sport to Football</li>
            <li>Open Developer Tools</li>
            <li>Change to the Network Tab</li>
            <li>Click Search</li>
            <li>Look for the otherTransfers? url and copy the response</li>
            <li>Click on the Next page and copy the response</li>
            <li>Repeat until you have copied all of the pages</li>
        </ol>

      <textarea
        placeholder="Paste NCAA Transfer Players here..."
        value={jsonInput}
        onChange={handleInputChange}
        rows={20}
        cols={100}
      />
       <p><button className="btn btn-success" onClick={() => parseJson()}>Import NCAA Players
                        </button></p>
      
      {parsedJson.length > 0 && (
        <div>
          <h2>Players Imported:</h2>
          <ul>
            {parsedJson.length > 0 ? parsedJson.map((player, index) => (
              <li key={index}>{player}</li>
            )) : 'No Errors'}
          </ul>
        </div>
      )}
    </div>
  );
}

export default ImportNCAA;
