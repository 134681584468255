import React, { useState, useEffect } from "react";
import { getRecruitFiles, deleteFile, uploadFile } from "../../services/supabase";
import 'react-image-lightbox/style.css';
import Lightbox from 'react-image-lightbox';
import unknownImage from '../../assets/imgs/unknown.png';

const AthleteFiles = (props) => {
    const { id, isAdmin = false } = props;
    const [recruitFiles, setRecruitFiles] = useState([]);
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [title, setTitle] = useState('');
    const [uploading, setUploading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [file, setFile] = useState(null);

    const handleImageClick = (index) => {
        setCurrentImage(index);
        setLightboxOpen(true);
    };

    const handleDeleteClick = async (fileId) => {
        if (window.confirm("Are you sure you want to delete this file?")) {
            try {
                await deleteFile(fileId);
                const updatedFiles = recruitFiles.filter(f => f.id !== fileId);
                setRecruitFiles(updatedFiles);
            } catch (error) {
                console.error('Error deleting file:', error);
            }
        }
    };

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    };

    const handleUpload = async () => {
        setError(null);
        setSuccessMessage(null);
    
        if (!file) {
            setError('Please select a file to upload.');
            return;
        }
    
        setUploading(true);
    
        const fileName = `${id}_${Date.now()}_${file.name}`;
    
        try {
            const uploadedFile = await uploadFile(fileName, file, id, title);
            if (!uploadedFile) {
                setError('Error uploading file.');
                return;
            } 
    
            setSuccessMessage('File uploaded successfully.');
    
            // Add the new file to the recruitFiles state
            const newFile = {
                id: uploadedFile.id,  // Assuming `uploadedFile.id` is the unique identifier
                file: fileName,
                title: title,
                // Add any other relevant properties returned by the uploadFile function
            };
            
            setRecruitFiles(prevFiles => [...prevFiles, newFile]);
    
            // Reset input fields
            setTitle('');
            setFile(null);
            
        } catch (error) {
            setError('Unexpected error occurred: ' + error.message);
        } finally {
            setUploading(false);
        }
    };
    

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const files = await getRecruitFiles(id);
                setRecruitFiles(files);
            } catch (error) {
                console.error('Error fetching data:', error);
                setError('Failed to load files. Please try again later.');
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [id]);

    if (loading) return <div>Loading...</div>;

    return (
        <div>
            {error && <div style={{ color: 'red' }}>{error}</div>}

            <div className='files-container' style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                {recruitFiles?.length === 0 && <span>No files found</span>}
                {recruitFiles?.map((file, index) => {
                    const src = `https://ktbibwgpjxgqhgsovoec.supabase.co/storage/v1/object/public/recruitFiles/${file.file}`;
                    const fileExtensions = ['.gif', '.jpg', '.jpeg', '.png', '.svg', '.webp', '.bmp', '.ico', '.tiff', '.tif'];
                    if (fileExtensions.some(ext => file.file.includes(ext))) {
                        return (
                            <div key={file.id} className='file-item' style={{ flex: '0 1 calc(25% - 10px)', boxSizing: 'border-box' }}>
                                <img
                                    src={src}
                                    width="100%"
                                    onError={(e) => { e.target.onerror = null; e.target.src = unknownImage; }}
                                    onClick={() => handleImageClick(index)}
                                    style={{ cursor: 'pointer' }}
                                    alt={file.title || "Recruit File"}
                                />
                                {isAdmin && <p><button className="btn btn-danger" onClick={() => handleDeleteClick(file.id)}>Delete</button></p>}
                            </div>
                        );
                    }
                    const videoExtensions = ['.mov', '.mp4', '.avi', '.flv', '.wmv', '.mkv', '.webm', '.mpg', '.mpeg', '.3gp', '.3g2', '.m4v', '.f4v', '.f4p', '.f4a', '.f4b'];
                    if (videoExtensions.some(ext => file.file.toLowerCase().includes(ext))) {
                        return (
                            <div key={file.id} className='file-item' style={{ flex: '0 1 calc(25% - 10px)', boxSizing: 'border-box' }}>
                                <video width="100%" controls>
                                    <source src={src} type="video/mp4" />
                                </video>
                                {isAdmin && <p><button className="btn btn-danger" onClick={() => handleDeleteClick(file.id)}>Delete</button></p>}
                            </div>
                        );
                    }
                    const docExtensions = ['.doc', '.docx', '.pdf', '.ppt', '.pptx', '.xls', '.xlsx'];
                    if (docExtensions.some(ext => file.file.includes(ext))) {
                        return (
                            <div key={file.id} className='file-item' style={{ flex: '0 1 calc(25% - 10px)', boxSizing: 'border-box' }}>
                                <a href={src} target="_blank" rel="noreferrer">
                                    {file.title}
                                </a>
                                {isAdmin && <p><button className="btn btn-danger" onClick={() => handleDeleteClick(file.id)}>Delete</button></p>}
                            </div>
                        );
                    }
                    return null;
                })}

                {lightboxOpen && (
                    <Lightbox
                        mainSrc={`https://ktbibwgpjxgqhgsovoec.supabase.co/storage/v1/object/public/recruitFiles/${recruitFiles[currentImage].file}`}
                        nextSrc={`https://ktbibwgpjxgqhgsovoec.supabase.co/storage/v1/object/public/recruitFiles/${recruitFiles[(currentImage + 1) % recruitFiles.length].file}`}
                        prevSrc={`https://ktbibwgpjxgqhgsovoec.supabase.co/storage/v1/object/public/recruitFiles/${recruitFiles[(currentImage + recruitFiles.length - 1) % recruitFiles.length].file}`}
                        onCloseRequest={() => setLightboxOpen(false)}
                        onMovePrevRequest={() => setCurrentImage((currentImage + recruitFiles.length - 1) % recruitFiles.length)}
                        onMoveNextRequest={() => setCurrentImage((currentImage + 1) % recruitFiles.length)}
                    />
                )}
            </div>

            {isAdmin && (
                <div className='files-container' style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                    <table className="table" style={{ width: '100%', border: '1px solid #000' }}>
                        <tbody>
                            <tr>
                                <td style={{ verticalAlign: 'middle' }}>
                                    <input
                                        type="text"
                                        name="title"
                                        id="title"
                                        value={title}
                                        placeholder="Enter file title"
                                        onChange={handleTitleChange}
                                    />
                                </td>
                                <td style={{ verticalAlign: 'middle' }}>
                                    <input type="file" accept="image/*,video/*" onChange={handleFileChange} />
                                </td>
                                <td style={{ verticalAlign: 'middle' }}>
                                    <button className="btn btn-success" onClick={handleUpload} disabled={uploading}>
                                        {uploading ? 'Uploading...' : 'Upload'}
                                    </button>
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                    {error && <div style={{ color: 'red' }}>{error}</div>}
                                    {successMessage && <div style={{ color: 'green' }}>{successMessage}</div>}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default AthleteFiles;
