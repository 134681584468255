import { useStats } from 'react-instantsearch';

export function CustomStats() {
  const {
    hitsPerPage,
    nbHits,
    areHitsSorted,
    nbSortedHits,
    nbPages,
    page,
    processingTimeMS,
    query,
  } = useStats();

  return (
  <span className="customStatsResults">{nbHits.toLocaleString()} result{nbHits > 1 ? 's' : ''}</span>
  )
}