import { supabaseClient } from '../../services/supabaseclient';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import npLogo from '../../assets/imgs/np.png';

const Login = () => {

    return (
        <main className="main container-fluid" style={{background: 'url("/static/media/NP_header.de5317ac5317541cb746.jpg") 0% 0% / cover'}} >
            <div className="box-content justify-content-center" style={{alignItems:'center', height:'100vh'}}>
                <div className="justify-content-center" style={{width:'400px', display:'flex', justifyContent:'center', flexDirection:'column', textAlign:'center'}}>
                    <div className="w-100"><img src={npLogo} alt="National Preps Logo" style={{width:'40%'}} /></div>
                    <Auth supabaseClient={supabaseClient} appearance={{ theme: ThemeSupa }} providers={[]}  style={{width:'100%'}} showLinks={false} />
            </div>
        </div>
        </main>
    )

}

export default Login;

