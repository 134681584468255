import React from 'react';
import { getSocialPosts, upsertSocialPost, processSocialPost, getRecruitByTwitterHandle, getTeams, getAllTeamsAndAbbreviations, upsertActivity } from '../../services/supabase';
import { useState, useEffect } from 'react';

function ImportSocialPosts() {
    const [logData, setLogData] = useState([]);
    const stopWords = [
        "a", "an", "and", "are", "as", "at", "be", "by", "for", "from", "has", "he", "in", "is", "it", "its",
        "of", "on", "that", "the", "to", "was", "were", "will", "with", "blessed", "bless", "blessing", "blessings", "agtg",
        "receive", "received", "receiving", "i", "very", "announce", "after", "ag2g", "extremely", "grateful", "my", "247recruiting",
        "on3recruits", "this", "home", "call", "conversation", "thank", "you", "have", "after", "great", "visit", "official",
        "good", "phone", "talk", "am", "excited", "talk", "informative", "absolutely", "first", "second", "third", "fourth", "fifth",
        "scholarship", "play", "camp", "showcase", "show", "case", "showed", "off", "offer", "offered", "offering", "commit", "committed",
        "me"
    ];

    function processPostContent(postContent) {
        const words = postContent.toLowerCase().match(/(?:@\w+|\b\w+\b)/g);
        let filteredWords = words.filter(word => !stopWords.includes(word));
        return filteredWords.join(' ').toUpperCase();
    }

    function convertToUnixTimestamp(dateString) {
        const date = new Date(dateString);
        return date.getTime();
    }

    async function handlePost(post, teams, teamsMin, foundCounters) {
        let teamName = '';
        let teamConference = '';
        let teamDivision = '';
        let teamLogo = '';

        setLogData((prev) => [...prev, `==========================`]);
        setLogData((prev) => [...prev, `POST ID ${post.postId}`]);
        setLogData((prev) => [...prev, `==========================`]);
        
        try {
            const recruit = await getRecruitByTwitterHandle(post.handle);
            if (recruit && recruit.length > 0) {
                post.recruitId = recruit[0].id;
                foundCounters.recruits += 1;
                
                setLogData((prev) => [...prev, `RECRUIT: ${recruit[0].firstName} ${recruit[0].lastName}`]);
                console.log('FOUND RECRUIT:', `${recruit[0].firstName} ${recruit[0].lastName}`);
            }

            if (/OFFER|🅾️FFER|⭕️FFER/.test(post.content.toUpperCase())) {
                post.interactionTypeId = 2;
                foundCounters.interactions += 1;
                setLogData((prev) => [...prev, `OFFER`]);
            }

            if (/COMMIT/.test(post.content.toUpperCase())) {
                post.interactionTypeId = 4;
                foundCounters.interactions += 1;
                setLogData((prev) => [...prev, `COMMIT`]);
            }

            const filteredWords = processPostContent(post.content);
            setLogData((prev) => [...prev, `FILTERED: ${filteredWords}`]);

            const teamMatches = teams.filter(team => {
                const escapedTeamName = escapeRegExp(team.name.toUpperCase()); // Ensure team name is uppercased and escaped
                const regex = new RegExp(`\\b${escapedTeamName}\\b`, 'i');
                return regex.test(filteredWords);
            });

            if (teamMatches.length > 0) {
                setLogData((prev) => [...prev, `TEAM: ${teamMatches.map(team => team.name).join(', ')}`]);

                const uniqueTeamIds = [...new Set(teamMatches.map(team => team.id))];

                if (uniqueTeamIds.length === 1) {
                    const team = teamsMin.find(t => t.id === uniqueTeamIds[0]);
                    post.teamId = team.id;
                    teamName = team.name;
                    teamConference = team.College?.Conference?.name;
                    teamDivision = team.College?.Conference?.conferenceDivisionId?.toUpperCase();
                    teamLogo = team.logo;
                    foundCounters.teams += 1;
                    setLogData((prev) => [...prev, `TEAM: ${team.name}`]);
                } else {
                    const findTwitterHandle = teamMatches.find(team => team.name.includes('@'));
                    if (findTwitterHandle) {
                        const team = teamsMin.find(t => t.id === findTwitterHandle.id);
                        post.teamId = team.id;
                        teamName = team.name;
                        teamConference = team.College?.Conference?.name;
                        teamDivision = team.College?.Conference?.conferenceDivisionId?.toUpperCase();
                        teamLogo = team.logo;
                        foundCounters.teams += 1;
                        setLogData((prev) => [...prev, `TEAM BY TWITTER: ${team.name}`]);
                    } else {
                        const longestNameTeam = teamMatches.reduce((longest, current) => {
                            return current.name.length > longest.name.length ? current : longest;
                        }, teamMatches[0]);

                        if (longestNameTeam) {
                            post.teamId = longestNameTeam.id;
                            teamName = longestNameTeam.name;
                            teamConference = longestNameTeam.College?.Conference?.name;
                            teamDivision = longestNameTeam.College?.Conference?.conferenceDivisionId?.toUpperCase();
                            teamLogo = longestNameTeam.logo;
                            foundCounters.teams += 1;
                            setLogData((prev) => [...prev, `TEAM LONGEST NAME: ${longestNameTeam.name}`]);
                        } else {
                            foundCounters.teamsMore += 1;
                        }
                    }
                }
            } else {
                setLogData((prev) => [...prev, `NO TEAM`]);
            }

            const uPost = await upsertSocialPost(post);

            if (post.recruitId && post.teamId && post.interactionTypeId) {
                const activity = {
                    interactionTypeId: post.interactionTypeId,
                    recruitId: post.recruitId,
                    teamId: post.teamId,
                    activity_at: post.tweetCreated,
                    date: convertToUnixTimestamp(post.tweetCreated),
                    detail: post.content,
                    link: post.link.replace('twitter.com', 'x.com'),
                    source: 'scraped ' + post.source,
                    logo: teamLogo,
                    teamName: teamName,
                    division: teamDivision,
                    conference: teamConference
                };

                const a = await upsertActivity(activity);

                if (a) {
                    post.activityId = a.id;
                    post.processed = true;
                    const updatedPost = await upsertSocialPost(post);

                    if (updatedPost) {
                        console.log(`UPDATED POST ${post.postId} WITH RECRUIT ID ${post.recruitId}, TEAM ID ${post.teamId}, and INTERACTION TYPE ${post.interactionTypeId}`);
                        await processSocialPost(post);

                    } else {
                        console.log(`Error updating post ${post.postId} with recruit ID ${post.recruitId}, team ID ${post.teamId}, and interaction type ${post.interactionTypeId}`);
                    }
                } else {
                    console.log(`Error upserting activity for post ${post.postId} with recruit ID ${post.recruitId}, team ID ${post.teamId}, and interaction type ${post.interactionTypeId}`);
                }
            }
        } catch (error) {
            console.error(`Error handling post ${post.postId}:`, error.message);
        }
    }

    function escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escapes special characters for use in a regex
    }

    const processSocialPosts = async () => {
        try {
            const socialPosts = await getSocialPosts();
            const teams = await getAllTeamsAndAbbreviations();
            const teamsMin = await getTeams();

            const foundCounters = {
                recruits: 0,
                interactions: 0,
                teams: 0,
                teamsMore: 0
            };

            const postProcessingPromises = socialPosts.map(post => handlePost(post, teams, teamsMin, foundCounters));
            await Promise.all(postProcessingPromises);

        } catch (error) {
            console.error('Error processing social posts:', error.message);
        }
    }


    return (
        <div>
            <h3 style={{ marginBottom: '20px' }}>
                Process Scraped Posts
            </h3>

            <p><button style={{ marginTop: '5px' }} className="btn btn-success" onClick={() => processSocialPosts()}>
                Process Scraped Posts →
            </button></p>

            <div>
                {logData.map((log, index) => (
                    <div key={index} dangerouslySetInnerHTML={{ __html: log }} />
                ))}
            </div>

        </div>
    );
}

export default ImportSocialPosts;