import React, { useEffect, useState } from 'react';
import { TextField, FormControl, Button, InputLabel } from '@mui/material';
import { getStates, updateHighSchool, addHighSchool } from '../../services/supabase';
import Select from 'react-select';
import { toast } from 'react-toastify';

const EditHighSchool = ({ highSchool }) => {
    console.log('HighSchool:', highSchool);
    const [selectStates, setSelectStates] = useState([]);
    const [formData, setFormData] = useState(highSchool || {});

    useEffect(() => {
        fetchStates();
    }, []);

    useEffect(() => {
        setFormData(highSchool || {});
    }, [highSchool]);

    async function fetchStates() {
        try {
            const states = await getStates();
            const sStates = states.map((x) => ({
                value: x.stateAbbrev,
                label: x.stateName
            }));
            setSelectStates(sStates);
        } catch (error) {
            console.error('Error fetching states:', error);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('High School Info:', formData);

        if (!formData.id) {
            const addedHighSchool = await addHighSchool(formData);
            if (addedHighSchool) {
                toast.success("Highschool Added", {
                    position: "top-right",
                    autoClose: 1000,
                });
            } else {
                toast.error("Error adding highschool", {
                    position: "top-right",
                    autoClose: 1000,
                });
            }
        }
        else {
            const updatedHighSchool = await updateHighSchool(formData);
            if (updatedHighSchool) {
                toast.success("Highschool Updated", {
                    position: "top-right",
                    autoClose: 1000,
                });
            } else {
                toast.error("Error updating highschool", {
                    position: "top-right",
                    autoClose: 1000,
                });
            }
        }

        
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleSelectChange = (selectedOption) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            State: selectedOption ? selectedOption.value : ''
        }));
    };

    return (
        <form onSubmit={handleSubmit} className="highSchool-form">
            <FormControl fullWidth margin="normal">
                <TextField
                    label="ETS"
                    type="text"
                    name="ETS"
                    value={formData.ETS || ''}
                    onChange={handleChange}
                    required
                    min={7}
                    max={7}
                />
            </FormControl>
           
            <FormControl fullWidth margin="normal">
                <TextField
                    label="Name"
                    type="text"
                    name="School"
                    value={formData.School || ''}
                    onChange={handleChange}
                    required
                />
            </FormControl>

            <FormControl fullWidth margin="normal">
                <TextField
                    label="Address"
                    type="text"
                    name="Address"
                    value={formData.Address || ''}
                    onChange={handleChange}
                    required
                />
            </FormControl>
            <FormControl fullWidth margin="normal">
                <TextField
                    label="City"
                    type="text"
                    name="City"
                    value={formData.City || ''}
                    onChange={handleChange}
                    required
                />
            </FormControl>
            <FormControl fullWidth margin="normal">
                <InputLabel>State</InputLabel>
                {selectStates.length > 0 ? (
                    <Select
                        id="State"
                        isSearchable={true}
                        isClearable={true}
                        name="State"
                        options={selectStates}
                        value={selectStates.find(option => option.value === formData.State) || null}
                        onChange={handleSelectChange}
                    />
                ) : (
                    <p>Loading states...</p>
                )}
            </FormControl>

            <FormControl fullWidth margin="normal">
                <TextField
                    label="Zip"
                    type="text"
                    name="Zip"
                    value={formData.Zip || ''}
                    onChange={handleChange}
                    required
                />
            </FormControl>

            <FormControl fullWidth margin="normal">
                <TextField
                    label="County"
                    type="text"
                    name="County"
                    value={formData.County || ''}
                    onChange={handleChange}
                    
                />
            </FormControl>
            <hr />
            <FormControl fullWidth margin="normal">
                <TextField
                    label="Coach"
                    type="text"
                    name="Coach"
                    value={formData.Coach || ''}
                    onChange={handleChange}
                    
                />
            </FormControl>
            <FormControl fullWidth margin="normal">
                <TextField
                    label="Phone"
                    type="text"
                    name="Phone"
                    value={formData.Phone || ''}
                    onChange={handleChange}
                    
                />
            </FormControl>
            <FormControl fullWidth margin="normal">
                <TextField
                    label="Cell"
                    type="text"
                    name="Cell"
                    value={formData.Cell || ''}
                    onChange={handleChange}
                    
                />
            </FormControl>
            <FormControl fullWidth margin="normal">
                <TextField
                    label="Twitter"
                    type="text"
                    name="Twitter"
                    value={formData.Twitter || ''}
                    onChange={handleChange}
                    
                />
            </FormControl>
            
            <FormControl fullWidth margin="normal">
                <TextField
                    label="Email"
                    type="email"
                    name="Email"
                    value={formData.Email || ''}
                    onChange={handleChange}
                    
                />
            </FormControl>

            <Button variant="contained" color="primary" type="submit">
                Submit
            </Button>
        </form>
    );
};

export default EditHighSchool;
