import React, { useState, useEffect } from 'react';
import { clearIndex as clearAlgoliaIndex, rebuildRecruits } from '../../services/algolia';
import TweetExtraction from '../shared/TweetExtraction';
import ImportOn3Transfers from './ImportOn3Transfers';

export default function Integrations() {
    const [rebuildIndexName, setRebuildIndexName] = useState('PROD_PORTAL_ATHLETE');
    const [results, setResults] = useState([])

    async function rebuildSelectedIndex() {
        try {
            const cleared = await clearAlgoliaIndex(rebuildIndexName)
            const rebuild = await rebuildRecruits(rebuildIndexName);
            console.log('REBUILT', rebuild);
            setResults(rebuild); // Update state with the fetched data
        } catch (error) {
            console.error('Error rebuilding index:', error);
        }
    }

    function handleRebuildIndex(value) {
        setRebuildIndexName(value);
    }

    return (
        <div className="box-content">
            <div className="col-md-6">
                <main className="container" style={{ display: 'block' }}>
                   <h5>Import PFF Athletes</h5>

                   
                   <hr className="mt-25 mb-25" />


                    <h5>Rebuild Index</h5>
                    <select
                        name="idx"
                        className="form-select ml-5"
                        id="idxRebuild"
                        onChange={(e) => handleRebuildIndex(e.target.value)}
                    >
                        <option selected value="PROD_PORTAL_ATHLETE">
                            Portal Athletes
                        </option>
                       
                        
                    </select>
                    <button style={{ marginTop: '5px' }} className="btn btn-success" onClick={() => rebuildSelectedIndex()}>
                        Rebuild Index →
                    </button>

                    <hr className="mt-25 mb-25" />

                    <h5>Import On3 Transfers</h5>
                    <ImportOn3Transfers />


                </main>
            </div>
            <div className="col-md-6">
                <table className="table table-bordered">
                    {results?.map((result, index) => (
                        <tr key={index}>
                            <td>{result.fname}</td>
                            <td>{result.lname}</td>
                            <td>{result.position}</td>
                            <td>{result.height}</td>
                            <td>{result.weight}</td>
                            <td>{result.twitterHandle}</td>

                            <td>{result.enteredPortalDate}</td>
                            <td>{result.exitTeam?.name}</td>
                            <td>{result.committedTeam?.name}</td>
                            <td>{result.committedDate}</td>
                        </tr>

                    ))}</table>
            </div>
        </div>
    );
}
