import React, { useState, useEffect } from "react";
import { addRecruit, checkRecruitHandle, processSocialPost } from '../../services/supabase';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css'; // Ensure you have this import for Toastify styles

export default function AthleteAdd(props) {
    const navigate = useNavigate();
    const socialPost = props.post? props.post : null; // Ensure it's a string
    console.log('Social Post:', socialPost)

    const fullName = socialPost?.name?.trim().split(' ');
    const firstName = fullName?.length > 0 ? fullName[0] : '';
    const lastName = fullName?.length > 1 ? fullName.slice(1).join(' ') : ''; // Handle names with more than two parts

    const [athlete, setAthlete] = useState({
        firstName: firstName ? firstName : '',
        lastName: lastName ? lastName : '',
        twitter: socialPost ? socialPost.handle : '',
        rank: 'Q (Questionnaire)'
    });

    async function handleFormSubmit(e) {
        e.preventDefault();
        e.stopPropagation();
        
        if (athlete.twitter && athlete.twitter?.trim() != '') {
            const twitterHandle = await checkRecruitHandle(athlete.twitter);
            console.log('Twitter Handle:', twitterHandle)
            if (twitterHandle) {
                toast.error("An athlete with that twitter handle already exists: " + athlete.twitter + " " + athlete.firstName + " " + athlete.lastName);
                return;
            }
        }
        
            const a = await addRecruit(athlete);
            console.log('Athlete:', a)
            if (a) {
                if (socialPost) {
                    socialPost.recruitId = a.id;
                    const updatedPost = await processSocialPost(socialPost);
                }
                console.log(a.id)
                navigate('/admin/athlete/' + a.id);
            }
            else {
                toast.error("Error adding athlete: " + athlete.firstName + " " + athlete.lastName);
            }
        
    }

    return (
        <>

            <form onSubmit={handleFormSubmit}>

                {socialPost ? (
                    <>
                        <div className="form-container mt-3">
                            <div className="container">
                                {socialPost ? (
                                    <>
                                        <div className="form-group mb-3">
                                            <p>{socialPost.content}</p>
                                        </div>
                                    </>
                                ) : ('')}

                            </div>
                        </div>
                        <div className="form-container mt-3">
                            <div className="container">
                                {socialPost ? (
                                    <>
                                        <div className="form-group mb-3">
                                            <p>{socialPost.description}</p>
                                        </div>
                                    </>
                                ) : ('')}

                            </div>
                        </div>
                    </>
                ) : ('')}


                <div className="form-container mt-3">

                    <div className="container">

                        <div className="form-group mb-3">
                            <label className="form-text"><strong>First Name</strong></label>
                            <input
                                type="text"
                                name="firstName"
                                value={athlete.firstName}
                                placeholder="First Name"
                                required
                                className="form-control"
                                onChange={(e) => setAthlete({ ...athlete, firstName: e.target.value })}
                            />
                        </div>

                        <div className="form-group mb-3">
                            <label className="form-text"><strong>Last Name</strong></label>
                            <input
                                type="text"
                                name="lastName"
                                value={athlete.lastName}
                                placeholder="Last Name"
                                required
                                className="form-control"
                                onChange={(e) => setAthlete({ ...athlete, lastName: e.target.value })}
                            />
                        </div>

                        <div className="form-group mb-3">
                            <label className="form-text"><strong>Twitter Handle</strong></label>
                            <input
                                type="text"
                                name="twitter"
                                value={athlete.twitter}
                                placeholder="Twitter Handle"
                                className="form-control"
                                onChange={(e) => setAthlete({ ...athlete, twitter: e.target.value })}
                            />
                        </div>

                        <div className="button-container">
                            <button className="btn btn-success" type="submit" style={{ backgroundColor: '#000' }}>
                                Add
                            </button>
                            <ToastContainer />
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}
