import React from 'react';
import { rebuildRecruits } from '../../services/algolia';

function ImportRebuildRecruits() {
   
    async function rebuildIndex() {
        rebuildRecruits();
    }
    
    return (
        <div>
            <h3 style={{ marginBottom: '20px' }}>
                Rebuild Recruits Index
            </h3>

            <p>Click the button</p>

            <p><button style={{ marginTop: '5px' }} className="btn btn-success" onClick={() => rebuildIndex()}>
               Rebuild Index →
            </button></p>

        </div>
    );
}

export default ImportRebuildRecruits;
