import { getUserRole, getUserRoles } from "./supabase";
import { supabaseClient } from "./supabaseclient";

export default function parseTweet(tweet) {
  let url = parseURL(tweet);
  url = parseHashtag(url);
  return parseUsername(url);
}
function parseURL(tweet) {
  return tweet?.replace(/[A-Za-z]+:\/\/[A-Za-z0-9-_]+\.[A-Za-z0-9-_:%&~\?\/.=]+/g, function (url) {
    return url.link(url);
  });
};

export function escapeValue (value) {
  if (typeof value === 'string' && (value.includes(',') || value.includes('"'))) {
      return `"${value.replace(/"/g, '""')}"`;
  }
  return value;
};


export function generateWeights(start = 120, end = 400, increment = 10) {
  const weights = [];
  for (let i = start; i <= end; i += increment) {
    weights.push(i);
  }
  return weights;
}

export function generateHeights(start = 60, end = 95, increment = 1){
  const heights = []
  for (let i = start; i <= end; i += increment){
    heights.push(i)
  }
  return heights
}

export async function signOut() {
  const { error } = await supabaseClient.auth.signOut()
}


// Auto-link Twitter usernames in a string
// Usage: mystring.parseUsername()
function parseUsername(tweet) {
  return tweet?.replace(/[@]+[A-Za-z0-9-_]+/g, function (u) {
    var username = u?.replace("@", "");
    return u.link('http://twitter.com/' + username);
  });
};
// Auto-link Twitter hashtags in a string
// Usage: mystring.parseHashtag()
function parseHashtag(tweet) {
  return tweet?.replace(/[#]+[A-Za-z0-9-_]+/g, function (t) {
    var tag = t?.replace("#", "%23");
    return t.link('http://search.twitter.com/search?q=' + tag);
  });
};
export function parseDate(d) {
  const dateObject = new Date(d);
  return dateObject.toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' });
}

export function extractTwitterHandle(tweet) {
  const twitterHandleRegex = /@(\w+)/; // Regular expression to match the Twitter handle
  const match = tweet.match(twitterHandleRegex);

  if (match && match.length > 1) {
    return match[1]; // Extracted Twitter handle
  } else {
    return null; // Handle not found
  }
}

export function SubmitIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 18 18"
      aria-hidden="true"
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.67"
        transform="translate(1 1)"
      >
        <circle cx="7.11" cy="7.11" r="7.11" />
        <path d="M16 16l-3.87-3.87" />
      </g>
    </svg>
  );
}

export async function checkUserRole(roleToCheck){
  try {
    const user_profile_id = localStorage.getItem('user_profile_id')
    const userProfile = await getUserRole(user_profile_id); 
    if(userProfile){
      const userRole = userProfile[0].role.type
      console.log('affix->', userRole, roleToCheck)
      const roleExists = roleToCheck.some(role => userRole.includes(role));
      console.log('exists->', roleExists)
      return roleExists;
    }
    return false

  } catch (error) {
    console.error('Error checking user role:', error);
    return false;
  }
};

export async function getRole() {
  
    const { data: { session } } = await supabaseClient.auth.getSession();
    if (session) {
      const role = await getUserRoles(session.user.id);
      if (role && role.length > 0) {
        return role[0];
      }
      else {
        return null;
      }
    }
    else {
      return null
    }
}

export function convertToUnixTimestamp(dateString) {
  const date = new Date(dateString);
  return date.getTime();
}
